import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Notiflix from 'notiflix';
import { Company } from 'src/app/core/shared/models/company.model';
import { Equipement } from 'src/app/core/shared/models/equipement';
import { Profile } from 'src/app/core/shared/models/profile';
import { StorageService } from 'src/app/core/shared/services/storage.service';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import {
  GridComponent,
  ParentDetails,
  RowDataBoundEventArgs,
  RowSelectEventArgs,
} from '@syncfusion/ej2-angular-grids';
import { BikePartsListService } from './bike-parts-list.service';
import {
  Substitution,
  WorkOrder,
  WorkOrderLine,
} from 'src/app/core/shared/models/WorkOrder';
import { HomeService } from '../home/services/home.service';
import { GenericComponent } from 'src/app/components/generic-component';
import { take, takeUntil } from 'rxjs/operators';
import { AccessService } from 'src/app/guard/access.service';
import { Features } from 'src/app/models/accessGrantObject.model';
import { Subject } from 'rxjs';
import { DetailWorkorderService } from '../detail-workorder/services/detail-workorder.service';
import { TemplateMakerService } from '../template-maker/services/template-maker.service';
import { OpenWorkorderService } from '../open-workorder/services/open-workorder.service';
import { Template } from 'src/app/core/shared/models/Template';
import { StatusProfileBlockAndReturnScan } from 'src/app/core/shared/models/statusProfileBlockAndReturnScan.model';
import { CommunService } from 'src/app/services/commun.service';
declare var bootstrap: any;
import { DataManager, Query } from '@syncfusion/ej2-data';
import { AppConfigService } from 'src/app/config/app.config.service';

@Component({
  selector: 'app-item-list',
  templateUrl: './bike-parts-list.component.html',
  styleUrls: ['./bike-parts-list.component.scss'],
})
export class BikePartsListComponent extends GenericComponent implements OnInit {
  @ViewChild('bikePartsGrid') bikePartsGrid: GridComponent;
  @ViewChild('childtemplate', { static: true }) public childtemplate?: any;
  @ViewChild('quantitytemplate', { static: true })
  public quantitytemplate?: any;
  @ViewChild('barcode') public barcode: any;

  userProfilTitle = '';
  bixiUserAuthObject: any = null;
  currentProfile: Profile = null;
  currentProfiles: Profile[] = [];
  workOrderNo = '';
  sameTasks = null;

  currentUserCompany: Company | undefined;
  equipementNo: string = '';
  pageName = 'bikepartslist';
  BTandLines: {
    BT: any;
    expenses: any[];
    items: any[];
  } = null;
  equipement: Equipement = null;
  listEquipData: any[] = [];
  bikePartsData: WorkOrderLine[] = [];
  taskLineDefinitions: any[] = [];
  validateSelectedRecords: WorkOrderLine[] = [];
  substitutions: Substitution[] = [];
  wos: WorkOrder[] = [];
  public childGrid?: any;
  public parentDetails?: ParentDetails[];
  openDetailTacheModalEvent: Subject<any> = new Subject<any>();
  disableButton = true;
  isTransfered = false;
  isBarCodeVisible = 'block';
  modalValidationTitle = 'Validation';
  codeTaskCategory = '';
  workorderTemplate: Template = null;
  equipementAssocieModal = null;
  isAllDetailsExpanded = false;
  expandCollapseClass = 'fa-eye';
  bikePartFeatures: Features = null;
  BIXI_userAuthObject: any = null;
  itemWorkOrderLines: any[] = [];
  expenseWorkOrderLines: any[] = [];
  unitsOfMeasureList = [];
  closeOpenWorkorderpanelEvent: Subject<void> = new Subject<void>();
  onSelectedAllFinishedClickEvent: Subject<any> = new Subject<any>();
  onReopenGeneralClickEvent: Subject<any> = new Subject<any>();
  onSelectedAllValidateClickEvent: Subject<any> = new Subject<any>();
  currentWorkorder: any = null;
  itemsList = [];
  categoryCode: string = '';
  workOrderSystemId: string = '';
  defDisplayNames: any[] = [];
  locationsList = [];
  allItemVariants = [];
  expensesList = [];
  createdByTemplateId = '';
  editWorkOrderFeatures: Features = null;
  public consumptionTrackData: object[];
  isUserBlocked: boolean = true;  
  onCodeBarOTClickModal = null;
  codeBarImgBase64: string = "";

  roueWorkorder = null;
  templateRoue = null;
  refreshAssetLinkEvent: Subject<void> = new Subject<void>();
  associatedEquipTemplateModal = null;
  globalSameTasks = null;
  taskCategoryByDocs: any[] = [];
  workOrdersNoArray = [];
  colorDefaultTaskIcon_Fr_Fr_A_G = 'text-info';
  templateSideIndex = 0
  EObjectToUpdate = null
  taskMultipleValue = 1

  schemaVersion = AppConfigService.settings.bcSchemaversion;
  isReturnOrderNoPrint: boolean = false;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private bikePartsListService: BikePartsListService,
    private activatedRoute: ActivatedRoute,
    private homeService: HomeService,
    private AuthService: AuthService,
    private accessService: AccessService,
    private detailWorkorderService: DetailWorkorderService,
    private templateMakerService: TemplateMakerService,
    private openWorkOrderService: OpenWorkorderService,
    private communService: CommunService,
    @Inject(ViewContainerRef) private viewContainerRef?: ViewContainerRef
  ) {
    super();
  }
  
  updateItemVariant(event: any, data: WorkOrderLine){
     if(event.target.value && data.systemId){
        Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff',
        });
        const variant = event.target.value
        const concatenatedUrl = 'eraworkOrderLines(' + data.systemId + ')';
        let dataToUpdate = { "variantCode": variant }
        this.detailWorkorderService
          .editItemVariantElement(
            this.BIXI_userAuthObject.tenantId,
            this.currentUserCompany.id,
            JSON.stringify(dataToUpdate),
            concatenatedUrl
          )
          .subscribe({
            next: (resData) => {
              Notiflix.Loading.remove();
              Notiflix.Notify.success(`La variante ${variant} est affectée avec succès à l'article ${data?.no}`, {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              });
              setTimeout(() => {
                this.getBikeParts();
              }, 1000);
              
            Notiflix.Loading.remove();
            },
            error: (error) => {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure(
                error,
                {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                }
              );
            },
          });       
     }
  }

  ngOnInit(): void {
    // check if user has data in browser storage, else redirect himn to login page
    if (
      !this.storageService.getItem('bixi-user-auth') ||
      !this.storageService.getItem('bixi-currentCompany') ||
      !this.storageService.getItem('bixi-currentProfile')
    ) {
      this.router.navigateByUrl('/auth/login');
    } else {
      this.BIXI_userAuthObject = JSON.parse(
        this.storageService.getItem('bixi-user-auth')
      );

      this.bixiUserAuthObject = JSON.parse(
        this.storageService.getItem('bixi-user-auth')
      );
      this.currentUserCompany = JSON.parse(
        this.storageService.getItem('bixi-currentCompany')
      );
      this.currentProfile = JSON.parse(
        this.storageService.getItem('bixi-currentProfile')
      );
      this.currentProfiles = JSON.parse(
        this.storageService.getItem('bixi-currentProfiles')
      );

      if(this.storageService.getItem('Bixi_BlockAndReturnScan')){
        let BlockAndReturnScan: StatusProfileBlockAndReturnScan  = JSON.parse(this.storageService.getItem('Bixi_BlockAndReturnScan'));
        this.isUserBlocked = BlockAndReturnScan ? JSON.parse(this.storageService.getItem('Bixi_BlockAndReturnScan'))?.isBlockedAction : true      
      }
    
    }

    this.getAllItemsVariants();
    this.editWorkOrderFeatures = this.accessService.getFeatureByProfile(
      this.currentProfile.profil
    );

    this.childGrid = {
      queryString: 'lineNo',
      load() {
        this.registeredTemplate = {}; // set registertemplate value as empty in load event
      },
      columns: [
        { headerText: '', width: 280 },
        {
          template: this.quantitytemplate,
          headerText: 'Quantité',
          width: 100,
          textAlign: 'Right',
          headerTextAlign: 'Right',
        },
        {
          field: 'inventoryCyclo',
          headerText: 'Quantité inventaire',
          width: 100,
          textAlign: 'Right',
          headerTextAlign: 'Right',
        },
        { field: 'lineNo', headerText: 'lineNo', width: 100, visible: false },
        {
          field: 'inventoryBixi',
          headerText: 'Inventaire Bixi',
          width: 100,
          textAlign: 'Right',
          headerTextAlign: 'Right',
        },
        {
          field: 'substituteNo',
          headerText: "Code de pièce d'inventaire",
          width: 120,
          visible: this.currentProfile.profil !== 'Mechanic',
        },
        {
          template: this.childtemplate,
          headerText: "Code de pièce d'inventaire",
          width: 120,
          visible: this.currentProfile.profil === 'Evaluator' || this.currentProfile.profil === 'Mechanic',
        },
        {
          field: 'variantCode',
          headerText: 'Variant',
          width: 120,
          visible: true,
        },
        { field: 'description', headerText: "Pièce d'inventaire", width: 800 },
      ],
    };
    Notiflix.Notify.init({
      position: 'right-bottom',
    });

    this.getEquipement();
    window.setTimeout(() => {
      this.childtemplate.elementRef.nativeElement._viewContainerRef =
        this.viewContainerRef;
      this.childtemplate.elementRef.nativeElement.propName = 'template';
      this.quantitytemplate.elementRef.nativeElement._viewContainerRef =
        this.viewContainerRef;
      this.quantitytemplate.elementRef.nativeElement.propName = 'template';
    }, 500);

    setTimeout(() => {
      this.isBarCodeVisible = 'none';
    }, 100);

    this.bikePartFeatures = this.accessService.getFeatureByProfile(
      this.currentProfile.profil
    );
  }

  changeProfile(profil) {
    if (profil) {
      const profileFound = this.currentProfiles.find(
        (currentProfile) => currentProfile.profil == profil
      );
      localStorage.setItem('bixi-currentProfile', JSON.stringify(profileFound));
      this.reloadCurrentRoute();
    } else {
      this.AuthService.logout();
    }
  }


  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    currentUrl = currentUrl.replace('%20', ' ');
    location.reload()
  }

  logout() {
    Notiflix.Confirm.show(
      'Confirmation',
      'Êtes-vous sûr de vouloir vous déconnecter?',
      'Confirmer',
      'Annuler',
      () => {
        this.router.navigateByUrl('/');
      },
      () => {},
      {
        width: '450px',
        okButtonBackground: '#04B76B',
        cancelButtonBackground: '#FF5E6D',
        titleColor: '#392C70',
        borderRadius: '5px',
        backgroundColor: '#ffffff',
      }
    );
  }

  refreshEquipementEvent() {
    this.getEquipement();
  }

  getBikeParts() {
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    this.bikePartsListService
      .getBikeParts(
        this.bixiUserAuthObject.tenantId,
        this.currentUserCompany.id,
        "leadWONo eq '" + this.equipement?.workOrderNo + "'"
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (result) => {
          this.bikePartsData = [];
          if (result.value && result.value.length) {
            this.wos = result.value;
            if (this.wos.length) {
              this.childGrid.dataSource = [];
              this.wos.forEach((wo) => {
                this.bikePartsData = this.bikePartsData.concat(
                  wo.eraworkOrderLines
                );
                
                wo.eraworkOrderLines.forEach((wol: WorkOrderLine) => {
                  wol.qtyToShip = wol.qtyToConsume;
                  wol.eraSubstitution.forEach((sub: Substitution) => {
                    sub.no = sub.itemNo;
                    sub.parentSystemId = wol.systemId;
                    sub.documentNo = wol.documentNo;
                    sub.lineNo = wol.lineNo;
                    this.childGrid.dataSource.push(sub);
                  });
                  wol.eraVariantsExisteArray = [];
                  //new added to get variant for item if shoud be existed
                  if(wol.eraVariantExiste){
                    wol.eraVariantsExisteArray = this.allItemVariants?.filter(x=>x.itemNumber == wol.no);                    
                  }

                });
              });
            }
          }
          Notiflix.Loading.remove();
        },
        error: (error) => {
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          });          
          Notiflix.Loading.remove();
        },
      });
  }

  openDetailModal(data) {
    this.codeTaskCategory = data.codeTaskCategory;

    this.openWorkOrderService
      .getWorkorders(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        "no eq '" + data.documentNo + "'"
      )
      .subscribe({
        next: (workOrder) => {
          this.currentWorkorder = workOrder.value[0];

          this.workorderTemplate.columnLines.forEach((columnLine, i) => {
            columnLine.sectionLines.forEach((sectionLine, j) => {
              sectionLine.subSectionLines.forEach((subSectionLine, k) => {
                subSectionLine.taskLines.forEach((taskLine, l) => {
                  if (taskLine.codeTaskCategory == this.codeTaskCategory) {
                    const foundData = this.taskLineDefinitions.filter(
                      (taskLineDefinition) =>
                        taskLineDefinition.code == taskLine.displayName
                    );

                    if (foundData.length > 0) {
                      taskLine.definition = foundData[0];
                      this.workOrderNo = data.documentNo;

                      this.sameTasks = {
                        [taskLine.systemId]: {
                          taskLine: taskLine,
                          workOrdersNo: [this.workOrderNo],
                        },
                      };

                      this.openDetailTacheModalEvent.next({
                        isOpenedFromDetailWo: false,
                        // eraMissingVariant: false,
                        taskLine: taskLine,
                        codeTaskCategory: data.codeTaskCategory,
                        workorderNo: data.documentNo,
                        workorder: this.currentWorkorder,
                        sameTasks: this.sameTasks,
                      });
                    } else {
                      taskLine.definition = undefined;
                    }
                  }
                });
              });
            });
          });
        },
        error: (error) => {},
      });
  }

   getAllItemsVariants(){
      const concatenatedUrl = '/itemVariants';
      this.detailWorkorderService
        .getNoStandartApiData_2(
          this.BIXI_userAuthObject.tenantId,
          this.currentUserCompany.id,
          concatenatedUrl
        )
        .subscribe({
          next: (resData) => {
            this.allItemVariants = resData.value;
          },
          error: (error) => {
            // Notiflix.Loading.remove();
            // Notiflix.Notify.failure("Une erreur s'est produite pendant le traitement. Veuillez réessayer", {
            //   timeout: 3000,
            //   showOnlyTheLastOne: true,
            //  })
          },
        });
      }

  getEquipement() {
    this.activatedRoute.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        this.equipementNo = params['equipementNo'];
        // check if equipementNo is in url
        if (this.equipementNo) {
          this.equipementNo = this.equipementNo.replace('%20', ' ');

          this.homeService
            .getEquipement(
              this.bixiUserAuthObject.tenantId,
              this.currentUserCompany.id,
              this.equipementNo
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (equipement) => {
                this.equipement = equipement.value[0];
                this.equipementNo = this.equipement.no;
                this.categoryCode = this.equipement.categoryCode;
                this.workOrderSystemId = this.equipement.systemId;
                if(equipement.value[0].multiCorrectifWorkOrder){
                  this.equipement.workOrderNo = this.storageService.getItem('Bixi_WorkOrderLedNo');
                  this.workOrderNo = this.equipement.workOrderNo;
                }
                 else
                    this.workOrderNo = this.equipement.workOrderNo;

                this.templateMakerService
                  .getPortailDefDisplayNames(
                    this.BIXI_userAuthObject.tenantId,
                    this.currentUserCompany.id
                  )
                  .subscribe({
                    next: (defDisplayNames) => {
                      this.defDisplayNames = defDisplayNames.value;
                    },
                    error: (error) => {
                      Notiflix.Loading.remove();
                      Notiflix.Notify.failure(error, {
                        timeout: 3000,
                        showOnlyTheLastOne: true, 
                        width: "400px",
                      });
                    },
                  });

                let concatenatedUrl = '/locations';
                this.detailWorkorderService
                  .getStandartApiData(
                    this.BIXI_userAuthObject.tenantId,
                    this.currentUserCompany.id,
                    concatenatedUrl
                  )
                  .subscribe({
                    next: (resData) => {
                      this.locationsList = resData.value.sort((a, b) =>
                        a.displayName.toLowerCase() >
                        b.displayName.toLowerCase()
                          ? 1
                          : -1
                      );
                    },
                    error: (error) => {
                      Notiflix.Loading.remove();
                      Notiflix.Notify.failure(error, {
                        timeout: 3000,
                        showOnlyTheLastOne: true, 
                        width: "400px",
                      });
                    },
                  });

                concatenatedUrl = '/unitsOfMeasure';
                this.detailWorkorderService
                  .getStandartApiData(
                    this.BIXI_userAuthObject.tenantId,
                    this.currentUserCompany.id,
                    concatenatedUrl
                  )
                  .subscribe({
                    next: (resData) => {
                      this.unitsOfMeasureList = resData.value.sort((a, b) =>
                        a.displayName.toLowerCase() >
                        b.displayName.toLowerCase()
                          ? 1
                          : -1
                      );
                    },
                    error: (error) => {
                      Notiflix.Loading.remove();
                      Notiflix.Notify.failure(error, {
                        timeout: 3000,
                        showOnlyTheLastOne: true, 
                        width: "400px",
                      });
                    },
                  });

                concatenatedUrl = '/items?$schemaversion='+this.schemaVersion+'&$filter=damMaintenanceItem eq true';

                this.detailWorkorderService
                  .getNoStandartApiData(
                    this.BIXI_userAuthObject.tenantId,
                    this.currentUserCompany.id,
                    concatenatedUrl
                  )
                  .subscribe({
                    next: (resData) => {
                      this.itemsList = resData.value.sort((a, b) =>
                        a.no.toLowerCase() > b.no.toLowerCase() ? 1 : -1
                      );
                    },
                    error: (error) => {
                      Notiflix.Loading.remove();
                      Notiflix.Notify.failure(error, {
                        timeout: 3000,
                        showOnlyTheLastOne: true, 
                        width: "400px",
                      });
                    },
                  });

                concatenatedUrl = '/itemVariants';
                this.detailWorkorderService
                  .getNoStandartApiData_2(
                    this.BIXI_userAuthObject.tenantId,
                    this.currentUserCompany.id,
                    concatenatedUrl
                  )
                  .subscribe({
                    next: (resData) => {
                      this.allItemVariants = resData.value;
                    },
                    error: (error) => {
                      // Notiflix.Loading.remove();
                      // Notiflix.Notify.failure("Une erreur s'est produite pendant le traitement. Veuillez réessayer", {
                      //   timeout: 3000,
                      //   showOnlyTheLastOne: true,
                      //  })
                    },
                  });

                 concatenatedUrl = '/expenses?$schemaversion='+this.schemaVersion;
                this.detailWorkorderService
                  .getNoStandartApiData(
                    this.BIXI_userAuthObject.tenantId,
                    this.currentUserCompany.id,
                    concatenatedUrl
                  )
                  .subscribe({
                    next: (resData) => {
                      this.expensesList = resData.value.sort((a, b) =>
                        a.no.toLowerCase() > b.no.toLowerCase() ? 1 : -1
                      );
                    },
                    error: (error) => {
                      Notiflix.Loading.remove();
                      Notiflix.Notify.failure(error, {
                        timeout: 3000,
                        showOnlyTheLastOne: true, 
                        width: "400px",
                      });
                    },
                  });

                this.openWorkOrderService
                  .getWorkorders(
                    this.BIXI_userAuthObject.tenantId,
                    this.currentUserCompany.id,
                    "no eq '" + this.workOrderNo + "'"
                  )
                  .subscribe({
                    next: (workOrder) => {
                      this.currentWorkorder = workOrder.value[0];

                      if (workOrder.value.length > 0) {
                        this.createdByTemplateId =
                          workOrder.value[0].createdByTemplateId;

                        this.detailWorkorderService
                          .getWorkOrderTemplate(
                            this.BIXI_userAuthObject.tenantId,
                            this.currentUserCompany.id,
                            this.createdByTemplateId,
                            "$filter=codeAssetCategory eq '" +
                              this.categoryCode +
                              "'"
                          )
                          .subscribe({
                            next: (workorderTemplate) => {
                              this.workorderTemplate = workorderTemplate;

                              this.detailWorkorderService
                                .getTaskDetails(
                                  this.BIXI_userAuthObject.tenantId,
                                  this.currentUserCompany.id
                                )
                                .subscribe({
                                  next: (definitions) => {
                                    this.taskLineDefinitions =
                                      definitions.value;

                                    if (
                                      window.location.href.includes(
                                        '?wo-opened=false'
                                      )
                                    ) {
                                      this.closeOpenWorkorderpanelEvent.next();
                                    }

                                    // this.getTaskCategoryByDocs();
                                  },
                                  error: (error) => {},
                                });
                            },
                            error: (error) => {
                              Notiflix.Loading.remove();
                              Notiflix.Notify.failure(
                                error,
                                {
                                  timeout: 3000,
                                  showOnlyTheLastOne: true,
                                  width: "400px",
                                }
                              );
                            },
                          });

                        Notiflix.Loading.remove();
                      }
                    },

                    error: (error) => {
                      Notiflix.Loading.remove();
                      Notiflix.Notify.failure(error, {
                        timeout: 3000,
                        showOnlyTheLastOne: true, 
                        width: "400px",
                      });
                    },
                  });

                this.getBikeParts();

                this.openWorkOrderService
                  .getTrackedItemsStatistics(
                    this.bixiUserAuthObject.tenantId,
                    this.currentUserCompany.id,
                    "noActif eq '" + this.equipementNo + "'",
                    '$expand=trackedItemsbyActifs'
                  )
                  .subscribe({
                    next: (consumptionTrackData) => {
                      this.consumptionTrackData =
                        consumptionTrackData.value[0].trackedItemsbyActifs;
                    },
                    error: (error) => {
                      Notiflix.Notify.failure(
                        error,
                        {
                          timeout: 3000,
                          showOnlyTheLastOne: true, 
                          width: "400px",
                        }
                      );                      
                    },
                  });
              },
              error: (error) => {
                Notiflix.Notify.failure(
                  'Aucun équipement ou bon de travail ne correspond au numéro saisi!',
                  {
                    timeout: 3000,
                    showOnlyTheLastOne: true, 
                    width: "400px",
                  }
                );
                // redirect user to home page
                this.router.navigateByUrl(
                  `home/${this.currentUserCompany.id}/${this.currentProfile.id}`
                );
              },
            });
        } else {
          // redirect user to home page
          this.router.navigateByUrl(
            `home/${this.currentUserCompany.id}/${this.currentProfile.id}`
          );
        }
      });
  }

  onpenEquipementAssocieModal() {
    this.equipementAssocieModal = new bootstrap.Modal(
      document.getElementById('associatedEquipModal'),
      {}
    );
    this.equipementAssocieModal.show();
  }

  replaceSubstitut(parentSystemId: string, no: string, substituteNo: string) {
    if(!this.isUserBlocked){
      Notiflix.Confirm.show(
        'Confirmation',
        `Êtes-vous sûr de vouloir changer ${no} par ${substituteNo} ?`,
        'Confirmer',
        'Annuler',
        () => {
          this.replaceSubstitutConfirmed(parentSystemId, no, substituteNo);
        },
        () => {},
        {
          // width: '450px',
          okButtonBackground: '#04B76B',
          cancelButtonBackground: '#FF5E6D',
          titleColor: '#392C70',
          borderRadius: '5px',
          backgroundColor: '#ffffff',
        }
      );
    }
    else{
      this.displayPermissionErrorMessage();
    }
  }

  replaceSubstitutConfirmed(
    parentSystemId: string,
    no: string,
    substituteNo: string
  ) {
      if(!this.isUserBlocked){
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff',
      });
      this.bikePartsListService
        .patchBikePartsSubstitut(
          this.bixiUserAuthObject.tenantId,
          this.currentUserCompany.id,
          parentSystemId,
          {
            no: substituteNo,
          }
        )
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.getBikeParts();
            Notiflix.Notify.success(
              `Article ${no} a été remplacé par ${substituteNo} avec succès.`,
              {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              }
            );
            Notiflix.Loading.remove();
          },
          error: (error) => {
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            });          
            Notiflix.Loading.remove();
          },
        });
    }
    else{
      this.displayPermissionErrorMessage();
    }
  }

  postOrderTransfert(body: any, isReturn: boolean) {
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    this.bikePartsListService
      .postOrderTransfert(
        this.bixiUserAuthObject.tenantId,
        this.currentUserCompany.id,
        body
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (result) => {
          this.getBikeParts();
          if (!isReturn)
            Notiflix.Notify.success(
              `Ordres de transfert créés avec succès.`,
              {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              }
            );          
          else 
            Notiflix.Notify.success(
              `Retours de pièces créés avec succès.`,
              {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              })  

          Notiflix.Loading.remove();
        },
        error: (error) => {
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          });    
          Notiflix.Loading.remove();
        },
      });
  }

  transfertOrder() {
    if(!this.isUserBlocked && this.editWorkOrderFeatures.createTransferOrder){
      const selectedRecords =
        this.bikePartsGrid.getSelectedRecords() as WorkOrderLine[];
      const distinations = selectedRecords
        .map((x) => x.locationDistination)
        .filter(this.bikePartsListService.onlyUnique);

      distinations.forEach((distination) => {
        const body = {
          noTransferOrder: '',
          transferFromCode: this.wos[0].locationSource,
          transferToCode: distination,
          eraAssetN: '',
          createTransferLines: [],
        };

        const lines: WorkOrderLine[] = selectedRecords.filter(
          (x) =>
            x.locationDistination === distination &&
            !x.transferOrderNo &&
            x.quantity > 0
        );
        const alreadyTransferedLines: WorkOrderLine[] = selectedRecords.filter(
          (x) => x.locationDistination === distination && x.transferOrderNo
        );
        const noQtyLines: WorkOrderLine[] = selectedRecords.filter(
          (x) => x.locationDistination === distination && x.quantity <= 0
        );
        // added condition to check valid quantity
        lines.forEach((line) => {
          if (!line.transferOrderNo) {
            body.createTransferLines.push({
              itemNo: line.no,
              variantCode: line.variantCode,
              quantity: line.quantity,
              eraWorkOrderNo: line.documentNo,
              eraWorkOrderLineNo: line.lineNo,
              bolReturnOrder: false,
            });
          }
        });
        if (body.createTransferLines.length > 0)
          this.postOrderTransfert(body, false);
        else {
          if (alreadyTransferedLines.length > 0)
            Notiflix.Notify.warning(
              `Impossible de créer Ordre de transfert, car un N° Transfert existe déjà`,
              {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              })             
          if (noQtyLines.length > 0)
            Notiflix.Notify.warning(
              `Impossible de créer un ordre de transfert, car la quantité est égale à zéro`,
              {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              })            
        }
      });
    }
    else this.displayPermissionErrorMessage();
  }

  returnParts() {
    if(!this.isUserBlocked && this.editWorkOrderFeatures.createReturnPart){
      const selectedRecords =
        this.bikePartsGrid.getSelectedRecords() as WorkOrderLine[];
      const distinations = selectedRecords
        .map((x) => x.locationDistination)
        .filter(this.bikePartsListService.onlyUnique);

      distinations.forEach((distination) => {
        const body = {
          noTransferOrder: '',
          transferFromCode: distination,
          transferToCode: this.wos[0].locationSource,
          eraAssetN: '',
          createTransferLines: [],
        };

        const lines: WorkOrderLine[] = selectedRecords.filter(
          (x) => x.locationDistination === distination
        );

        lines.forEach((line) => {
          if (!line.eraReturnOrderNo && line.eraPostedTransfer) {
            body.createTransferLines.push({
              itemNo: line.no,
              variantCode: line.variantCode,
              quantity: line.quantity,
              eraWorkOrderNo: line.documentNo,
              eraWorkOrderLineNo: line.lineNo,
              bolReturnOrder: true,
            });
          }
        });
        if (body.createTransferLines.length > 0)
          this.postOrderTransfert(body, true);
        else
          Notiflix.Notify.warning(
            `Impossible de créer un Retour, car un N° Retour existe déjà ou aucun ordre de transfert est effectué`,
            {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            })         
      });
    }
    else this.displayPermissionErrorMessage();
  }

  validateTransfertOrder(isTransfered: boolean) {
    if(!this.isUserBlocked && 
      ((isTransfered && this.editWorkOrderFeatures.validateTransferOrder) || 
      (!isTransfered && this.editWorkOrderFeatures.validateReturnPart))){
      // 1 selection
      this.isTransfered = isTransfered;
      this.modalValidationTitle = isTransfered
        ? 'Validation des ordres de transfert'!
        : 'Validation des retours de pièces';
      const selectedLines =
        this.bikePartsGrid.getSelectedRecords() as WorkOrderLine[];
      // 2 - filtrer et exclure les lignes avec OT
      if (isTransfered) {
        this.validateSelectedRecords = selectedLines.filter(
          (x) => x.transferOrderNo
        );
      } else {
        this.validateSelectedRecords = selectedLines.filter(
          (x) => x.eraReturnOrderNo
        );
      }
    }
    else this.displayPermissionErrorMessage();
  }

  expandCollapse() {
    this.isAllDetailsExpanded = !this.isAllDetailsExpanded;
    if (this.isAllDetailsExpanded) {
      this.expandCollapseClass = 'fa-eye-slash';
      this.bikePartsGrid.detailRowModule.expandAll();
    } else {
      this.expandCollapseClass = 'fa-eye';
      this.bikePartsGrid.detailRowModule.collapseAll();
    }
  }

  validate() {
    if (this.validateSelectedRecords.length)
      this.postValidItems(this.validateSelectedRecords);
    else 
      Notiflix.Notify.warning(
        `Aucune ligne à valider est trouvée!`,
        {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
        })     
  }

  postValidItems(validItems: WorkOrderLine[]) {
    const body: string = this.isTransfered
      ? this.bikePartsListService.getBodyTransfer(validItems)
      : this.bikePartsListService.getBodyReturn(validItems);
    const body2 = JSON.parse(body);
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    this.bikePartsListService
      .postValidItems(
        this.bixiUserAuthObject.tenantId,
        this.currentUserCompany.id,
        body2
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (result) => {
          this.confirmPostValidItems(result?.entryNo);
        },

        error: (error) => {
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
           }); 
          Notiflix.Loading.remove();
        },
      });
  }

  confirmPostValidItems(entryNo: number) {
    this.bikePartsListService
      .confirmValidItems(
        this.bixiUserAuthObject.tenantId,
        this.currentUserCompany.id,
        entryNo
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.getBikeParts();
          if (this.isTransfered)
            Notiflix.Notify.success(`Ordres de transfert validés avec succès.`,
            {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            });
          else
            Notiflix.Notify.success(`Retours de pièces validés avec succès.`,
            {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            });
          Notiflix.Loading.remove();
          var closeModalValidation =
            document.getElementById('btnModalCloseBtn');
          closeModalValidation.click();
        },
        error: (error) => {
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          });            
          Notiflix.Loading.remove();
        },
      });
  }

  private displayPermissionErrorMessage() {
    if(this.storageService.getItem('Bixi_BlockAndReturnScan')){
      const statusApiPermissions:StatusProfileBlockAndReturnScan = JSON.parse(this.storageService.getItem("Bixi_BlockAndReturnScan")) || null;
      if(statusApiPermissions && statusApiPermissions.isBlockedAction){
        Notiflix.Notify.warning(
            "Le statut en cours "+ statusApiPermissions.currentStatus+" du bon de travail bloque cette opération pour le profil " +
              this.communService.roleEnglishToFrench[statusApiPermissions.currentProfile],{
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            }
          );
      }
      else {
        Notiflix.Notify.warning(
            "Vous ne pouvez pas effectuer cette action car vous êtes un " +
              this.communService.roleEnglishToFrench[statusApiPermissions.currentProfile],{
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            }
          );
      }
    } 
  } 

  public rowDataBoundBikePartsGrid(args: RowDataBoundEventArgs) {
    if (args?.data['transferOrderNo'] && !args?.data['eraPostedTransfer']) {
      args?.row?.classList.add('notReceivedTO');
    }
    if (args?.data['transferOrderNo'] && args?.data['eraPostedTransfer']) {
      args?.row?.classList.add('receivedTO');
    }
    const parentData: number = args?.data['lineNo'];
    const childrecord: object[] = new DataManager(this.childGrid.dataSource as JSON[]).executeLocal(new Query().where('lineNo', 'equal', parentData, true));
    if (childrecord.length === 0) {
        // Here hide which parent row has no child records
        const rowElement = args.row as HTMLTableRowElement;
        const cellElement= rowElement.querySelector('td') as HTMLTableCellElement
        cellElement.innerHTML = ' '; 
        cellElement.className = 'e-customizedexpandcell';
    }
  }

   async generateCodeBar(valueBarCode: string, isReturnOrderNo: boolean) {
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    this.isBarCodeVisible = 'block';
    this.barcode.value = valueBarCode;
    try{      
      await new Promise(resolve => setTimeout(resolve)).then(() => this.exportAsBase64Image(isReturnOrderNo))
    }
    catch{
      Notiflix.Loading.remove();
      Notiflix.Notify.failure('Une erreur est survenue lors du génération code barre de N° Transfet '+ this.barcode.value, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          }
        );
    }
  }

  async exportAsBase64Image(isReturnOrderNo: boolean){
    this.isReturnOrderNoPrint = false;
    await this.barcode.exportAsBase64Image('PNG').then(data => {
      if(data){
        this.codeBarImgBase64 = data;
        this.isBarCodeVisible = 'none';
        this.onCodeBarOTClickModal = new bootstrap.Modal(document.getElementById('onCodeBarOTClickModal'), {});
        this.isReturnOrderNoPrint = isReturnOrderNo;
        this.onCodeBarOTClickModal.show();
      }
      else{

         Notiflix.Notify.failure('Une erreur est survenue lors du génération code barre de N° Transfet '+isReturnOrderNo? 'retour ' : ''+this.barcode.value,{
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          }
        );
      }
      Notiflix.Loading.remove();
    })    
  }

  printReturnOrderLabel() {   
    if(this.barcode.value){
  
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff',
      });
      this.bikePartsListService
        .printReturnOrderLabel(
          this.BIXI_userAuthObject.tenantId,
          this.currentUserCompany.id,
          this.barcode?.value
        )
        .subscribe({
          next: (report) => {
            Notiflix.Loading.remove();
            if (report?.value)
              this.communService.openPdfFileInNewTab(report?.value);
            else
              Notiflix.Notify.failure(
                `Aucune étiquette trouvée pour l'ordre de transfert retour ${this.barcode?.value} `
              );
          },
          error: (error) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            });
          },
        });
    }
    else{
      Notiflix.Notify.failure("Impossible d'imprimer l'étiquette. N° Transfert RP invalide ou inexistant", {
        timeout: 3000,
        showOnlyTheLastOne: true, 
        width: "400px",
      });       
    }
  }


  hideCodeBar() {
    this.isBarCodeVisible = 'none';
    this.barcode.value = '';
  }

  rowSelected(): void {
    const selectedLines =
      this.bikePartsGrid.getSelectedRecords() as WorkOrderLine[];
    this.disableButton = !(selectedLines.length > 0);
  }

  getWorkOrderLines(workorder: any, taskLine, mode) {
    // Loading indicator with a message, and the new options
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    this.BTandLines = { BT: workorder, expenses: [], items: [] };
    this.detailWorkorderService
      .getWorkOrderLines(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        workorder.systemId,
        "$filter=type eq 'Item' and codeTaskCategory eq '" +
          taskLine.codeTaskCategory +
          "'"
      )
      .subscribe({
        next: (resData) => {
          this.itemWorkOrderLines = resData.value;
          this.BTandLines.items = this.itemWorkOrderLines;

          this.detailWorkorderService
            .getWorkOrderLines(
              this.BIXI_userAuthObject.tenantId,
              this.currentUserCompany.id,
              workorder.systemId,
              "$filter=type eq 'Expense' and codeTaskCategory eq '" +
                taskLine.codeTaskCategory +
                "'"
            )
            .subscribe({
              next: (resData) => {
                Notiflix.Loading.remove();
                this.expenseWorkOrderLines = resData.value;

                this.BTandLines.expenses = this.expenseWorkOrderLines;

                if (mode) {
                  switch (mode) {
                    case 'ff':
                      this.onSelectedAllValidateClickEvent.next({
                        info_1: null,
                        info_2: false,
                      });
                      this.getEquipement();
                      break;
                    case 'fr':
                      this.onReopenGeneralClickEvent.next({
                        info_1: 'finished',
                        info_2: false,
                      });
                      this.getEquipement();
                      break;
                    case 'vv':
                      this.onSelectedAllValidateClickEvent.next({
                        info_1: 'finished',
                        info_2: false,
                      });
                      this.getEquipement();
                      break;
                    case 'vr':
                      this.onReopenGeneralClickEvent.next({
                        info_1: 'validated',
                        info_2: false,
                      });
                      this.getEquipement();
                      break;
                    default:
                      break;
                  }
                }
              },
              error: (error) => {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(error, {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                });
              },
            });
        },
        error: (error) => {
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          });
        },
      });
  }

  getTaskCategoryByDocs(){
    this.detailWorkorderService.getTaskCategoryByDoc(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "$filter=documentNo eq '"+ this.roueWorkorder.no +"'" ).subscribe({
      next: (taskCategoryByDocs) => {
        this.taskCategoryByDocs = taskCategoryByDocs.value
        this.templateRoue.columnLines.forEach((columnLine, i) =>{                
          columnLine.sectionLines.forEach((sectionLine, j) =>{
            sectionLine.subSectionLines.forEach((subSectionLine, k) =>{
              subSectionLine.taskLines.forEach((taskLine, l) =>{
                const foundData = this.taskLineDefinitions.filter(taskLineDefinition => taskLineDefinition.code == taskLine.displayName)
                if(foundData.length > 0){
                    taskLine.definition = foundData[0] 
                }else{
                  taskLine.definition = undefined
                }
                if(taskLine.taskCategoryByDoc == undefined){           
                  const _foundData = this.taskCategoryByDocs.filter(taskCategoryByDoc => (taskCategoryByDoc.documentNo == this.roueWorkorder.no && taskCategoryByDoc.codeTaskCategory == this.templateRoue.columnLines[i].sectionLines[j].subSectionLines[k].taskLines[l].codeTaskCategory))                 
                  if(_foundData.length > 0){
                      taskLine.taskCategoryByDoc = _foundData[0] 
                  }             
                }
              })
            })
          })
        })       
      },
      error: (error) => {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure(error, {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
         })
      }
    })
  }

  createNewWorkOrder(equipRoue){
      const assetNum = equipRoue.num
      const data =  { sourceBT: this.equipement.workOrderNo, assetNo: assetNum }
      this.storageService.setItem('equipementRoueSerialNumber', assetNum)
      const concatenatedUrl = 'createLinkedWos'
      this.openWorkOrderService.createElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, data, concatenatedUrl).subscribe({
        next: (data) => {
          this.openWorkOrderService.getWorkorders(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "no eq '"+ data.workOrderNo +"'").subscribe({
              next: (workOrder) => {
                if(workOrder.value.length > 0){
                  this.roueWorkorder = workOrder.value[0]
                  this.createdByTemplateId =  workOrder.value[0].createdByTemplateId
                  const concatenatedUrl = "/workOrders("+ this.roueWorkorder.systemId +")"
                  this.openWorkOrderService.editElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.roueWorkorder, concatenatedUrl).subscribe({
                    next: (wo) => {
                      this.roueWorkorder = wo
                      this.detailWorkorderService.getWorkOrderTemplateWithoutFilter(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.createdByTemplateId).subscribe({
                        next: (workorderTemplate) => {
                          this.templateRoue = workorderTemplate
                          this.detailWorkorderService.getTaskDetails(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id).subscribe({
                            next: (definitions) => {
                              this.taskLineDefinitions = definitions.value
                              this.getTaskCategoryByDocs()   
                            },
                            error: (error) => {
                            }
                          })
                          Notiflix.Loading.remove();
                          Notiflix.Notify.success("Bon de tavail crée avec succès!", {timeout: 3000, width: "400px"})
                          this.refreshAssetLinkEvent.next()                      
                          if(!this.associatedEquipTemplateModal){
                              this.associatedEquipTemplateModal = new bootstrap.Modal(document.getElementById('associatedEquipTemplateModal'), {})
                              this.associatedEquipTemplateModal.show()  
                            }else {
                              this.associatedEquipTemplateModal.show()  
                            }                      
                        },
                        error: (error) => {
                          Notiflix.Loading.remove();
                          Notiflix.Notify.failure("Aucun template trouvé pour cet équipement", {
                            timeout: 3000,
                            showOnlyTheLastOne: true, 
                            width: "400px",
                          })   
                        }
                      })                    
                    },
                    error: (error) => {}
                  })                 
                }
              },
              error: (error) => {
              }
          })     
        },
        error: (error) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
           })
        }
      })
    this.equipementAssocieModal.hide() 
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff'
    });
  }

  updateTaskOnWorkOrder(e){
    this.EObjectToUpdate = e
    if(e.data == 'taskCategoryByDoc'){
      if(e.object.taskCategoryByDoc.createdByProfil =='Supervisor' || e.object.taskCategoryByDoc.createdByProfil =='Evaluator'){
        if(this.currentProfile.profil == 'Supervisor' || this.currentProfile.profil == 'Evaluator'){
          this.canRemoveTask(e)
        }else{    
        Notiflix.Loading.remove();
        Notiflix.Notify.failure("Vous n'avez pas le droit de retirer cette tâche car vous êtes un "+ this.communService.roleEnglishToFrench[this.currentProfile.profil], {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
           })
        }
    }else if(e.object.taskCategoryByDoc.createdByProfil =='Mechanic'){
      this.canRemoveTask(e)
    } 
    }else if(e.data == 'taskLine'){
      if(e.object.fieldType == 'Selection'){
        this.AddTaskToWorkOrder(e)
      }else if(e.object.fieldType == 'X (Expenses)' || e.object.fieldType == 'X (Items)' || e.object.fieldType == 'X (Items/Expenses)'){
          var myModal = new bootstrap.Modal(
            document.getElementById('mulitipeValueTaskLineModal'),
            {}
          );
          myModal.toggle();        
      }else{
        Notiflix.Notify.failure("FieldType inconnue. Veuillez reconfigurer le template", {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
         })
      }
    }
  }

  getTaskLineIcon(displayName, columnLineIndex, sectionLineIndex, subSectionLineIndex, taskLineIndex){
    const dataFound = this.defDisplayNames.filter(defDisplayName => defDisplayName.code == displayName)
    if(dataFound.length > 0){
       const content64 = dataFound[0].content64
       const element:any = document.getElementById('taskLine_icon_'+ columnLineIndex +'_'+ sectionLineIndex +'_'+ subSectionLineIndex +'_'+taskLineIndex)
       if(element !== null){
         if(content64.trim() !== ''){
           element.src = "data:image/png;base64,"+content64
         }else{
           element.style.display = 'none'
         }   
       }    
    }
  }

  onBtClose(){
    if(this.equipementAssocieModal && !this.equipementAssocieModal._isShown){
      this.equipementAssocieModal.show()
    } 
  } 
  
  canRemoveTask(e){
    const systemId = e.object.taskCategoryByDoc.systemId
    const concatenatedUrl = "/taskCategoriesByDocs("+systemId+")"
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff'
    });
    this.detailWorkorderService.deleteElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, concatenatedUrl).subscribe({
      next: (resData) => {
        this.getTaskCategoryByDocs()
        Notiflix.Loading.remove();
        e.object.taskCategoryByDoc = undefined
        Notiflix.Notify.success("Tâche retirée avec succès", {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
         })
      },
      error: (error) => {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure(error, {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
         })
      }
    })
  }  

  AddTaskToWorkOrder(e){
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff'
    });
    this.detailWorkorderService.getWorkOrderCategoriesWithFilter(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "code eq '"+ e.object.codeTaskCategory +"'").subscribe({
      next: (resData) => {
        const concatenatedUrl = "/taskCategoriesByDocs"
        const data = {
          codeTaskCategory: e.object.codeTaskCategory,
          codeTaskCategoryId: resData.value[0].systemId,
          createdByProfil: this.currentProfile.profil,
          createdByTemplate: this.templateRoue.code,
          createdByTemplateId: this.createdByTemplateId,
          documentId: this.roueWorkorder.systemId,
          documentNo: this.roueWorkorder.no,
          multiple: e.object.fieldType == 'Selection' ? 1 : this.taskMultipleValue,
          documentType: 'Order',
        }
        this.detailWorkorderService.createElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, data, concatenatedUrl).subscribe({
          next: (resData) => {
            Notiflix.Loading.remove();
            e.object.taskCategoryByDoc = resData
            e.object.codeTaskCategory = resData.codeTaskCategory
            if(this.associatedEquipTemplateModal && !this.associatedEquipTemplateModal._isShown){
                 this.associatedEquipTemplateModal.show()
             }
            Notiflix.Notify.success("Tâche ajoutée avec succès", {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
             })
          },
          error: (error) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
             })
          }
        })
      },
      error: (error) => {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure(error, {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
         })
      }
    })
  }  

  addTaskByMultipleValue() {
    if (this.EObjectToUpdate !== null) {
      if (
        this.taskMultipleValue < this.EObjectToUpdate.object.fieldMINValue ||
        this.taskMultipleValue > this.EObjectToUpdate.object.fieldMAXValue
      ) {
        setTimeout(() => {
          var myModal = new bootstrap.Modal(
            document.getElementById('mulitipeValueTaskLineModal'),
            {}
          );
          myModal.toggle();
        }, 300)
        Notiflix.Notify.failure(
          'Le multiplicateur doit être compris entre la valeur Min et la valeur Max.',
          {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          }
        );
      } else {
        this.AddTaskToWorkOrder(this.EObjectToUpdate);
      }
    }
  } 
  
  onMultipleTaskCancel(){
    if(this.associatedEquipTemplateModal && !this.associatedEquipTemplateModal._isShown){
      this.associatedEquipTemplateModal.show()
    }
  }   

}
