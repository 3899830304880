<div class="container-scroller">
  <!-- Nav bar component -->
  <app-nav-bar [BIXI_userAuthObject]="BIXI_userAuthObject" [currentProfile]="currentProfile"
    [currentUserCompany]="currentUserCompany" (changeProfileEvent)="changeProfile($event)" [equipementNo]="equipementNo"
    [pageName]="pageName" (logoutEvent)="logout()"></app-nav-bar>

  <div class="container-fluid page-body-wrapper add-padding">
    <!-- Side Bar component -->
    <app-side-bar [currentProfile]="currentProfile" [currentUserCompany]="currentUserCompany" [pageName]="pageName"
      [equipementNo]="equipementNo"></app-side-bar>

    <div class="main-panel" style="margin-left: 70px">
      <div class="content-wrapper p-3">
        <app-loading *ngIf="!templatesLoaded"></app-loading>

        <div id="collapseOne" *ngIf="templatesLoaded" class="accordion-collapse collapse show"
          aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div class="row">
            <div class="col-9"></div>
            <div class="col-3">
              <form class="navbar-form">
                <div class="input-group">
                  <input style="border-radius: 8px 0px 0px 8px" class="form-control" type="text" name="search"
                    (input)="sendTheNewValue($event)" placeholder="Recherche de templates" />
                  <span class="input-group-btn">
                    <i style="
                        padding-left: 16px;
                        padding-right: 10px;
                        padding-top: 15px;
                      " class="fa fa-search"></i>
                  </span>
                </div>
              </form>
            </div>
          </div>

          <div class="accordion-body py-2">
            <div class="card cardWorkOrdersGrid">
              <ejs-grid #currentWoGrid locale="fr-CA" [dataSource]="filtredTemplates" id="gridcomp" allowPaging="true"
                allowSorting="true" gridLines="Horizontal" showColumnChooser="false"
                [contextMenuItems]="contextMenuItems" (contextMenuClick)="contextMenuclick($event)"
                [allowExcelExport]="true" [allowPdfExport]="true" [allowSelection]="true" [allowMultiSorting]="true"
                rowHeight="30">
                <e-columns>
                  <e-column field="code" headerText="Code" width="150" textAlign="Left"></e-column>
                  <e-column field="description" headerText="Description" width="150" textAlign="Left"></e-column>
                  <e-column field="codeAssetCategory" headerText="Catégorie équipement" width="150" textAlign="Left">
                    <ng-template #template let-data>
                      <div style="padding-top: 30px; padding-bottom: 30px">
                        {{ data.codeAssetCategory }}
                      </div>
                    </ng-template>
                  </e-column>
                  <e-column field="status" headerText="Statut" width="150" textAlign="Left"></e-column>
                  <e-column field="templateColumns" headerText="Nombre maximum de colonne" width="150"
                    textAlign="Center"></e-column>
                  <e-column field="columnLines.length" headerText="Nombre de colonnes créées" width="150"
                    textAlign="Center"></e-column>
                </e-columns>
              </ejs-grid>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fab-container">
      <div class="fab shadow">
        <div class="fab-content">
          <span class="material-icons">arrow_drop_up</span>
        </div>
      </div>
      <div class="sub-button shadow" *ngIf="templateFeatures.createTemplate">
        <a [routerLink]="
            '/templatemaker/' +
            currentUserCompany.id +
            '/' +
            currentProfile.id +
            '/serial-number/'
          " href="#">
          <span class="material-icons">add</span>
        </a>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="_templateModal_2" tabindex="-1" aria-labelledby="templateLabel" aria-hidden="true"
  data-bs-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-h6-title-container" id="templateLabel">
          <i class="mr-2 fa" class="fa fa-edit modal-icon-title"></i>&nbsp;
          <b class="modal-title-style">Modifier l'entête</b>
        </h6>
      </div>
      <div class="modal-body pt-2 pb-2">
        <div class="row mt-2">
          <div class="grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="forms-sample" *ngIf="headerTemplateMode">
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Code</label>
                    <div class="col-sm-7">
                      <input type="text" name="code" class="form-control form-control-sm py-1"
                        [(ngModel)]="template.code" #templateHeaderCodeElement2 #code="ngModel" required />
                      <div *ngIf="code.invalid && (code.dirty || code.touched)"
                        class="alert alert-danger input-error-style">
                        <div *ngIf="code.errors?.['required']">
                          Le code est requis
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Description</label>
                    <div class="col-sm-7">
                      <input type="text" name="description" class="form-control form-control-sm py-1"
                        [(ngModel)]="template.description" #templateHeaderDescriptionElement2 #name="ngModel"
                        required="" />
                      <div *ngIf="name.invalid && (name.dirty || name.touched)"
                        class="alert alert-danger input-error-style">
                        <div *ngIf="name.errors?.['required']">
                          La description est requise
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Catégorie d'équipement</label>
                    <div class="col-sm-7">
                      <select (change)="onAssetCategoryChange($event)" class="form-select form-select-sm py-1"
                        [(ngModel)]="template.codeAssetCategory" #templateHeaderAssetCategoryCodeElement>
                        <option *ngFor="let assetCategory of assetCategories" [value]="assetCategory.code">
                          {{ assetCategory.description }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Code d'équipement</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control form-control-sm py-1" (input)="sendTheNewValue($event)"
                        [(ngModel)]="template.codeAssetCategory" #templateHeaderAssetCategoryDescriptionElement />
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Nombre de colonnes</label>
                    <div class="col-sm-7">
                      <select class="form-select form-select-sm py-1" [(ngModel)]="template.templateColumns">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Statut</label>
                    <div class="col-sm-7">
                      <select class="form-select form-select-sm py-1" value="Open" [(ngModel)]="template.status">
                        <option value="Released">Released</option>
                        <option value="Open">Open</option>
                      </select>
                    </div>
                  </div>
                  <div class="float-right mt-3">
                    <button type="button" class="btn btn-primary btn-sm mr-2" data-bs-dismiss="modal"
                      (click)="saveTemplateHeader()"
                      [disabled]="code.errors?.['required'] || name.errors?.['required']">
                      <i class="fa fa-save mr-2"></i>
                      Sauvegarder
                    </button>
                    <button class="btn btn-light btn-sm" data-bs-dismiss="modal" (click)="cancelTemplateHeaderSave()">
                      <i class="fa fa-times mr-2"></i>
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>