import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as Notiflix from 'notiflix';
import { Company } from 'src/app/core/shared/models/company.model';
import { StorageService } from 'src/app/core/shared/services/storage.service';
import { HomeService } from './services/home.service';
import { Profile } from 'src/app/core/shared/models/profile';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { AccessService } from 'src/app/guard/access.service';
import { Features } from 'src/app/models/accessGrantObject.model';
import { TaskControlService } from 'src/app/core/shared/services/task-control.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  isBikeScan = true;
  disabledWoScanBtn = '';
  styleBikeScanBtn = 'btn-primary';
  styleWoScanBtn = 'btn-light';
  inpuScanPlaceholder = "Numéro de série d'équipement ou de bon de travail";
  codeValue = '';
  userProfil = '';
  userProfilTitle = '';
  currentUserCompany: Company | undefined;
  currentUser: any;
  selectedRecords: any = null;
  currentProfile: Profile = null;
  BIXI_userAuthObject: any = null;
  selectedWorEquipment = '';
  pageName = 'home';
  currentProfiles: Profile[] = [];
  scanFeatures: Features = null;
  isUserBlocked: boolean = false;  
  @ViewChild('scanInput') scanInput: ElementRef;

 
  constructor(
    private router: Router,
    private storageService: StorageService,
    private homeService: HomeService,
    private AuthService: AuthService,
    private accessService: AccessService,
    private taskControlService: TaskControlService
  ) {}

  ngOnInit() {
    Notiflix.Notify.init({
      position: 'right-bottom',
    });
    Notiflix.Loading.remove();
    // check if user has data in browser storage, else redirect himn to login page
    if (
      !this.storageService.getItem('bixi-user-auth') ||
      !this.storageService.getItem('bixi-currentCompany') ||
      !this.storageService.getItem('bixi-currentProfile')
    ) {
      this.router.navigateByUrl('/auth/login');
    } else {
      this.storageService.removeBlockAndReturnScanObject();
      this.BIXI_userAuthObject = JSON.parse(
        this.storageService.getItem('bixi-user-auth')
      );
      this.currentUserCompany = JSON.parse(
        this.storageService.getItem('bixi-currentCompany')
      );
      this.currentProfile = JSON.parse(
        this.storageService.getItem('bixi-currentProfile')
      );
      this.currentProfiles = JSON.parse(
        this.storageService.getItem('bixi-currentProfiles')
      );
    }
    // Bootstrap tooltip initialization
    this.storageService.setItem('bixiApp_scanType', 'B')
    
    this.scanFeatures = this.accessService.getFeatureByProfile(
      this.currentProfile.profil
    );
    setTimeout(() => {
      this.scanInput?.nativeElement?.focus()      
    });
  }

  changeProfile(profil) {
    if (profil) {
      const profileFound = this.currentProfiles.find(
        (currentProfile) => currentProfile.profil == profil
      );
      this.storageService.setItem('bixi-currentProfile', JSON.stringify(profileFound))
      this.reloadCurrentRoute();
    } else {
      this.AuthService.logout();
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    currentUrl = currentUrl.replace('%20', ' ');
    location.reload()
  }

  logout() {
    Notiflix.Confirm.show(
      'Confirmation',
      'Êtes-vous sûr de vouloir vous déconnecter?',
      'Confirmer',
      'Annuler',
      () => {
        this.router.navigateByUrl('/');
      },
      () => {},
      {
        width: '450px',
        okButtonBackground: '#04B76B',
        cancelButtonBackground: '#FF5E6D',
        titleColor: '#392C70',
        borderRadius: '5px',
        backgroundColor: '#ffffff',
      }
    );
  }

  // TODO Valider lors du scan si c'est équipement ou BT
  scanCode() {
    if (this.codeValue) {
      // Loading indicator with a message, and the new options
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff',
      });

      const data = {
        no: this.codeValue,
        profil: this.currentProfile.profil,
        createCorrectiveWO: this.scanFeatures.createCorrectiveWO,
        source: true
      };
      this.homeService
        .scanner(
          this.BIXI_userAuthObject.tenantId,
          this.currentUserCompany.id,
          JSON.stringify(data)
        )
        .subscribe({
          next: (equipement) => {
            this.storageService.setItem("Bixi_WorkOrderLedSysId", equipement?.workOrderId);
            this.storageService.setItem("Bixi_WorkOrderBixiStatus", equipement?.workOrderBixiStatus);
            this.getBlockActionsByStatusProfile(equipement);
          },
          error: (error) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            });
          },
        });
    } else {
      Notiflix.Notify.warning('Aucun numéro de série saisi!', {
        timeout: 3000,
        showOnlyTheLastOne: true, 
        width: "400px",
      });
    }
  }

  private redirectionAfterScan(equipement){
    if (equipement.type == 0) {
      this.storageService.setItem(
        'currentSerialNumber',
        this.codeValue
      );
      if (equipement.multiCorrectifWorkOrder) {
        this.router.navigateByUrl(
          `detailworkorder/${this.currentUserCompany.id}/${this.currentProfile.id}/workOrder/${equipement.workOrderNo}?wo-opened=false`
        );
      } 
      else {
        this.router.navigateByUrl(
          `openworkorder/${this.currentUserCompany.id}/${this.currentProfile.id}/serial-number/${this.codeValue}`
        );
      }
    }

    if (equipement.type == 1) {
      this.storageService.setItem('currentSerialNumber', equipement.no);
      if (equipement.multiCorrectifWorkOrder) {
        this.router.navigateByUrl(
          `detailworkorder/${this.currentUserCompany.id}/${this.currentProfile.id}/workOrder/${equipement.workOrderNo}?wo-opened=false`
        );
      } else {
        this.router.navigateByUrl(
          `detailworkorder/${this.currentUserCompany.id}/${this.currentProfile.id}/workOrder/${equipement.workOrderNo}`
        );
      }
    }
  }

  private getBlockActionsByStatusProfile(equipement){
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });    
    const workOrderLedSysId = this.storageService.getItem("Bixi_WorkOrderLedSysId");
    const currentWoStatus = this.storageService.getItem("Bixi_WorkOrderBixiStatus");
    this.taskControlService.blockActionsByStatusBtProfile(
          this.BIXI_userAuthObject.tenantId,
          this.currentUserCompany?.id,
          currentWoStatus,
          this.currentProfile?.profil,
          workOrderLedSysId   
      ).subscribe({
        next: (permissions) => {
          this.storageService.setBlockAndReturnScanObject(this.currentProfile?.profil, currentWoStatus, workOrderLedSysId, permissions.isBlockedAction, permissions.isRetrunScan)
          Notiflix.Loading.remove();
          this.redirectionAfterScan(equipement);
        },
        error: (error) => {
          this.storageService.setBlockAndReturnScanObject(this.currentProfile?.profil, currentWoStatus, workOrderLedSysId, true, false)
          Notiflix.Loading.remove();
          this.redirectionAfterScan(equipement);
        },
    });    
  }
}
