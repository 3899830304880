<div class="modal fade" id="associatedEquipModal" data-bs-focus="false" tabindex="-1"
  aria-labelledby="associatedEquipModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="width: 70%">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-customized-title" id="associatedEquipModalLabel">
          <i class="fa fa-cog me-2"></i><span class="fw-bold">Liste des équipements associés</span>
        </h6>
        <button type="button" class="btn btn-danger btn-sm my-1" data-bs-dismiss="modal" aria-label="Close">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <ejs-grid #listEquipGrid [dataSource]="linkedAssets" [selectionSettings]="selectionOptions"
          gridLines="Horizontal" [allowSelection]="true" [allowMultiSorting]="true" rowHeight="30"
          (rowSelecting)="rowselecting($event)">
          <e-columns>
            <e-column field="num" headerText="N° équipement" width="130" textAlign="Left"></e-column>
            <e-column field="desciption" headerText="Description" width="150" textAlign="Left"></e-column>
            <e-column field="category" headerText="Catégorie" width="120" textAlign="Left"></e-column>
            <e-column headerText="Bon de travail" width="150" textAlign="Left">
              <ng-template #template let-data>
                <a rel="nofollow" style="cursor: pointer" class="text-info fw-bold" data-bs-dismiss="modal"
                  (click)="goToTemplate2(data)">{{ data.currentWo }}</a>
              </ng-template>
            </e-column>
          </e-columns>
        </ejs-grid>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm" (click)="printWorkOrderLabel()">
          <i class="fa fa-print mr-2"></i>Imprimer étiquette
        </button>
        <button type="button" class="btn btn-success btn-sm" (click)="confirmeCreateWoEquip()"
          [disabled]="isUserBlocked" *ngIf="linkedAssetFeatures && linkedAssetFeatures.buttonCreateWO">
          <i class="fa fa-plus mr-2"></i>Créer un bon de travail
        </button>
      </div>
    </div>
  </div>
</div>