import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/config/app.config.service';
import { Observable, catchError, map, of } from 'rxjs';
import { ErrorService } from 'src/app/core/shared/services/error.service';
import { WorkOrderLine } from 'src/app/core/shared/models/WorkOrder';
import { Report } from 'src/app/core/shared/models/report.model';

@Injectable({
  providedIn: 'root',
})
export class BikePartsListService {
  defaultCompanyId = AppConfigService.settings.defaultCompanyId;
  bcCoreApiUrl = AppConfigService.settings.bcCoreApiUrl;
  customApiUrlSegment = AppConfigService.settings.customApiUrlSegment;
  bcEnvirName = AppConfigService.settings.bcEnvirName;
  defaultLg = AppConfigService.settings.defaultLanguage;
  schemaVersion = AppConfigService.settings.bcSchemaversion;

  constructor(private http: HttpClient, private errorService: ErrorService) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': 'fr-CA',
      'If-Match': '*',
    }),
  };

  getBikeParts(tenantId: any, companyId: string, $filter): Observable<any> {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/getPickingLists?$schemaversion='+this.schemaVersion+'&$expand=eraworkOrderLines($expand=eraSubstitution)&$filter=' +
      $filter;
    return this.http
      .get(url, this.httpOptions)
      .pipe(catchError(this.errorService.handleError));
  }

  patchBikePartsSubstitut(
    tenantId: any,
    companyId: string,
    systemId,
    data: any
  ): Observable<any> {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/eraReplaceBySubstatut(' +
      systemId +
      ')?$schemaversion='+this.schemaVersion
    return this.http
      .patch(url, JSON.stringify(data), this.httpOptions)
      .pipe(catchError(this.errorService.handleError));
  }

  postOrderTransfert(
    tenantId: any,
    companyId: string,
    body: any
  ): Observable<any> {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/createTransferOrderHeaders/?$expand=createTransferLines&$schemaversion='+this.schemaVersion;
    return this.http
      .post(url, JSON.stringify(body), this.httpOptions)
      .pipe(catchError(this.errorService.handleError));
  }

  postValidItems(tenantId: any, companyId: string, body: any): Observable<any> {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/updateTransferLines/?$expand=postTransferLines&$schemaversion='+this.schemaVersion;
    return this.http
      .post(url, JSON.stringify(body), this.httpOptions)
      .pipe(catchError(this.errorService.handleError));
  }

  confirmValidItems(
    tenantId: any,
    companyId: string,
    entryNo: number
  ): Observable<any> {
    const body = {
      entryNo: entryNo,
    };
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/postTransferLines(' +
      entryNo +
      ')/Microsoft.NAV.postTransfer?$schemaversion='+this.schemaVersion;;
    return this.http
      .post(url, JSON.stringify(body), this.httpOptions)
      .pipe(catchError(this.errorService.handleError));
  }

  getBodyTransfer(validItems: WorkOrderLine[]): string {
    return `{
      "entryNo": 99,
      "postTransferLines":[
        ${validItems
          .map(
            (x) =>
              '{' +
              '"documentNo": "' +
              x.transferOrderNo +
              '",' +
              '"lineNo": ' +
              x.transferOrderLineNo +
              ',' +
              '"qtyToShip": ' +
              x.qtyToShip +
              '}'
          )
          .join(',')}
      ]
    }`;
  }

  getBodyReturn(validItems: WorkOrderLine[]): string {
    return `{
      "entryNo": 99,
      "postTransferLines":[
        ${validItems
          .map(
            (x) =>
              '{' +
              '"documentNo": "' +
              x.eraReturnOrderNo +
              '",' +
              '"lineNo": ' +
              x.eraReturnOrderLineNo +
              ',' +
              '"qtyToShip": ' +
              x.qtyToShip +
              '}'
          )
          .join(',')}
      ]
    }`;
  }

  onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  printReturnOrderLabel(
    tenantId: any,
    companyId: string,
    numReturnOrder: string
  ): Observable<any> {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/workOrderPrintLabels(00000000-0000-0000-0000-000000000000)/Microsoft.NAV.runReportTOPrintLabelPdf?$schemaversion='+this.schemaVersion;
    const body = { returnOrder: numReturnOrder };
    return this.http.post(url, JSON.stringify(body), this.httpOptions).pipe(
      map((result) => result as Report),
      catchError(this.errorService.handleError)
    );
  }
}
