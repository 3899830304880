<div class="container-scroller">
  <!-- Nav bar component -->
  <app-nav-bar [BIXI_userAuthObject]="BIXI_userAuthObject" [currentProfile]="currentProfile"
    [currentUserCompany]="currentUserCompany" (changeProfileEvent)="changeProfile($event)" [equipementNo]="''"
    [pageName]="pageName" (logoutEvent)="logout()" [isUserBlocked]="isUserBlocked"></app-nav-bar>

  <div class="container-fluid page-body-wrapper add-padding">
    <!-- Side Bar component -->
    <app-side-bar [currentProfile]="currentProfile" [currentUserCompany]="currentUserCompany"
      [pageName]="pageName"></app-side-bar>

    <div class="main-panel" style="margin-left: 70px">
      <div class="content-wrapper text-center">
        <div class="card border text-center scanner-center col-md-4">
          <div class="card-body pb-0">
            <img src="../../../assets/imgs/scanner.jpg" class="mb-4 mt-2 rounded border-primary" width="140" />
            <h4>Saisir un équipement ou un bon de travail</h4>
            <div class="form-group mt-3">
              <div class="input-group">
                <input type="text" #scanInput class="form-control border" placeholder="{{ inpuScanPlaceholder }}"
                  [(ngModel)]="codeValue" (keyup.enter)="scanCode()" />
                <div class="input-group-append">
                  <button class="btn btn-sm btn-primary" type="button" (click)="scanCode()">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>