<div style="margin-top:-30px;" *ngIf="displayProgressBarCircleTitle">
  <i class="text-right ml-2 fa"
    [ngClass]="{'fa-check-square text-primary': progressionType === 'R', 'fa-thumbs-up text-primary': progressionType === 'V' }"></i>
</div>
<div class="progress" attr.data-percentage="{{progressionValue}}" style="margin-right:5px" data-bs-toggle="tooltip"
  data-placement="right" [title]="progressionType == 'R'? 'Réalisation': 'Validation'"
  [ngStyle]="{'margin-top': displayProgressBarCircleTitle ? '12px' : ''}">
  <span class="progress-left" [ngClass]="disableProgressBar ? 'progress-disabled': ''">
    <span class="progress-bar" [ngStyle]="{borderColor: getBorderColor()}"></span>
  </span>
  <span class="progress-right" [ngClass]="disableProgressBar ? 'progress-disabled': ''">
    <span class="progress-bar" [ngStyle]="{borderColor: getBorderColor()}"></span>
  </span>
  <div class="progress-value">
    <div>
      {{progressionValue || 0}}%<br>
    </div>
  </div>
</div>