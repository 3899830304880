<div style="position: absolute; left: -9999px;">
  <ejs-barcodegenerator style="display: block;" #barcode id="barcode" width="200px" height="80px" mode="SVG"
    type="Code128" value=""></ejs-barcodegenerator>
</div>

<div class="modal fade" id="reviewBeforeConfirmModal" tabindex="-1" aria-labelledby="reviewBeforeConfirmModalLabel"
  aria-hidden="true" data-bs-backdrop="static">
  <div class="modal-dialog modal-lg" style="width: 65%">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-customized-title" id="reviewBeforeConfirmModalLabel">
          <i class="fa fa-check me-2"></i><span class="fw-bold">Revue avant confirmation finale</span>
        </h6>
        <button type="button" class="btn btn-danger btn-sm my-1 d-none" data-bs-dismiss="modal" aria-label="Close">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="confirmValidationStatusStart">
          <table class="border border-secondary bg-light px-3 py-3 d-block" style="width: 100%;">
            <tr style="width:100%">
              <td style="width:80%">
                <h3><b>Tâches non terminées</b></h3>
                <div class="alert alert-success alert-dismissible fade show d-block" role="alert"
                  *ngIf="confirmationDetailsExpensesData.length == 0">
                  <h4 class="mb-0">
                    <strong><span class="fa fa-check-circle mr-2"></span></strong>
                    Toutes les tâches sont terminées
                  </h4>
                </div>
              </td>
              <td style="width:300px">
              </td>
              <td></td>
            </tr>
            <tr style="width:100%" *ngIf="confirmationDetailsExpensesData.length>0">
              <td style="width:90%">
                <p class="detailExpense" *ngFor="let expense of confirmationDetailsExpensesData; index as i">
                  <b>{{i+1}}.</b> {{expense?.description}}
                </p>
              </td>
              <td style="width:300px">
              </td>
              <td style="width:10%">
                <input type="checkbox" aria-label="Checkbox for following text input" [(ngModel)]="isExpenseChecked"
                  (change)="checkExpensesToValidate(isExpenseChecked? true:false)">
              </td>
            </tr>
          </table>
          <table class="border-none mt-4 bg-light px-3 py-3 d-block">
            <tr style="width:100%">
              <td style="width:80%">
                <h3><b>Articles non utilisés</b></h3>
                <div class="alert alert-success alert-dismissible fade show" role="alert"
                  *ngIf="confirmationDetailsItemsData.length == 0">
                  <h4 class="mb-0">
                    <strong><span class="fa fa-check-circle mr-2"></span></strong>
                    Tous les articles sont terminés
                  </h4>
                </div>
              </td>
              <td style="width:300px">
              </td>
              <td></td>
            </tr>
            <tr *ngIf="confirmationDetailsItemsData.length>0">
              <td style="width:90%">
                <p class="detailItem" *ngFor="let item of confirmationDetailsItemsData; index as j">
                  <b>{{j+1}}.</b> {{item?.no}} - {{item?.description}}
                </p>
              </td>
              <td style="width:300px">
              </td>
              <td style="width:10%">
                <input type="checkbox" aria-label="Checkbox for following text input" [(ngModel)]="isItemChecked"
                  (change)="checkItemsToValidate(isItemChecked? true:false)">
              </td>
            </tr>
          </table>

          <div class="alert alert-warning alert-dismissible fade show mt-3" role="alert" *ngIf="generateReturnOT">
            <strong><span class="fa fa-info mr-2"></span></strong> Un ordre de transfert retour
            contenant les articles non utilisés sera automatiquement crée suite à la validation et un nouvel onglet vous
            permettra d'imprimer l'étiquette.
          </div>
          <div class="alert alert-info alert-dismissible fade show mt-3" role="alert" *ngIf="!generateReturnOT">
            <strong><span class="fa fa-check-circle mr-2"></span></strong> Aucun article ne devra être retourné : aucun
            ordre de transfert retour ne sera crée suite à la validation
          </div>
        </div>
        <div *ngIf="!confirmValidationStatusStart">
          <div *ngIf="isTransferOrderReturnCreated">
            <div class="alert alert-success alert-dismissible fade show" role="alert">
              <h4 class="mb-0">
                <strong><span class="fa fa-check-circle mr-2"></span></strong>
                Ordre de transfert retour a été créé avec succès correctement, veuillez imprimer le code-barres associé
                avant de terminer
              </h4>
            </div>
            <div class="d-flex justify-content-center">
              <img [src]="codeBarImgBase64" width="300px" class="img-responsive" />
            </div>
          </div>
          <div class="alert alert-dismissible fade show" role="alert" *ngIf="!isTransferOrderReturnCreated">
            <h4 class="mb-0"><strong><span class="fa fa-times-circle mr-2"></span></strong> Aucun ordre de transfert
              retour n'est crée, veuillez terminer</h4>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="w-100" *ngIf="confirmValidationStatusStart">
          <button type="button" class="btn btn-primary mr-2 float-left" data-bs-dismiss="modal" aria-label="Close"
            (click)="cancelReviewBeforeConfirmModal()">
            <i class="fa fa-arrow-left mr-2"></i>Retour
          </button>
          <button type="button" class="btn btn-success float-right" *ngIf="generateReturnOT"
            [disabled]="isValidateBtnDisabled" (click)="applyNewStatus()">
            <i class="fa fa-check mr-2"></i>Valider
          </button>
          <button type="button" class="btn btn-success float-right" *ngIf="!generateReturnOT"
            [disabled]="isValidateBtnDisabled" (click)="applyFinishedStatus()">
            <i class="fa fa-check mr-2"></i>Valider et terminer
          </button>
        </div>
        <div class="w-100" *ngIf="!confirmValidationStatusStart">
          <button type="button" class="btn btn-primary mr-2 float-left" *ngIf="isTransferOrderReturnCreated"
            (click)="printReturnOrderLabel()">
            <i class="fa fa-print mr-2"></i>Imprimer
          </button>
          <button type="button" class="btn btn-success float-right" [disabled]="!isPrintReturnOTNoClicked"
            (click)="applyFinishedStatus()">
            <i class="fa fa-check mr-2 ml-2"></i>Terminer
          </button>
        </div>
      </div>
    </div>
  </div>
</div>