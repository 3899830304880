import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company } from 'src/app/core/shared/models/company.model';
import { Profile } from 'src/app/core/shared/models/profile';
import { AccessService } from 'src/app/guard/access.service';
import { Features } from 'src/app/models/accessGrantObject.model';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  @Output() onpenEquipementAssocieEvent = new EventEmitter<any>();
  @Input() currentProfile: Profile = null;
  @Input() currentUserCompany: Company | undefined = null;
  @Input() pageName: string = '';
  @Input() equipementNo: string = '';
  sidebarFeatures: Features = null;

  constructor(private accessService: AccessService) {}

  ngOnInit() {
    this.sidebarFeatures = this.accessService.getFeatureByProfile(
      this.currentProfile.profil
    );
  }
  onEquipementAssocieClick() {
    this.onpenEquipementAssocieEvent.emit(true);
  }
}
