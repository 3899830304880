import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/config/app.config.service';
import { Observable, catchError, map } from 'rxjs';
import { StorageService } from 'src/app/core/shared/services/storage.service';
import { ErrorService } from 'src/app/core/shared/services/error.service';
import { GenericApi } from 'src/app/core/shared/models/generic-api.model';
import { Equipement } from 'src/app/core/shared/models/equipement';
import { Template } from '../core/shared/models/Template';

@Injectable({
  providedIn: 'root'
})
export class CommunService {

  defaultCompanyId = AppConfigService.settings.defaultCompanyId;
  bcCoreApiUrl = AppConfigService.settings.bcCoreApiUrl;
  customApiUrlSegment = AppConfigService.settings.customApiUrlSegment;
  bcEnvirName = AppConfigService.settings.bcEnvirName;
  defaultLg = AppConfigService.settings.defaultLanguage;

  userAccess = {
    create: true, read: true, update: true, false: true 
  }

  roleEnglishToFrench = {
    Mechanic: 'Mécanicien',
    Evaluator: 'Evaluateur',
    Supervisor: 'Superviseur',
    Administrator: 'Administrateur'
  }
  
    constructor(private http: HttpClient,
      private storageService: StorageService,
      private errorService: ErrorService  ) { }
  

    generateRandomCode() {
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
      const lengthOfCode = 6;
      let text = "";
      for (let i = 0; i < lengthOfCode; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
        return text;
    }
  
   openPdfFileInNewTab(fileBase64, ){
      var byteCharacters = atob(fileBase64);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: 'application/pdf;base64' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL); 
  }

}
