<div class="container-scroller">

  <!-- Nav bar component -->
  <app-nav-bar *ngIf="!duplicateMode" [BIXI_userAuthObject]="BIXI_userAuthObject" [currentProfile]="currentProfile"
    [currentUserCompany]="currentUserCompany" (changeProfileEvent)="changeProfile($event)" [equipementNo]="equipementNo"
    [pageName]="pageName" (logoutEvent)="logout()"></app-nav-bar>

  <div [class]="duplicateMode ? 'container-fluid page-body-wrapper' : 'container-fluid page-body-wrapper add-padding' ">
    <!-- Side Bar component -->
    <app-side-bar *ngIf="!duplicateMode" [currentProfile]="currentProfile" [currentUserCompany]="currentUserCompany"
      [pageName]="pageName" [equipementNo]="equipementNo"></app-side-bar>

    <div class="main-panel" [style]="duplicateMode ? 'width: 100% !important;' : 'margin-left:70px' ">
      <div class="content-wrapper p-3">
        <div class="row pl-0 pr-0" *ngIf="isTemplateHeaderCreated">
          <div class="template-demo pl-0 pr-0">
            <h3 [hidden]="!duplicateMode">Duplication de template</h3>
            <h3 class="nav-link float-left pl-0"> Template: {{ template.description }} </h3>


            <a class="nav-link float-right pl-0" (click)="disableModeEditor()"><span class="btn btn-primary btn-sm "><i
                  class="{{makerModeBtnIcon}} mr-2"></i><small>
                  {{makerModeBtnLabel}}</small></span></a>

            <a *ngIf="isModeEditor && template.columnLines.length < 3" class="nav-link float-right pl-0"
              (click)="addNewColumn()"><span class="btn btn-primary btn-sm "><i
                  class="fa fa-plus mr-2"></i><small>Ajouter
                  une colonne</small></span></a>

          </div>
        </div>
        <app-loading *ngIf="!isTemplateHeaderCreated"></app-loading>
        <div class="row" style="height:200px" *ngIf="isTemplateHeaderCreated && template.templateColumns >= 1">
          <div class="col-md-4 pt-4" [ngStyle]="isModeEditor?{'border': '1px solid lightgray'} : {'border': ''}"
            *ngFor="let columnLine of template.columnLines; let i = index" [id]="'colmun_index_'+i">
            <button type="button" class="btn btn-danger btn-sm rounded-0 py-1 px-2" *ngIf="isModeEditor"
              (click)="removeColumn(i)"><small><i class="fa fa-trash" style="font-size: 12px;"></i></small></button>
            <h5 style="display: inline;
                    margin-left: 10px;">{{columnLine.displayName}}
            </h5>
            <div style="position:absolute; top:0; right:0;">
              <!-- <button type="button" class="btn btn-danger btn-sm rounded-0 py-1 px-2" *ngIf="isModeEditor"><small><i
                      class="fa fa-trash" style="font-size: 12px;"></i></small></button> -->
              <button type="button" class="btn btn-primary btn-sm rounded-0 py-1"
                (click)="displayTemplateModel('save','templateSection', i, 0, 0,'', 0, '', 0, '', 0, '')"
                *ngIf="isModeEditor"><small><i class="fa fa-plus mr-2" style="font-size: 12px;"></i>Ajouter
                  une section</small></button>
            </div>

            <div *ngFor="let sectionLine of columnLine.sectionLines; let j = index" [id]="'section_index_'+j">
              <div class="card mt-2 rounded-0 borderTemplateTable">
                <div class="card-header pb-0 pt-2 bg-light rounded-0">
                  <h5 [ngClass]="{'text-info' : isModeEditor == true, '': isModeEditor == false}"
                    [ngStyle]="isModeEditor?{'cursor': 'pointer'} : {'cursor': 'auto'}" (click)="displayTemplateModel('edit','templateSection', i, j, 0, sectionLine.code, sectionLine.hierarchyIndex,
                      '', 0, '', 0, '')">{{sectionLine.displayName}}
                  </h5>
                  <div style="position:absolute; top:0; right:0;">
                    <button type="button" class="btn btn-danger btn-sm rounded-0 py-1 px-2" *ngIf="isModeEditor"
                      (click)="removeSection(i, j)"><small><i class="fa fa-trash"
                          style="font-size: 12px;"></i></small></button>
                    <button type="button" class="btn btn-primary btn-sm rounded-0 py-1"
                      (click)="displayTemplateModel('save', 'templateSubsection', i, j, 0, sectionLine.displayName, j, '', 0, '', 0, '')"
                      *ngIf="isModeEditor"><small><i class=" fa fa-plus mr-2" style="font-size: 12px;"></i>Ajouter
                        une sous-section</small></button>
                  </div>

                </div>
                <div class="card-body pt-0 pb-0 pl-2 pr-2">
                  <div class="table-responsive">
                    <table class="table templateTable">
                      <thead>
                        <tr class="text-center">
                          <th style="width:40%"></th>
                          <th *ngIf="!sectionLine.leftLabel && !sectionLine.rightLabel">
                          </th>
                          <th *ngIf="sectionLine.leftLabel">
                            {{sectionLine.leftLabel}}</th>
                          <th *ngIf="sectionLine.rightLabel">
                            {{sectionLine.rightLabel}}</th>
                        </tr>
                      </thead>
                      <tbody *ngFor="let subSectionLine of sectionLine.subSectionLines; let k = index">
                        <tr style="padding: 0px; border-bottom-color:white" *ngIf="isModeEditor">
                          <td style="padding:5px">
                          </td>
                          <td colspan="2" *ngIf="subSectionLine.position == 'Center'" class="p-0" style="padding:5px">
                            <button type="button" class="btn btn-primary btn-sm rounded-0 py-1  d-block w-100"
                              (click)="displayTemplateModel('save','templateTask', i, j, k, sectionLine.displayName, i, subSectionLine.displayName, j, 'Center', 0, '')"><small><i
                                  class=" fa fa-plus mr-2" style="font-size: 12px;"></i>Ajouter
                                une tâche</small></button>
                          </td>
                          <td *ngIf="subSectionLine.position == 'L/R'" style="padding:5px">
                            <button type="button" class="btn btn-primary btn-sm rounded-0 py-1  d-block w-100"
                              (click)="displayTemplateModel('save', 'templateTask', i, j, k, sectionLine.displayName, i, subSectionLine.displayName, j, 'Left', 0, '')"><small><i
                                  class=" fa fa-plus mr-2" style="font-size: 12px;"></i>Ajouter
                                une tâche</small></button>
                          </td>
                          <td *ngIf="subSectionLine.position == 'L/R'" style="padding:5px">
                            <button type="button" class="btn btn-primary btn-sm rounded-0 py-1 d-block w-100"
                              (click)="displayTemplateModel('save', 'templateTask', i, j, k, sectionLine.displayName, i, subSectionLine.displayName, j, 'Right', 0, '')"><small><i
                                  class=" fa fa-plus mr-2" style="font-size: 12px;"></i>Ajouter
                                une tâche</small></button>
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold py-1">
                            <span [ngClass]="{'text-info' : isModeEditor == true, '': isModeEditor == false}"
                              [ngStyle]="isModeEditor?{'cursor': 'pointer'} : {'cursor': 'auto'}"
                              (click)="displayTemplateModel('edit','templateSubsection', i, j, k, sectionLine.displayName, i, subSectionLine.displayName, j, '', 0, '')">
                              {{subSectionLine.displayName}}
                            </span>
                            <button type="button" class="btn btn-danger btn-sm rounded-0 py-1 px-2 ml-2"
                              (click)="removeSubsection(i, j, k)" *ngIf="isModeEditor"><small><i class="fa fa-trash"
                                  style="font-size: 12px;"></i></small></button>
                          </td>
                          <td colspan="2" *ngIf="subSectionLine.position == 'Center'" class="px-1 py-1">
                            <div *ngFor="let taskLine of subSectionLine.taskLines; let l1 = index"
                              class="btn-group d-flex">
                              <div class="btn-group d-flex mb-1" style="width:100% !important"
                                *ngIf="taskLine.position == 'Center'">
                                <button type="button" class="btn btn-danger btn-sm rounded-0 py-1 px-2"
                                  (click)="removeTask(i, j, k, l1, taskLine.position)" *ngIf="isModeEditor"><small><i
                                      class="fa fa-trash" style="font-size: 12px;"></i></small></button>
                                <button type="button" style="width:100% !important"
                                  (click)="displayTemplateModel('edit', 'templateTask', i, j, k, sectionLine.displayName, i, sectionLine.displayName, j, 'Center', l1, taskLine.code)"
                                  class="btn btn-sm btn-light p-1 btn-block borderTemplateBtnTemplate"
                                  [ngClass]="{'text-info' : isModeEditor == true, '': isModeEditor == false}">
                                  {{ getTaskLineIcon(taskLine.displayName.trim(), i, j, k, l1) }}
                                  <img src="" id="taskLine_icon_{{i}}_{{j}}_{{k}}_{{l1}}"
                                    class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                  {{taskLine.displayName.trim()}}<i class=" fontSizeTemplateBtn fa fa-star float-left"
                                    *ngIf="taskLine.defaultTask == 'Yes'"></i>
                                </button>
                                <button style="border-left:1px solid gray"
                                  class="btn btn-sm btn-light p-1 borderTemplateBtnTemplate"
                                  [ngClass]="{'text-info' : isModeEditor == true, '': isModeEditor == false}">
                                  <i class=" fa fa-list-ul"></i>
                                </button>
                              </div>
                            </div>
                          </td>

                          <td
                            *ngIf="subSectionLine.position == 'L/R' && sectionLine.leftLabel || subSectionLine.position == 'L/R' && sectionLine.leftLabel == ''"
                            class="px-1 py-1">
                            <div *ngFor="let taskLine of subSectionLine.taskLines; let l2 = index">
                              <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Left'">
                                <button type="button" class="btn btn-danger btn-sm rounded-0 py-1 px-2"
                                  *ngIf="isModeEditor" (click)="removeTask(i, j, k, l2, task.position)"><small><i
                                      class="fa fa-trash" style="font-size: 12px;"></i></small></button>
                                <button
                                  (click)="displayTemplateModel('edit', 'templateTask', i, j, k, sectionLine.displayName, i, subSectionLine.displayName, j, 'Left', l2, taskLine.code)"
                                  class="btn btn-sm btn-light p-1 btn-block borderTemplateBtnTemplate"
                                  [ngClass]="{'text-info' : isModeEditor == true, '': isModeEditor == false}">
                                  {{ getTaskLineIcon(taskLine.displayName.trim(), i, j, k, l2) }}
                                  <img src="" id="taskLine_icon_{{i}}_{{j}}_{{k}}_{{l2}}"
                                    class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                  {{taskLine.displayName.trim()}}<i class=" fontSizeTemplateBtn fa fa-star float-left"
                                    *ngIf="taskLine.defaultTask == 'Yes'"></i>
                                </button>
                                <button class="btn btn-sm btn-light p-1 borderTemplateBtnTemplate">
                                  <i class=" fa fa-list-ul"></i>
                                </button>
                              </div>
                            </div>
                          </td>
                          <td
                            *ngIf="subSectionLine.position == 'L/R' && sectionLine.rightLabel || subSectionLine.position == 'L/R' && sectionLine.rightLabel == ''"
                            class="px-1 py-1">
                            <div *ngFor="let taskLine of subSectionLine.taskLines; let l3 = index">
                              <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Right'">
                                <button type="button" class="btn btn-danger btn-sm rounded-0 py-1 px-2"
                                  *ngIf="isModeEditor" (click)="removeTask(i, j, k, l3, task.position)"><small><i
                                      class="fa fa-trash" style="font-size: 12px;"></i></small></button>
                                <button
                                  (click)="displayTemplateModel('edit', 'templateTask', i, j, k, sectionLine.displayName, i, subSectionLine.displayName, j, 'Right', l3, taskLine.code)"
                                  class="btn btn-sm btn-light p-1 btn-block borderTemplateBtnTemplate"
                                  [ngClass]="{'text-info' : isModeEditor == true, '': isModeEditor == false}">
                                  {{ getTaskLineIcon(taskLine.displayName.trim(), i, j, k, l3) }}
                                  <img src="" id="taskLine_icon_{{i}}_{{j}}_{{k}}_{{l3}}"
                                    class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                  {{taskLine.displayName.trim()}}<i class=" fa fa-star float-left"
                                    *ngIf="task.default == 'Yes'"></i>
                                </button>
                                <button style="border:1px solid lightgray; font-size: 12px;"
                                  class="btn btn-sm btn-light p-1 borderTemplateBtnTemplate">
                                  <i class=" fa fa-list-ul"></i>
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="(isTemplateHeaderCreated && !editExistantTemplate) || duplicateMode" class="fab-container">
      <div class="fab shadow">
        <div (click)="onSaveTemplateClick(action)" class="fab-content">
          Sauvegarder
        </div>
      </div>
    </div>

  </div>
</div>

<div class="modal fade" id="templateModal" tabindex="-1" aria-labelledby="templateLabel" aria-hidden="true"
  data-bs-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-h6-title-container" id="templateLabel">
          <i class="mr-2 fa "
            [class]="saveMode == 'save' ? 'fa-plus-square modal-icon-title' : saveMode =='edit' ? 'fa-edit modal-icon-title' : ''"></i><b
            class="modal-title-style">{{formTemplateModalTitle}}</b>
        </h6>
      </div>
      <div class="modal-body pt-2 pb-2">
        <div class="row mt-2">
          <div class="grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="forms-sample" *ngIf="headerTemplateMode">
                  <!-- <div class="form-group row mb-0 py-1">
                      <label class="col-sm-5 col-form-label py-1">Code</label>
                      <div class="col-sm-7">
                        <input type="text" class="form-control form-control-sm py-1" [(ngModel)]="template.code"
                          [disabled]="true">
                      </div>
                    </div> -->`
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Code</label>
                    <div class="col-sm-7">
                      <input type="text" name="code" class="form-control form-control-sm py-1"
                        [(ngModel)]="template.code" #templateHeaderCodeElement2 #code="ngModel" required="">
                      <div *ngIf="code.invalid && (code.dirty || code.touched)"
                        class="alert alert-danger input-error-style">
                        <div *ngIf="code.errors?.['required']"> Le code est requis </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Description</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control form-control-sm py-1" id="headerDescription"
                        name="description" [(ngModel)]="template.description" required #templateHeaderDescriptionElement
                        #name="ngModel">
                      <div *ngIf="name.invalid && (name.dirty || name.touched)"
                        class="alert alert-danger input-error-style">
                        <div *ngIf="name.errors?.['required']"> La description est requise </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Catégorie d'équipement</label>
                    <div class="col-sm-7">
                      <select (change)="onAssetCategoryChange($event)" class="form-select form-select-sm py-1"
                        name="codeAssetCategory" [(ngModel)]="template.codeAssetCategory"
                        #templateHeaderAssetCategoryCodeElement>
                        <option *ngFor="let assetCategory of assetCategories;" [value]="assetCategory.code">{{
                          assetCategory.description }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Code d'équipement</label>
                    <div class="col-sm-7">
                      <input type="text" name="codeAssetCategory" class="form-control form-control-sm py-1"
                        (input)="sendTheNewValue($event)" [(ngModel)]="template.codeAssetCategory"
                        #templateHeaderAssetCategoryDescriptionElement [disabled]="true">
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Nombre de colonnes</label>
                    <div class="col-sm-7">
                      <select name="templateColumns" class="form-select form-select-sm py-1"
                        [(ngModel)]="template.templateColumns" [disabled]="duplicateMode || editExistantTemplate"
                        #templateColumnsNbElement>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Statut</label>
                    <div class="col-sm-7">
                      <select name="status" class="form-select form-select-sm py-1" value="Open"
                        [(ngModel)]="template.status" [disabled]="true">
                        <option value="Open" selected>Open</option>
                      </select>
                    </div>
                  </div>
                  <div class="float-right mt-3">
                    <button type="button" class="btn btn-primary btn-sm mr-2" data-bs-dismiss="modal"
                      (click)="createTemplateHeader()"
                      [disabled]="code.errors?.['required'] || name.errors?.['required']"><i
                        class="fa fa-save mr-2"></i>Créer</button>
                    <button class="btn btn-light btn-sm" data-bs-dismiss="modal" (click)="cancelTemplateCreate()"><i
                        class="fa fa-times mr-2"></i>Annuler</button>
                  </div>
                </div>
                <div class="forms-sample" *ngIf="sectionTemplateMode">
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Position</label>
                    <div class="col-sm-7">
                      <input min=1 type="number" name="position" #name="ngModel" required
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                        [(ngModel)]="sectionPositionValidation"
                        [max]="clickEditMode ? currentEditableDataIndexPosition : this.template.columnLines[currentColumnSelectedIndex].sectionLines.length + 1"
                        [value]="clickEditMode ? currentEditableDataIndexPosition : this.template.columnLines[currentColumnSelectedIndex].sectionLines.length + 1"
                        class="form-control form-control-sm py-1"
                        [disabled]="this.template.columnLines[currentColumnSelectedIndex].sectionLines.length > 0 ? false : true"
                        #templateSectionLinePosition>
                      <div class="alert alert-danger input-error-style" *ngIf="name.errors?.['required']"> La position
                        est requise </div>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Description</label>
                    <div class="col-sm-7">
                      <input type="text" name="description" #description="ngModel" [(ngModel)]="sectionDescriptionEdit"
                        required class="form-control form-control-sm py-1" #templateSectionLineDescription>
                      <div *ngIf="description.invalid && (description.dirty || description.touched)"
                        class="alert alert-danger input-error-style">
                        <div *ngIf="description.errors?.['required']"> La description est requise </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Étiquette Gauche</label>
                    <div class="col-sm-7">
                      <input type="text" [value]="sectionLeftLabelEdit" class="form-control form-control-sm py-1"
                        #leftLabelElement>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Étiquette Droite</label>
                    <div class="col-sm-7">
                      <input type="text" [value]="sectionRightLabelEdit" class="form-control form-control-sm py-1"
                        #rightLabelElement>
                    </div>
                  </div>
                  <div class="float-right mt-3">
                    <button type="button" class="btn btn-primary btn-sm mr-2" data-bs-dismiss="modal"
                      (click)="createTemplateSection()"
                      [disabled]="name.errors?.['required'] || description.errors?.['required']"><i
                        class="fa fa-save mr-2"></i>{{saveBtnModal}}</button>
                    <button class="btn btn-light btn-sm" data-bs-dismiss="modal"><i
                        class="fa fa-times mr-2"></i>Annuler</button>
                  </div>

                </div>
                <div class="forms-sample" *ngIf="subsectionTemplateMode">
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Position</label>
                    <div class="col-sm-7">
                      <input type="number" min=1 name="positionSubsction" #positionSubsction="ngModel"
                        [(ngModel)]="subSectionPositionValidation" required
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                        [max]="clickEditMode ? currentEditableDataIndexPosition : this.template.columnLines[currentColumnSelectedIndex].sectionLines[currentSectionSelectedIndex].subSectionLines.length + 1"
                        [value]="clickEditMode ? currentEditableDataIndexPosition : this.template.columnLines[currentColumnSelectedIndex].sectionLines[currentSectionSelectedIndex].subSectionLines.length + 1"
                        class="form-control form-control-sm py-1"
                        [disabled]="this.template.columnLines[currentColumnSelectedIndex].sectionLines[currentSectionSelectedIndex].subSectionLines.length + 1 > 0 ? false : true"
                        #templateSubSectionLinePosition>
                      <div class="alert alert-danger input-error-style" *ngIf="positionSubsction.errors?.['required']">
                        La position est requise </div>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Section</label>
                    <div class="col-sm-7">
                      <input
                        [value]="template.columnLines[currentColumnSelectedIndex].sectionLines[currentSectionSelectedIndex].displayName"
                        type="text" class="form-control form-control-sm py-1 bg-light" [readOnly]=" true"
                        #sectionDisplayNameElement>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Description</label>
                    <div class="col-sm-7">
                      <input type="text" name="subSectionDescription" #subSectionDescription="ngModel"
                        [(ngModel)]="subSectionDescriptionEdit" required class="form-control form-control-sm py-1"
                        #templateSubsctionLineDescription>
                      <div
                        *ngIf="subSectionDescription.invalid && (subSectionDescription.dirty || subSectionDescription.touched)"
                        class="alert alert-danger input-error-style">
                        <div *ngIf="subSectionDescription.errors?.['required']"> La description est requise </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Alignement</label>
                    <div class="col-sm-7">
                      <select class="form-select form-select-sm py-1" #templateSubSectionLineAlignement>
                        <option value="Center">Centre</option>
                        <option value="L/R">Gauche - Droite</option>
                      </select>
                    </div>
                  </div>
                  <div class="float-right mt-3">
                    <button type="button" class="btn btn-primary btn-sm mr-2" data-bs-dismiss="modal"
                      (click)="createTemplateSubsection()"
                      [disabled]="positionSubsction.errors?.['required'] || subSectionDescription.errors?.['required']"><i
                        class="fa fa-save mr-2"></i>{{saveBtnModal}}</button>
                    <button class="btn btn-light btn-sm" data-bs-dismiss="modal"><i
                        class="fa fa-times mr-2"></i>Annuler</button>
                  </div>

                </div>
                <div class="forms-sample" *ngIf="taskTemplateMode">
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Position</label>
                    <div class="col-sm-7">
                      <input type="number" min=1
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                        [max]="clickEditMode ? currentEditableDataIndexPosition : taskLinePosition + 1"
                        [value]="clickEditMode ? currentEditableDataIndexPosition : taskLinePosition + 1"
                        class="form-control form-control-sm py-1"
                        [disabled]="this.template.columnLines[currentColumnSelectedIndex].sectionLines[currentSectionSelectedIndex].subSectionLines[currentSubSectionSelectedIndex].taskLines.length > 0 ? false : true"
                        #templateTaskLinePosition>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Alignement</label>
                    <div class="col-sm-7">
                      <select class="form-select form-select-sm py-1" #templateSubSectionLineAlignement_
                        [disabled]="true">
                        <option
                          *ngIf="template.columnLines[this.currentColumnSelectedIndex].sectionLines[currentSectionSelectedIndex].subSectionLines[currentSubSectionSelectedIndex].position == 'Center'"
                          value="Center">Centre</option>
                        <option
                          *ngIf="template.columnLines[this.currentColumnSelectedIndex].sectionLines[currentSectionSelectedIndex].subSectionLines[currentSubSectionSelectedIndex].position == 'L/R' && selectTaskPosition == 'Left' "
                          value="Left">
                          {{template.columnLines[this.currentColumnSelectedIndex].sectionLines[currentSectionSelectedIndex].leftLabel}}
                        </option>
                        <option
                          *ngIf="template.columnLines[this.currentColumnSelectedIndex].sectionLines[currentSectionSelectedIndex].subSectionLines[currentSubSectionSelectedIndex].position == 'L/R' && selectTaskPosition == 'Right'"
                          value="Right">
                          {{template.columnLines[this.currentColumnSelectedIndex].sectionLines[currentSectionSelectedIndex].rightLabel}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Section</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control form-control-sm py-1 bg-light"
                        [value]="template.columnLines[currentColumnSelectedIndex].sectionLines[currentSectionSelectedIndex].displayName"
                        [readOnly]=" true" #sectionLineElement_>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Sous-section</label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control form-control-sm py-1 bg-light"
                        [value]="template.columnLines[currentColumnSelectedIndex].sectionLines[currentSectionSelectedIndex].subSectionLines[currentSubSectionSelectedIndex].displayName"
                        [readOnly]="true" #subsctionTaskLineElement>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Description</label>
                    <div class="col-sm-7">
                      <select class="form-select form-select-sm py-1" name="task_description"
                        [(ngModel)]="taskLineDescriptionEdit" #task_description="ngModel" #taskDescription required>
                        <option [value]="''" selected></option>
                        <option *ngFor="let defDisplayName of defDisplayNames; let k = index"
                          [value]="defDisplayName.code"> {{defDisplayName.code}} </option>
                      </select>
                      <div *ngIf="task_description.invalid && (task_description.dirty || task_description.touched)"
                        class="alert alert-danger input-error-style">
                        <div *ngIf="task_description.errors?.['required']"> La description est requise </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Task category</label>
                    <div class="col-sm-7">
                      <select class="form-select form-select-sm py-1" name="task_category"
                        [(ngModel)]="taskLineCodeTaskCategoryEdit" #task_category="ngModel" #taskCategoryElement
                        required>
                        <option [value]="''" selected></option>
                        <option *ngFor="let taskCategory of taskCategories; let k = index" [value]="taskCategory.code">
                          {{taskCategory.description}} </option>
                      </select>
                      <div *ngIf="task_category.invalid && (task_category.dirty || task_category.touched)"
                        class="alert alert-danger input-error-style">
                        <div *ngIf="task_category.errors?.['required']"> Le task category est requis </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Type de champ</label>
                    <div class="col-sm-7">
                      <select class="form-select form-select-sm py-1" (change)="onFieldTypeChange($event)"
                        [value]="taskLineFieldType" #fieldTypeElement>
                        <option value="Selection" selected>Selection</option>
                        <option value="X (Expenses)">X (Expenses)</option>
                        <option value="X (Items)">X (Items)</option>
                        <option value="X (Items/Expenses)">X (Items/Expenses)</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Champ min</label>
                    <div class="col-sm-7">
                      <input type="number" min=1
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                        [value]="taskLineFieldMINValue" class="form-control form-control-sm py-1" #fieldMinElement
                        [disabled]="taskLineFieldType == 'Selection'">
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Champ max</label>
                    <div class="col-sm-7">
                      <input type="number" min=1
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                        [value]="taskLineFieldMAXValue" class="form-control form-control-sm py-1" #fieldMaxElement
                        [disabled]="taskLineFieldType == 'Selection'">
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Tâche par défault</label>
                    <div class="col-sm-7">
                      <select value="false" class="form-select form-select-sm py-1" [value]="taskLineDefaultTask"
                        #defaultTaskElement>
                        <option value="true">Oui</option>
                        <option value="false" selected>Non</option>
                      </select>
                    </div>
                  </div>
                  <div class="float-right mt-3">
                    <button type="button" class="btn btn-primary btn-sm mr-2"
                      [disabled]="task_description.errors?.['required'] || task_category.errors?.['required']"
                      data-bs-dismiss="modal" (click)="createTemplateTask()"><i
                        class="fa fa-save mr-2"></i>{{saveBtnModal}}</button>
                    <button class="btn btn-light btn-sm" data-bs-dismiss="modal"><i
                        class="fa fa-times mr-2"></i>Annuler</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>