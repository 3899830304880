import { Component, OnInit, ViewChild } from '@angular/core';
import * as Notiflix from 'notiflix';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';

import {
  ColumnChooser,
  GridComponent,
  ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/core/shared/services/storage.service';
import { Profile } from 'src/app/core/shared/models/profile';
import { Company } from 'src/app/core/shared/models/company.model';
import { HomeService } from '../home/services/home.service';
import { Equipement } from 'src/app/core/shared/models/equipement';
import { OpenWorkorderService } from './services/open-workorder.service';
import { WorkOrder } from 'src/app/core/shared/models/WorkOrder';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { DetailWorkorderService } from '../detail-workorder/services/detail-workorder.service';
import { TemplateMakerService } from '../template-maker/services/template-maker.service';
import { CommunService } from 'src/app/services/commun.service';
import { Subject } from 'rxjs';
import { Features } from 'src/app/models/accessGrantObject.model';
import { AccessService } from 'src/app/guard/access.service';
import { L10n, setCulture, loadCldr } from '@syncfusion/ej2-base';
import { StatusProfileBlockAndReturnScan } from 'src/app/core/shared/models/statusProfileBlockAndReturnScan.model';
declare var bootstrap: any;

setCulture('fr-CA');
 
L10n.load({ 
   'fr-CA': { 
       grid: { 
          EmptyRecord:"Aucun enregistrement à afficher ", 
       } 
   }
});

@Component({
  selector: 'app-open-workorder',
  templateUrl: './open-workorder.component.html',
  styleUrls: ['./open-workorder.component.scss'],
})
export class OpenWorkorderComponent implements OnInit {
  pageName = 'openWorkorder';
  public listEquipData: Object[];
  public currentWoData: object[];
  public consumptionTrackData: object[];
  taskMultipleValue = 1
  colorDefaultTaskIcon_Fr_Fr_A_G = 'text-info';
  taskCategoryByDocs: any[] = []

  public pageSettings: Object;
  public toolbarOptions: ToolbarItems[] | object;
  public selectOptions: Object;
  mulitipeValueTaskLineModal = null
  public editSettings: Object;
  public columnChooserOption: ColumnChooser;
  equipementNo: string = '';
  disableEditBTButton = false;
  woOpenedState = true
  roueWorkorder = null
  equipementAssocieModal = null
  templateRoue = null
  taskLineDefinitions: any[] = []
  EObjectToUpdate = null
  defDisplayNames: any[] = []

  public typeScan = localStorage.getItem('bixiApp_scanType');
  defaultShow = '';
  createdByTemplateId = '';
  workorderTemplate = null;
  defaultAriaExpanded = 'false';
  currentUserName = '';
  currentUserCompany: Company | undefined;
  selectedWorEquipment = '';
  associatedEquipTemplateModal = null
  equipement: Equipement = null;
  userProfil = localStorage.getItem('bixi-currentProfile') || '';
  @ViewChild('workOrdersGrid') workOrdersGrid: GridComponent;
  @ViewChild('cardWorkOrdersGrid') cardWorkOrdersGrid: GridComponent;
  @ViewChild('currentWoGrid') currentWoGrid: GridComponent;
  inProgressWorkOrdersData: WorkOrder[] = [];
  openedPreventifWorkOrdersData: WorkOrder[] = [];
  openWorkOrdersData: WorkOrder[] = [];
  templateSideIndex = 0

  userProfilTitle = '';
  BIXI_userAuthObject: any = null;
  currentProfile: Profile = null;
  currentProfiles: Profile[] = [];
  workOrderNo = '';
  refreshAssetLinkEvent: Subject<void> = new Subject<void>();
  openWorkOrderFeatures: Features = null;
  isUserBlocked: boolean = true;  

  constructor(
    private router: Router,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private homeService: HomeService,
    private openWorkOrderService: OpenWorkorderService,
    private AuthService: AuthService,
    private detailWorkorderService: DetailWorkorderService,
    private templateMakerService: TemplateMakerService,
    private communService: CommunService,
    private accessService: AccessService
  ) {}

  changeProfile(profil) {
    if (profil) {

      const profileFound = this.currentProfiles.find(
        (currentProfile) => currentProfile.profil == profil
      );

      localStorage.setItem('bixi-currentProfile', JSON.stringify(profileFound));
      this.reloadCurrentRoute();

    } else {
      this.AuthService.logout();
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    currentUrl = currentUrl.replace('%20', ' ');
    location.reload()
  }

  logout() {
    Notiflix.Confirm.show(
      'Confirmation',
      'Êtes-vous sûr de vouloir vous déconnecter?',
      'Confirmer',
      'Annuler',
      () => {
        this.router.navigateByUrl('/');
      },
      () => {},
      {
        width: '450px',
        okButtonBackground: '#04B76B',
        cancelButtonBackground: '#FF5E6D',
        titleColor: '#392C70',
        borderRadius: '5px',
        backgroundColor: '#ffffff',
      }
    );
  }

  goToDetailworkorder(workOrderNo) {
    this.workOrderNo = workOrderNo;
    this.router.navigateByUrl(
      `detailworkorder/${this.currentUserCompany.id}/${this.currentProfile.id}/workOrder/${workOrderNo}`
    );
  }

  updateTaskOnWorkOrder(e){
    this.EObjectToUpdate = e
    if(e.data == 'taskCategoryByDoc'){
      if(e.object.taskCategoryByDoc.createdByProfil =='Supervisor' || e.object.taskCategoryByDoc.createdByProfil =='Evaluator'){
        if(this.currentProfile.profil == 'Supervisor' || this.currentProfile.profil == 'Evaluator'){
          this.canRemoveTask(e)
        }else{    
        Notiflix.Loading.remove();
        Notiflix.Notify.failure("Vous n'avez pas le droit de retirer cette tâche car vous êtes un "+ this.communService.roleEnglishToFrench[this.currentProfile.profil], {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
           })
        }
    }else if(e.object.taskCategoryByDoc.createdByProfil =='Mechanic'){
      this.canRemoveTask(e)
    } 
    }else if(e.data == 'taskLine'){
      if(e.object.fieldType == 'Selection'){
        this.AddTaskToWorkOrder(e)
      }else if(e.object.fieldType == 'X (Expenses)' || e.object.fieldType == 'X (Items)' || e.object.fieldType == 'X (Items/Expenses)'){
          var myModal = new bootstrap.Modal(
            document.getElementById('mulitipeValueTaskLineModal'),
            {}
          );
          myModal.toggle();        
      }else{
        Notiflix.Notify.failure("FieldType inconnue. Veuillez reconfigurer le template", {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
         })
      }
    }
  }

  ngOnInit() {
    Notiflix.Notify.init({
      position: 'right-bottom',
    });
    // check if user has data in browser storage, else redirect himn to login page
    if (
      !this.storageService.getItem('bixi-user-auth') ||
      !this.storageService.getItem('bixi-currentCompany') ||
      !this.storageService.getItem('bixi-currentProfile')
    ) {
      this.router.navigateByUrl('/auth/login');
    } else {
      this.BIXI_userAuthObject = JSON.parse(
        this.storageService.getItem('bixi-user-auth')
      );
      this.currentUserCompany = JSON.parse(
        this.storageService.getItem('bixi-currentCompany')
      );
      this.currentProfile = JSON.parse(
        this.storageService.getItem('bixi-currentProfile')
      );
      this.currentProfiles = JSON.parse(
        this.storageService.getItem('bixi-currentProfiles')
      );

      if(this.storageService.getItem('Bixi_BlockAndReturnScan')){
        let BlockAndReturnScan: StatusProfileBlockAndReturnScan  = JSON.parse(this.storageService.getItem('Bixi_BlockAndReturnScan'));
        this.isUserBlocked = BlockAndReturnScan ? JSON.parse(this.storageService.getItem('Bixi_BlockAndReturnScan'))?.isBlockedAction : true      
      }
    
    }

    this.getEquipement();

    localStorage.setItem('bixiApp_isTemplateReadOnly', '0');
    this.currentUserName = localStorage.getItem('bixiApp_currentUserName');

    this.openWorkOrderFeatures = this.accessService.getFeatureByProfile(this.currentProfile.profil)

  }

  getEquipement() {
    this.activatedRoute.params.subscribe((params) => {
      this.equipementNo = params['equipementNo'];
      // checjk if equipementNo is in url
      if (this.equipementNo) {
        this.equipementNo = this.equipementNo.replace('%20', ' ');
        if(window.location.href.includes('?wo-opened=false') || window.location.href.includes('wo-opened%3Dfalse')){
          this.equipementNo = this.equipementNo.slice(0, this.equipementNo.indexOf('?wo-opened=false'))
        }
        let equipementRoueSerialNumber = this.storageService.getItem('equipementRoueSerialNumber')
        let currentSerialNumber = this.storageService.getItem('currentSerialNumber')
        if(equipementRoueSerialNumber){
          this.storageService.setItem('equipementRoueSerialNumber', currentSerialNumber)
        }
        this.storageService.setItem('currentSerialNumber', this.equipementNo)
        // Loading indicator with a message, and the new options
        Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff',
        });
        this.templateMakerService.getPortailDefDisplayNames(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id).subscribe({
          next: (defDisplayNames) => {
            this.defDisplayNames = defDisplayNames.value
          },
          error: (error) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
             })
          }
        })
        this.homeService
          .getEquipement(
            this.BIXI_userAuthObject.tenantId,
            this.currentUserCompany.id,
            this.equipementNo
          )
          .subscribe({
            next: (equipement) => {
              this.equipement = equipement.value[0];
              if(window.location.href.includes('?wo-opened=false') || window.location.href.includes('wo-opened%3Dfalse')){
                  this.woOpenedState = false
              }
              this.getWorkorders();
              this.openWorkOrderService
                .getTrackedItemsStatistics(
                  this.BIXI_userAuthObject.tenantId,
                  this.currentUserCompany.id,
                  "noActif eq '" + this.equipementNo + "'",
                  '$expand=trackedItemsbyActifs'
                )
                .subscribe({
                  next: (consumptionTrackData) => {
                    this.consumptionTrackData =
                      consumptionTrackData.value[0].trackedItemsbyActifs;
                  },
                  error: (error) => {},
                });
              Notiflix.Loading.remove();
            },
            error: (error) => {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure(error, {
                timeout: 3000,
                showOnlyTheLastOne: true, 
                width: "400px",
              });
              // redirect user to home page
              this.router.navigateByUrl(
                `home/${this.currentUserCompany.id}/${this.currentProfile.id}`
              );
            },
          });
      } else {
        // redirect user to home page
        this.router.navigateByUrl(
          `home/${this.currentUserCompany.id}/${this.currentProfile.id}`
        );
      }
    });
  }

  refreshEquipementEvent() {
    this.getEquipement();
  }

  getWorkorders() {
    this.openWorkOrderService
      .getWorkorders(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        "correctiveMaintenance eq true and assetNo eq '" +
          this.equipementNo +
          "'"
      )
      .subscribe({
        next: (inProgressWorkOrdersData) => {
          this.inProgressWorkOrdersData = inProgressWorkOrdersData.value;

          const BTLeadArray: any = this.inProgressWorkOrdersData.filter(
            (data) => data.no == this.equipement.workOrderNo
          );

          if (BTLeadArray.length > 0) {
            const BTLead = BTLeadArray[0];

            const BTLeadIndex = this.inProgressWorkOrdersData.findIndex(
              (data) => data.no == BTLead.no
            );

            if (BTLeadIndex) {
              this.inProgressWorkOrdersData.splice(BTLeadIndex, 1);
              this.inProgressWorkOrdersData.unshift(BTLead);
            }
          }

          this.openWorkOrderService
            .getWorkorders(
              this.BIXI_userAuthObject.tenantId,
              this.currentUserCompany.id,
              "correctiveMaintenance eq false and assetNo eq '" +
                this.equipementNo +
                "' and leadWoNo eq ''"
            )
            .subscribe({
              next: (openWorkOrdersData) => {
                this.openWorkOrdersData = openWorkOrdersData.value;
              },

              error: (error) => {},
            });

          if (this.inProgressWorkOrdersData.length > 0) {
            this.openWorkOrderService
              .getWorkorders(
                this.BIXI_userAuthObject.tenantId,
                this.currentUserCompany.id,
                "correctiveMaintenance eq false and assetNo eq '" +
                  this.equipementNo +
                  "' and leadWoNo eq '" +
                  this.inProgressWorkOrdersData[0].no +
                  "' "
              )
              .subscribe({
                next: (openedPreventifWorkOrdersData) => {
                  this.inProgressWorkOrdersData.push(
                    ...openedPreventifWorkOrdersData.value
                  );
                  this.currentWoGrid.refresh();
                },

                error: (error) => {},
              });
          }
        },

        error: (error) => {},
      });
  }

  public checkBoxChange(event): void {
    var selectedRecords: any[] = this.currentWoGrid.getSelectedRecords();

    if (selectedRecords.length > 0) {
      this.disableEditBTButton = true;
    } else {
      this.disableEditBTButton = false;
    }
  }

  confirmAddWorkOrder() {
    // Notiflix.Loading.standard('Chargement...', {
    //   svgColor: '#ffffff'
    // });
    // this.workOrdersData.push({WorkOrder: "122-00",State: "En réparation", ProductId: "E20001",Revision: "Procédé Config E-FitV3-(2023)",Category: "Préventif standard", Date: "27/03/2023",Day: this.getRandomNumber(1,300),Location: 0,Evaluateur: this.getRandomNumber(70,90),Mecanician: this.getRandomNumber(200,250),Supervisor: this.getRandomNumber(100,150)});
    // setTimeout(() => {
    //   Notiflix.Loading.remove();
    //   this.workOrdersGrid.refresh();
    //   Notiflix.Notify.success("Bon de tavail crée avec succès!", {timeout: 1500})
    // }, 2000);
  }

  onpenEquipementAssocieModal(){
    this.equipementAssocieModal = new bootstrap.Modal(document.getElementById('associatedEquipModal'), {})
    this.equipementAssocieModal.show() 
  }

  createNewWorkOrder(equipRoue){
      const assetNum = equipRoue.num
      const data =  { sourceBT: this.equipement.workOrderNo, assetNo: assetNum }
      this.storageService.setItem('equipementRoueSerialNumber', assetNum)
      const concatenatedUrl = 'createLinkedWos'
      this.openWorkOrderService.createElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, data, concatenatedUrl).subscribe({
        next: (data) => {
          this.openWorkOrderService.getWorkorders(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "no eq '"+ data.workOrderNo +"'").subscribe({
              next: (workOrder) => {
                if(workOrder.value.length > 0){
                  this.roueWorkorder = workOrder.value[0]
                  this.createdByTemplateId =  workOrder.value[0].createdByTemplateId
                  const concatenatedUrl = "/workOrders("+ this.roueWorkorder.systemId +")"
                  this.openWorkOrderService.editElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.roueWorkorder, concatenatedUrl).subscribe({
                    next: (wo) => {
                      this.roueWorkorder = wo
                      this.detailWorkorderService.getWorkOrderTemplateWithoutFilter(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.createdByTemplateId).subscribe({
                        next: (workorderTemplate) => {
                          this.templateRoue = workorderTemplate
                          this.detailWorkorderService.getTaskDetails(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id).subscribe({
                            next: (definitions) => {
                              this.taskLineDefinitions = definitions.value
                              this.getTaskCategoryByDocs()   
                            },
                            error: (error) => {
                            }
                          })
                          Notiflix.Loading.remove();
                          Notiflix.Notify.success("Bon de tavail crée avec succès!", {timeout: 3000, width: "400px"})
                          this.refreshAssetLinkEvent.next()                      
                          if(!this.associatedEquipTemplateModal){
                              this.associatedEquipTemplateModal = new bootstrap.Modal(document.getElementById('associatedEquipTemplateModal'), {})
                              this.associatedEquipTemplateModal.show()  
                            }else {
                              this.associatedEquipTemplateModal.show()  
                            }                      
                        },
                        error: (error) => {
                          Notiflix.Loading.remove();
                          Notiflix.Notify.failure("Aucun template trouvé pour cet équipement", {
                            timeout: 3000,
                            showOnlyTheLastOne: true, 
                            width: "400px",
                          })   
                        }
                      })                    
                    },
                    error: (error) => {}
                  })                 
                }
              },
              error: (error) => {
              }
          })     
        },
        error: (error) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
           })
        }
      })
    this.equipementAssocieModal.hide() 
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff'
    });
  }

  getTaskLineIcon(displayName, columnLineIndex, sectionLineIndex, subSectionLineIndex, taskLineIndex){
    const dataFound = this.defDisplayNames.filter(defDisplayName => defDisplayName.code == displayName)
    if(dataFound.length > 0){
       const content64 = dataFound[0].content64
       const element:any = document.getElementById('taskLine_icon_'+ columnLineIndex +'_'+ sectionLineIndex +'_'+ subSectionLineIndex +'_'+taskLineIndex)
       if(element !== null){
         if(content64.trim() !== ''){
           element.src = "data:image/png;base64,"+content64
         }else{
           element.style.display = 'none'
         }   
       }    
    }
   }

  AddTaskToWorkOrder(e){
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff'
    });
    this.detailWorkorderService.getWorkOrderCategoriesWithFilter(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "code eq '"+ e.object.codeTaskCategory +"'").subscribe({
      next: (resData) => {
        const concatenatedUrl = "/taskCategoriesByDocs"
        const data = {
          codeTaskCategory: e.object.codeTaskCategory,
          codeTaskCategoryId: resData.value[0].systemId,
          createdByProfil: this.currentProfile.profil,
          createdByTemplate: this.templateRoue.code,
          createdByTemplateId: this.createdByTemplateId,
          documentId: this.roueWorkorder.systemId,
          documentNo: this.roueWorkorder.no,
          multiple: e.object.fieldType == 'Selection' ? 1 : this.taskMultipleValue,
          documentType: 'Order',
        }
        this.detailWorkorderService.createElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, data, concatenatedUrl).subscribe({
          next: (resData) => {
            Notiflix.Loading.remove();
            e.object.taskCategoryByDoc = resData
            e.object.codeTaskCategory = resData.codeTaskCategory
            if(this.associatedEquipTemplateModal && !this.associatedEquipTemplateModal._isShown){
                 this.associatedEquipTemplateModal.show()
             }
            Notiflix.Notify.success("Tâche ajoutée avec succès", {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
             })
          },
          error: (error) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
             })
          }
        })
      },
      error: (error) => {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure(error, {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
         })
      }
    })
  }

  onMultipleTaskCancel(){
    if(this.associatedEquipTemplateModal && !this.associatedEquipTemplateModal._isShown){
      this.associatedEquipTemplateModal.show()
    }
  } 

  canRemoveTask(e){
    const systemId = e.object.taskCategoryByDoc.systemId
    const concatenatedUrl = "/taskCategoriesByDocs("+systemId+")"
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff'
    });
    this.detailWorkorderService.deleteElement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, concatenatedUrl).subscribe({
      next: (resData) => {
        this.getTaskCategoryByDocs()
        Notiflix.Loading.remove();
        e.object.taskCategoryByDoc = undefined
        Notiflix.Notify.success("Tâche retirée avec succès", {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
         })
      },
      error: (error) => {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure(error, {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
         })
      }
    })
  }

  getTaskCategoryByDocs(){
    this.detailWorkorderService.getTaskCategoryByDoc(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "$filter=documentNo eq '"+ this.roueWorkorder.no +"'" ).subscribe({
      next: (taskCategoryByDocs) => {
        this.taskCategoryByDocs = taskCategoryByDocs.value
        this.templateRoue.columnLines.forEach((columnLine, i) =>{                
          columnLine.sectionLines.forEach((sectionLine, j) =>{
            sectionLine.subSectionLines.forEach((subSectionLine, k) =>{
              subSectionLine.taskLines.forEach((taskLine, l) =>{
                const foundData = this.taskLineDefinitions.filter(taskLineDefinition => taskLineDefinition.code == taskLine.displayName)
                if(foundData.length > 0){
                    taskLine.definition = foundData[0] 
                }else{
                  taskLine.definition = undefined
                }
                if(taskLine.taskCategoryByDoc == undefined){           
                  const _foundData = this.taskCategoryByDocs.filter(taskCategoryByDoc => (taskCategoryByDoc.documentNo == this.roueWorkorder.no && taskCategoryByDoc.codeTaskCategory == this.templateRoue.columnLines[i].sectionLines[j].subSectionLines[k].taskLines[l].codeTaskCategory))                 
                  if(_foundData.length > 0){
                      taskLine.taskCategoryByDoc = _foundData[0] 
                  }             
                }
              })
            })
          })
        })       
      },
      error: (error) => {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure(error, {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
         })
      }
    })
  }

  getRandomNumber(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  toolbarClick(args: ClickEventArgs): void {
    switch (args.item.text) {
      case 'Nouveau':
        this.createWorkorder();
        break;
      case 'Afficher/masquer':
        var left = window.innerWidth;
        this.workOrdersGrid.openColumnChooser(left - 373, 40);
        break;
      default:
    }
  }

  columnsShowHideCurrentWo() {
    var left = window.innerWidth;
    this.workOrdersGrid.openColumnChooser(left - 373, 0);
  }

  columnsShowHideOpenWo() {
    var left = window.innerWidth;
    this.currentWoGrid.openColumnChooser(left - 373, 0);
  }

  addOpenWoToCurrentWoList() {
    var selectedRecords = this.workOrdersGrid.getSelectedRecords();
    if (selectedRecords.length > 0) {
      if (this.inProgressWorkOrdersData.length > 0) {
        Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff',
        });
        selectedRecords.forEach((selectedRecord: any, index) => {
          const concatenatedUrl =
            '/saveSelectionWorkOrders(' + selectedRecord.systemId + ')';

          const data = {
            leadWoNo: this.equipement.workOrderNo,
          };
          this.openWorkOrderService
            .editElement(
              this.BIXI_userAuthObject.tenantId,
              this.currentUserCompany.id,
              JSON.stringify(data),
              concatenatedUrl
            )
            .subscribe({
              next: (resData) => {
                const _concatenatedUrl =
                  '/saveStatus(' + selectedRecord.systemId + ')';

                const __data = {
                  eraBixiStatus: this.equipement.workOrderBixiStatus,
                };
                this.openWorkOrderService
                  .editElement(
                    this.BIXI_userAuthObject.tenantId,
                    this.currentUserCompany.id,
                    JSON.stringify(__data),
                    _concatenatedUrl
                  )
                  .subscribe({
                    next: (resData) => {},
                    error: (error) => {},
                  });

                if (index == selectedRecords.length - 1) {
                  Notiflix.Loading.remove();

                  Notiflix.Notify.success(
                    selectedRecords.length == 1
                      ? 'Bon de travail ouvert inclut avec succès'
                      : 'Bons de travail ouverts inclus avec succès',
                    {
                      timeout: 3000,
                      showOnlyTheLastOne: true, 
                      width: "400px",
                    }
                  );

                  this.deleteSelectedRows(
                    this.workOrdersGrid,
                    this.inProgressWorkOrdersData,
                    false
                  );

                  this.getWorkorders();

                  this.workOrdersGrid.refresh();
                  this.currentWoGrid.refresh();
                }
              },
              error: (error) => {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(error, {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                });
              },
            });
        });
      } else {
        Notiflix.Notify.failure('Aucun bon de travail en cours trouvé', {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
        });
      }
    } else {
      Notiflix.Notify.warning('Aucun bon de travail ouvert sélectionné!', {
        showOnlyTheLastOne: true, 
        width: "400px",
      });;
    }
  }

  addCurrentWoToOpenWoList() {
    var selectedRecords: any[] = this.currentWoGrid.getSelectedRecords();
    if (selectedRecords.length > 0) {
      if (selectedRecords[0].correctiveMaintenance == true) {
        Notiflix.Notify.warning(
          "Impossible d'exclure un bon de travail correctif!", {
            showOnlyTheLastOne: true, 
            width: "400px",
        });
      } else {
        Notiflix.Loading.standard('Chargement...', {
          svgColor: '#ffffff',
        });

        selectedRecords.forEach((selectedRecord: any, index) => {
          const concatenatedUrl =
            '/saveSelectionWorkOrders(' + selectedRecord.systemId + ')';

          const data = {
            leadWoNo: '',
          };
          this.openWorkOrderService
            .editElement(
              this.BIXI_userAuthObject.tenantId,
              this.currentUserCompany.id,
              JSON.stringify(data),
              concatenatedUrl
            )
            .subscribe({
              next: (resData) => {
                if (index == selectedRecords.length - 1) {
                  Notiflix.Loading.remove();

                  Notiflix.Notify.success(
                    selectedRecords.length == 1
                      ? 'Bon de travail en cours exclut avec succès'
                      : 'Bons de travail en cours exclus avec succès',
                    {
                      timeout: 3000,
                      showOnlyTheLastOne: true, 
                      width: "400px",
                    }
                  );

                  this.disableEditBTButton = false;

                  this.getWorkorders();

                  this.workOrdersGrid.refresh();
                  this.currentWoGrid.refresh();
                }
              },
              error: (error) => {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(error, {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                });
              },
            });
        });
      }
    } else {
      Notiflix.Notify.warning('Aucun bon de travail en cours sélectionné!', {
        showOnlyTheLastOne: true, 
        width: "400px",
      });
    }
  }

  goToMultipleBTDetailworkorder() {
    let paramArray = [];
    Notiflix.Notify.info('Nous ouvrons tous les bons de travail en cours!', {
      showOnlyTheLastOne: true, 
      width: "400px",
    });
    this.inProgressWorkOrdersData.forEach((selectedRecord: any, index) => {
      paramArray.push(selectedRecord.no);
      if (index == this.inProgressWorkOrdersData.length - 1) {
        let stringArray = paramArray.join(',');
        this.router.navigateByUrl(
          `detailworkorder/${this.currentUserCompany.id}/${this.currentProfile.id}/workOrders/${stringArray}`
        );
      }
    });
  }
  // this.workOrdersGrid
  deleteSelectedRows(grid, data: Object[], checkType: boolean): void {
    let selectedRows = grid.getSelectedRecords();
    for (let k = 0; k < selectedRows.length; k++) {
      const index = data.indexOf(selectedRows[k]);
      if (index > -1) {
        if (!checkType || selectedRows[k]['Type'] !== 'Correctif')
          data.splice(index, 1);
      }
    }
  }

  createWorkorder() {
    Notiflix.Confirm.show(
      'Confirmation',
      'Voulez-vous vraiment créer un nouveau bon de travail?',
      'Confirmer',
      'Annuler',
      () => {
        this.confirmAddWorkOrder();
      },
      () => {},
      {
        width: '450px',
        okButtonBackground: '#04B76B',
        cancelButtonBackground: '#FF5E6D',
        titleColor: '#392C70',
        borderRadius: '5px',
        backgroundColor: '#ffffff',
      }
    );
  }

  goReadOnlyToTemplate(workOrderNo) {
    localStorage.setItem('bixiApp_isTemplateReadOnly', '1');
    this.router.navigateByUrl(
      `detailworkorder/${this.currentUserCompany.id}/${this.currentProfile.id}/workOrder/${workOrderNo}`
    );
  }

  goToTemplate(workOrderNo) {
    localStorage.setItem('bixiApp_isTemplateReadOnly', '0');
    this.router.navigateByUrl(
      `detailworkorder/${this.currentUserCompany.id}/${this.currentProfile.id}/workOrder/${workOrderNo}`
    );
  }

  onBtClose(){
    if(this.equipementAssocieModal && !this.equipementAssocieModal._isShown){
      this.equipementAssocieModal.show()
    } 
  }


  addTaskByMultipleValue() {
    if (this.EObjectToUpdate !== null) {
      if (
        this.taskMultipleValue < this.EObjectToUpdate.object.fieldMINValue ||
        this.taskMultipleValue > this.EObjectToUpdate.object.fieldMAXValue
      ) {
        setTimeout(() => {
          var myModal = new bootstrap.Modal(
            document.getElementById('mulitipeValueTaskLineModal'),
            {}
          );
          myModal.toggle();
        }, 300)
        Notiflix.Notify.failure(
          'Le multiplicateur doit être compris entre la valeur Min et la valeur Max.',
          {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          }
        );
      } else {
        this.AddTaskToWorkOrder(this.EObjectToUpdate);
      }
    }
  }

}
