import { Component, OnInit, ViewChild } from '@angular/core';
import * as Notiflix from 'notiflix';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';

import {
  ColumnChooser,
  GridComponent,
  ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/core/shared/services/storage.service';
import { HomeService } from '../home/services/home.service';
import { Profile } from 'src/app/core/shared/models/profile';
import { Template } from 'src/app/core/shared/models/Template';
import { Company } from 'src/app/core/shared/models/company.model';
import { Equipement } from 'src/app/core/shared/models/equipement';
import { ArchiveWorkorderService } from './services/archive-workorder.service';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { StatusProfileBlockAndReturnScan } from 'src/app/core/shared/models/statusProfileBlockAndReturnScan.model';
declare var bootstrap: any;

@Component({
  selector: 'app-archive-workorder',
  templateUrl: './archive-workorder.component.html',
  styleUrls: ['./archive-workorder.component.scss'],
})
export class ArchiveWorkorderComponent implements OnInit {
  pageName = 'archiveWorkorder';
  public workOrdersData: Object[];
  public listEquipData: Object[];
  public currentWoData: object[];
  public consumptionTrackData: object[];
  public workOrderDetailData: any[] = [];
  public pageSettings: Object;
  public toolbarOptions: ToolbarItems[] | object;
  public selectOptions: Object;
  public editSettings: Object;
  public columnChooserOption: ColumnChooser;
  currentUserName = '';
  currentUserCompany: Company | undefined;
  userProfil = '';
  userProfilTitle = '';
  isUserBlocked: boolean = true;  
  @ViewChild('workOrdersGrid') workOrdersGrid: GridComponent;
  @ViewChild('cardWorkOrdersGrid') cardWorkOrdersGrid: any;
  BIXI_userAuthObject: any = null;
  currentProfile: Profile = null;
  currentProfiles: Profile[] = [];
  categoryCode: string = '';
  workorderTemplate: Template = null;
  workOrderSystemId: string = '';
  workOrderNo = '';
  equipement: Equipement = null;
  equipementNo: string = '';
  archices: Worker = null;
  currentWorkorder = null;
  itemPostedWorkOrderLines = [];
  expensePostedWorkOrderLines = [];
  expensesSubTasks = [];
  drawTable = false;
  selectedWorEquipment = '';

  constructor(
    private router: Router,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private homeService: HomeService,
    private archiveWorkorderService: ArchiveWorkorderService,
    private AuthService: AuthService
  ) {}

  logout() {
    Notiflix.Notify.init({
      position: 'right-bottom',
    });

    Notiflix.Confirm.show(
      'Confirmation',
      'Êtes-vous sûr de vouloir vous déconnecter?',
      'Confirmer',
      'Annuler',
      () => {
        this.router.navigateByUrl('/');
      },
      () => {},
      {
        width: '450px',
        okButtonBackground: '#04B76B',
        cancelButtonBackground: '#FF5E6D',
        titleColor: '#392C70',
        borderRadius: '5px',
        backgroundColor: '#ffffff',
      }
    );
  }

  workorderDetailModalData(currentWorkorder) {
    this.currentWorkorder = currentWorkorder;
    // Loading indicator with a message, and the new options
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });

    this.archiveWorkorderService
      .getArchivesOrderLines(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        this.currentWorkorder.systemId,
        "type eq 'Item'"
      )
      .subscribe({
        next: (resData) => {
          this.itemPostedWorkOrderLines = resData.value;

          this.archiveWorkorderService
            .getArchivesOrderLines(
              this.BIXI_userAuthObject.tenantId,
              this.currentUserCompany.id,
              this.currentWorkorder.systemId,
              "type eq 'Expense'"
            )
            .subscribe({
              next: (resData) => {
                // Dépenses
                Notiflix.Loading.remove();
                this.expensePostedWorkOrderLines = resData.value;

                let tempArray = [];

                this.expensePostedWorkOrderLines.forEach((data) => {
                  tempArray.push({codeTaskCategory: data.codeTaskCategory, descTaskCategory: data.descTaskCategory });
                });

                tempArray = this.removeDuplicates(tempArray);

                tempArray.forEach((element, i) => {
                  this.workOrderDetailData[0].subtasks.push({
                    num: '',
                    codeTaskCategory: element.codeTaskCategory,
                    action: '',
                    variante: '',
                    btAssoc: '',
                    description: element.descTaskCategory,
                    uom: '',
                    locationCode: '',
                    quantity: null,
                    date: '',
                    eraFinished: 'nothing',
                    eraApproved: 'nothing',
                    doneBy: '',
                    role: '',
                    subtasks: [],
                  });
                  this.expensePostedWorkOrderLines.forEach(
                    (expensePostedWorkOrderLine, j) => {
                      if (
                        expensePostedWorkOrderLine.codeTaskCategory == element.codeTaskCategory
                      ) {
                        this.workOrderDetailData[0].subtasks[i].subtasks.push(
                          expensePostedWorkOrderLine
                        );
                      }
                    }
                  );
                });

                tempArray = [];        
                this.itemPostedWorkOrderLines.forEach((data) => {
                  tempArray.push({codeTaskCategory: data.codeTaskCategory, descTaskCategory: data.descTaskCategory });
                });              
                tempArray = this.removeDuplicates(tempArray);
                tempArray.forEach((element, i) => {
                  this.workOrderDetailData[1].subtasks.push({
                    num: '',
                    codeTaskCategory: element.codeTaskCategory,
                    action: '',
                    variante: '',
                    btAssoc: '',
                    description: element.descTaskCategory,
                    uom: '',
                    locationCode: '',
                    quantity: null,
                    date: '',
                    eraFinished: 'nothing',
                    eraApproved: 'nothing',
                    doneBy: '',
                    role: '',
                    subtasks: [],
                  });

                  this.itemPostedWorkOrderLines.forEach(
                    (itemPostedWorkOrderLine, j) => {
                      if (
                        itemPostedWorkOrderLine.codeTaskCategory == element.codeTaskCategory
                      ) {
                        this.workOrderDetailData[1].subtasks[i].subtasks.push(
                          itemPostedWorkOrderLine
                        );
                      }
                    }
                  );
                });

                this.drawTable = true;
              },
              error: (error) => {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(
                  "Une erreur s'est produite pendant le traitement. Veuillez réessayer",
                  {
                    timeout: 3000,
                    showOnlyTheLastOne: true, 
                    width: "400px",
                  }
                );
              },
            });
        },
        error: (error) => {
          Notiflix.Notify.failure(
            "Une erreur s'est produite pendant le traitement. Veuillez réessayer",
            {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            }
          );
        },
      });
  }

  checkUserProfil() {
    this.userProfil = localStorage.getItem('bixiApp_userProfil') || '';
    if (this.userProfil == 'M') this.userProfilTitle = 'Mécanicien';
    if (this.userProfil == 'E') this.userProfilTitle = 'Evaluateur';
    if (this.userProfil == 'S') this.userProfilTitle = 'Superviseur';
  }

  changeProfile(profil) {
    if (profil) {

      const profileFound = this.currentProfiles.find(
        (currentProfile) => currentProfile.profil == profil
      );

      localStorage.setItem('bixi-currentProfile', JSON.stringify(profileFound));
      this.reloadCurrentRoute();

    } else {
      this.AuthService.logout();
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    currentUrl = currentUrl.replace('%20', ' ');
    location.reload()
  }

  ngOnInit() {
    // check if user has data in browser storage, else redirect himn to login page
    if (
      !this.storageService.getItem('bixi-user-auth') ||
      !this.storageService.getItem('bixi-currentCompany') ||
      !this.storageService.getItem('bixi-currentProfile')
    ) {
      this.router.navigateByUrl('/auth/login');
    } else {
      this.BIXI_userAuthObject = JSON.parse(
        this.storageService.getItem('bixi-user-auth')
      );
      this.currentUserCompany = JSON.parse(
        this.storageService.getItem('bixi-currentCompany')
      );
      this.currentProfile = JSON.parse(
        this.storageService.getItem('bixi-currentProfile')
      );
      this.currentProfiles = JSON.parse(
        this.storageService.getItem('bixi-currentProfiles')
      );
      this.equipementNo = this.storageService.getItem('currentSerialNumber');

      if(this.storageService.getItem('Bixi_BlockAndReturnScan')){
        let BlockAndReturnScan: StatusProfileBlockAndReturnScan  = JSON.parse(this.storageService.getItem('Bixi_BlockAndReturnScan'));
        this.isUserBlocked = BlockAndReturnScan ? JSON.parse(this.storageService.getItem('Bixi_BlockAndReturnScan'))?.isBlockedAction : true
      } 
  
    }

    this.getEquipement();

    this.checkUserProfil();

    this.currentUserName = localStorage.getItem('bixiApp_currentUserName');

    this.workOrderDetailData = [
      {
        // taskID: 2,
        num: '',
        codeTaskCategory: 'Dépenses',
        action: '',
        variante: '',
        btAssoc: '',
        description: '',
        uom: '',
        locationCode: '',
        quantity: null,
        date: '',
        eraFinished: 'nothing',
        eraApproved: 'nothing',
        doneBy: '',
        role: '',
        subtasks: [],
      },
      {
        // taskID: 11,
        num: '',
        codeTaskCategory: 'Articles',
        action: '',
        variante: '',
        btAssoc: '',
        description: '',
        uom: '',
        locationCode: '',
        quantity: null,
        date: '',
        eraFinished: 'nothing',
        eraApproved: 'nothing',
        doneBy: '',
        role: '',
        subtasks: [],
      },
    ];

    // this.selectOptions = { persistSelection: true };
    this.editSettings = { allowAdding: true };

    // this.toolbarOptions = ['ColumnChooser'];

    // Bootstrap tooltip initialization
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

  refreshEquipementEvent() {
    this.getEquipement();
  }

  getEquipement() {
    this.activatedRoute.params.subscribe((params) => {
      this.equipementNo = params['equipementNo'];

      // checjk if equipementNo is in url
      if (this.equipementNo) {
        this.equipementNo = this.equipementNo.replace('%20', ' ');

        this.homeService
          .getEquipement(
            this.BIXI_userAuthObject.tenantId,
            this.currentUserCompany.id,
            this.equipementNo
          )
          .subscribe({
            next: (equipement) => {
              this.equipement = equipement.value[0];

              this.getArchives();
            },
            error: (error) => {
              Notiflix.Notify.failure(
                'Aucun équipement ou bon de travail ne correspond au numéro saisi!',
                {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                }
              );

              // redirect user to home page
              this.router.navigateByUrl(
                `home/${this.currentUserCompany.id}/${this.currentProfile.id}`
              );
            },
          });
      } else {
        // redirect user to home page
        this.router.navigateByUrl(
          `home/${this.currentUserCompany.id}/${this.currentProfile.id}`
        );
      }
    });
  }

  getArchives() {
    // Loading indicator with a message, and the new options
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    this.archiveWorkorderService
      .getArchives(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        "assetNo eq '" + this.equipementNo + "'"
      )
      .subscribe({
        next: (archices) => {
          this.archices = archices.value;
          Notiflix.Loading.remove();
        },
        error: (error) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(
            'Aucun équipement ou bon de travail ne correspond au numéro saisi!',
            {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            }
          );

          // redirect user to home page
          this.router.navigateByUrl(
            `home/${this.currentUserCompany.id}/${this.currentProfile.id}`
          );
        },
      });
  }

  confirmAddWorkOrder() {
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    this.workOrdersData.push({
      WorkOrder: '122-00',
      State: 'En réparation',
      ProductId: 'E20001',
      Revision: 'Procédé Config E-FitV3-(2023)',
      Category: 'B',
      Date: '27/03/2023',
      Day: this.getRandomNumber(1, 300),
      Location: 0,
      Evaluateur: this.getRandomNumber(70, 90),
      Mecanician: this.getRandomNumber(200, 250),
      Supervisor: this.getRandomNumber(100, 150),
    });
    setTimeout(() => {
      Notiflix.Loading.remove();
      this.workOrdersGrid.refresh();
      Notiflix.Notify.success('Bon de tavail crée avec succès!', {
        timeout: 1500,
        width: "400px"
      });
    }, 2000);
  }

  getRandomNumber(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  toolbarClick(args: ClickEventArgs): void {
    switch (args.item.text) {
      case 'Nouveau':
        this.createWorkorder();
        break;
      case 'Afficher/masquer':
        var left = window.innerWidth;
        this.workOrdersGrid.openColumnChooser(left - 373, 40);
        break;
      default:
        alert(args.item.text);
    }
  }

  columnsShowHide() {
    var left = window.innerWidth;
    this.workOrdersGrid.openColumnChooser(left - 373, 0);
  }
  createWorkorder() {
    Notiflix.Confirm.show(
      'Confirmation',
      'Voulez-vous vraiment créer un nouveau bon de travail?',
      'Confirmer',
      'Annuler',
      () => {
        this.confirmAddWorkOrder();
      },
      () => {},
      {
        width: '450px',
        okButtonBackground: '#04B76B',
        cancelButtonBackground: '#FF5E6D',
        titleColor: '#392C70',
        borderRadius: '5px',
        backgroundColor: '#ffffff',
      }
    );
  }

  removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }
}
