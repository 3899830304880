import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { ErrorService } from '../core/shared/services/error.service';
import { AppConfigService } from '../config/app.config.service';
import { StorageService } from '../core/shared/services/storage.service';
import { AccessGrantObject, Features } from '../models/accessGrantObject.model';

@Injectable({
  providedIn: 'root'
})
export class AccessService {
  defaultCompanyId = AppConfigService.settings.defaultCompanyId;
  bcCoreApiUrl = AppConfigService.settings.bcCoreApiUrl;
  customApiUrlSegment = AppConfigService.settings.customApiUrlSegment;
  bcEnvirName = AppConfigService.settings.bcEnvirName;
  defaultLg = AppConfigService.settings.defaultLanguage;
  standardApiUrlSegment = AppConfigService.settings.standardApiUrlSegment;
  accessGrantObject: AccessGrantObject;
  schemaVersion = AppConfigService.settings.bcSchemaversion;

    constructor(private http: HttpClient,
      private errorService: ErrorService,
      private storageService: StorageService) { }
     // Http Options
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'If-Match': '*',
        'Accept-Language': 'fr-CA'
      }),
    }; 

    getAccessPermissions(): Observable<any>{
      const tenantId = JSON.parse(this.storageService.getItem("bixi-user-auth"))?.tenantId;
      const companyId = JSON.parse(this.storageService.getItem("bixi-currentCompany"))?.id;
      const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+ this.customApiUrlSegment + "/companies("+companyId+")/setups?$schemaversion="+this.schemaVersion;     
      return this.http.get(url,  this.httpOptions).pipe(
        catchError(this.errorService.handleError));
    }

    getFeatureByProfile(profil:string): Features{
      let feature = null;
      switch(profil){
        case "Evaluator":
          feature = this.accessGrantObject.Evaluator.features;
          break;
        case "Mechanic":
          feature = this.accessGrantObject.Mechanic.features;
          break;
        case "Supervisor":
          feature = this.accessGrantObject.Supervisor.features;
          break;
        case "Administrator":
          feature = this.accessGrantObject.Administrator.features;
          break; 
      }      

      return feature;
    }
}
