import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/config/app.config.service';
import { ErrorService } from '../../shared/services/error.service';
import { GenericApi } from '../../shared/models/generic-api.model';
import { Observable, catchError, map } from 'rxjs';
import { Company } from '../../shared/models/company.model';
import { Profile } from '../../shared/models/profile';

@Injectable({
  providedIn: 'root'
})
export class ProfilService {
defaultCompanyId = AppConfigService.settings.defaultCompanyId;
bcCoreApiUrl = AppConfigService.settings.bcCoreApiUrl;
customApiUrlSegment = AppConfigService.settings.customApiUrlSegment;
bcEnvirName = AppConfigService.settings.bcEnvirName;
defaultLg = AppConfigService.settings.defaultLanguage;
tenantId: string = ''
schemaVersion = AppConfigService.settings.bcSchemaversion;

constructor(
   private http: HttpClient,
   private errorService: ErrorService  
) { }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Accept-Language' : 'fr-CA'
    }),
  }; 

  getCompanies(BIXI_userAuthObject: any): Observable<Company[]>{
    this.tenantId = BIXI_userAuthObject.tenantId
    const url = this.bcCoreApiUrl+this.tenantId+"/"+this.bcEnvirName+"/"+this.customApiUrlSegment+"/companies?$schemaversion="+this.schemaVersion; 
    return this.http.get<GenericApi>(url, this.httpOptions).pipe(
      map(result  => result.value as Company[]),      
      catchError(this.errorService.handleError));
  }
  
  getProfiles(BIXI_userAuthObject: any, companyId: string): Observable<Profile[]>{
    this.tenantId = BIXI_userAuthObject.tenantId
    const href = this.bcCoreApiUrl+this.tenantId+"/"+this.bcEnvirName+"/"+this.customApiUrlSegment+"/companies("+companyId+")/eraPortailProfils?$schemaversion="+this.schemaVersion;     
    return this.http.get<GenericApi>(href, this.httpOptions).pipe(
      map(result  => result.value as Profile[]),
      catchError(this.errorService.handleError));
  }


}
