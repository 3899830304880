<div class="card card-statistics">
  <div *ngIf="
      pageName == 'openWorkorder' ||
      pageName == 'detailWorkorder' ||
      pageName == 'bikepartslist'
    " class="card-body pt-3 pb-3">
    <div class="d-md-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center mb-3 mb-md-0">
        <div class="text-left">
          <h5 class="mb-0 fw-bold">N° de série</h5>
          <p class="mb-0 mt-1" *ngIf="equipement">{{ equipement.no }}</p>
        </div>
      </div>
      <div class="d-flex align-items-center mb-3 mb-md-0">
        <div class="text-left">
          <h5 class="mb-0 fw-bold">Description</h5>
          <p class="mb-0 mt-1" *ngIf="equipement">
            {{ equipement.description }}
          </p>
        </div>
      </div>
      <div class="d-flex mb-3 mb-md-0">
        <div class="text-left">
          <h5 class="mb-0 fw-bold">Catégorie équipement</h5>
          <p class="mb-0 mt-1" *ngIf="equipement && equipement.categoryDescription !== ''">
            {{ equipement.categoryDescription }}
          </p>
          <p class="mb-0 mt-1 absence-of-data" *ngIf="equipement && equipement.categoryDescription == ''">
            Aucune catégorie d'équipement
          </p>
        </div>
      </div>
      <div class="d-flex align-items-center mb-3 mb-md-0">
        <div class="text-left">
          <h5 class="mb-0 fw-bold">Bon travail en cours</h5>
          <p class="mb-0 mt-1" *ngIf="equipement">
            {{ equipement.workOrderNo }}
          </p>
        </div>
      </div>

      <div class="d-flex align-items-center mb-3 mb-md-0">
        <div class="text-left">
          <h5 class="mb-0 fw-bold" style="position: relative; top: 9px">
            Statut bon de travail
          </h5>
          <p class="mb-0 mt-1 text-info" (click)="changeStatusProcess()">
            <a class="nav-link pe-auto" style="padding-left: 0px !important" role="button">
              <span class="menu-title">{{
                equipement.workOrderBixiStatusDesc
                }}</span>
            </a>
          </p>
        </div>
      </div>
      <div class="d-flex align-items-center mb-3 mb-md-0 d-none">
        <div class="text-left">
          <h5 class="mb-0 fw-bold">Statut équipement actuel</h5>
          <p class="mb-0 mt-1" *ngIf="equipement">
            {{ equipement.assetStatusCode }}
          </p>
          <!-- <p class="mb-0 mt-1 d-none">
            <select class="form-select form-select-sm text-small" id="exampleFormControlSelect3">
              <option class="text-small">Dans la rue</option>
              <option class="text-small">En réparation</option>
              <option class="text-small">Dans le magasin</option>
            </select>
          </p> -->
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="text-left">
          <h5 class="mb-0 fw-bold">Commanditaire</h5>
          <p class="mb-0 mt-1" *ngIf="equipement">
            {{ equipement.commanditaire }}
          </p>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="text-left">
          <h5 class="mb-0 fw-bold">Emplacement équipement</h5>
          <p class="mb-0 mt-1" *ngIf="equipement">
            {{ equipement.assetLocationCode }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="pageName == 'archiveWorkorder'" class="card-body pt-3 pb-3">
    <div class="d-md-flex">
      <div class="d-flex mb-3 mb-md-0 mr-5">
        <div class="text-left">
          <h5 class="mb-0 fw-bold">N° de série</h5>
          <p class="mb-0 mt-1" *ngIf="equipement">{{ equipement.no }}</p>
        </div>
      </div>
      <div class="d-flex mb-3 mb-md-0 mr-5">
        <div class="text-left">
          <h5 class="mb-0 fw-bold">Description</h5>
          <p class="mb-0 mt-1" *ngIf="equipement">
            {{ equipement.description }}
          </p>
        </div>
      </div>
      <div class="d-flex mb-3 mb-md-0">
        <div class="text-left">
          <h5 class="mb-0 fw-bold">Catégorie équipement</h5>
          <p class="mb-0 mt-1" *ngIf="equipement && equipement.categoryDescription !== ''">
            {{ equipement.categoryDescription }}
          </p>
          <p class="mb-0 mt-1 absence-of-data" *ngIf="equipement && equipement.categoryDescription == ''">
            Aucune catégorie d'équipement
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="workOrderBixiStatusModal" tabindex="-1" aria-labelledby="workOrderBixiStatusModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" style="width: 30%">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-customized-title" id="workOrderBixiStatusModalLabel">
          <i class="fa fa-edit mr-2"></i><span class="fw-bold">Changement du statut</span>
        </h6>
        <button type="button" id="closeWorkorderStatusModal" class="btn btn-sm btn-danger my-1" data-bs-dismiss="modal"
          aria-label="Close"><i class="fa fa-times"></i></button>
      </div>
      <div class="modal-body">
        <select class="form-select" id="exampleFormControlSelect3" (change)="onAuthorizedStatusChange($event)"
          [(ngModel)]="selectedValue">
          <option value="">
            Veuillez sélectionner un statut
          </option>
          <option *ngFor="
              let eraAutorisedStatusData of eraAutorisedStatus;
              let i = index
            " [value]="eraAutorisedStatusData.codeStatusDestination">
            {{ eraAutorisedStatusData.statusDestinationDescription }}
          </option>
        </select>
      </div>
      <div class="modal-footer px-3">
        <button type="button" class="btn btn-primary btn-sm" (click)="saveStatus()" [disabled]="disableButton">
          <i class="fa fa-save mr-2"></i>Modifier
        </button>
        <button type="button" class="btn btn-light btn-sm" data-bs-dismiss="modal" aria-label="Close">
          <i class="fa fa-times mr-2"></i>Annuler
        </button>
      </div>
    </div>
  </div>
</div>

<!-- <app-detail-tache-modal (refreshGetEquipementEvent)="refreshGetEquipement()" [BIXI_userAuthObject]="BIXI_userAuthObject"
  [currentProfile]="currentProfile" [currentProfiles]="currentProfiles" [currentUserCompany]="currentUserCompany"
  [BTandLines]="BTandLines" [openDetailTacheModalEvent]="openDetailTacheModalEvent"
  [populateExpensesArrayEvent]="populateExpensesArrayEvent" [populateItemsArrayEvent]="populateItemsArrayEvent"
  [sameTasks]="sameTasks" [currentWorkorder]="currentWorkorder" [itemsList]="itemsList" [expensesList]="expensesList"
  [allItemVariants]="allItemVariants" [editWorkOrderFeatures]="editWorkOrderFeatures"
  [expenseWorkOrderLines]="expenseWorkOrderLines" [itemWorkOrderLines]="itemWorkOrderLines"
  [onSelectedAllFinishedClickEvent]="onSelectedAllFinishedClickEvent"
  [onReopenGeneralClickEvent]="onReopenGeneralClickEvent"
  [onSelectedAllValidateClickEvent]="onSelectedAllValidateClickEvent" [unitsOfMeasureList]="unitsOfMeasureList"
  [locationsList]="locationsList" [bikePartsData]="bikePartsData"
  [isUserBlocked]="isUserBlocked"></app-detail-tache-modal> -->

<app-confirm-validation-status
  [confirmValidationStatusModalEvent]="confirmValidationStatusModalEvent"></app-confirm-validation-status>