<!-- Section: Design Block -->
<div class="content-wrapper h-100 remove-padding">
  <div class="row g-0 d-flex align-items-center  h-100">
    <div class="col-6 d-lg-flex  h-100 login-half-bg">
    </div>
    <div class="col-6 d-flex flex-column align-items-center justify-content-center">
      <div class="alert login-alert alert-fill-success bg-gradient col-lg-7 col-md-11" *ngIf="authSuccess">
        <i class="fa fa-check-circle"></i>
        {{successAuthMessage}}
        <!-- Félicitations! Connexion établie avec succès -->
        <img src="assets/imgs/loading.gif" class="loading-img" />
      </div>
      <div class="alert login-alert alert-fill-danger alert-dismissible col-lg-7 col-md-11" *ngIf="authFailed">
        <i class="fa fa-exclamation-triangle"></i>
        {{errorAuthMessage}}
        <!-- Échec! Veuillez vous connecter à nouveau -->
      </div>
      <div class="card shadow-5-strong col-lg-7 col-md-11">
        <div class="card-body py-4 px-5">
          <div class="row d-flex justify-content-center text-center">
            <img src="../../../assets/imgs/bixi.png" class="logo-auth mb-4">
            <h4 class="fw-bold">Authentification</h4>
            <h6 class="text-primary fw-lighter text-normal-font-size">Utiliser votre compte Microsoft pour vous
              connecter</h6>
            <button class="btn btn-block btn-primary bg-gradient btn-sm mt-4 rounded-1" (click)="login()"
              [disabled]="disableConnectionButton"><img src="../../../assets/imgs/microsoft.png" width="20" height="20"
                class="me-2">Se connecter</button>
            <a class="btn btn-link small text-auth text-primary text-decoration-none" (click)="logout()"><small>Changer
                votre compte Microsoft?</small></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Section: Design Block -->