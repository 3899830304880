import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/config/app.config.service';
import { Observable, catchError, map } from 'rxjs';
import { ErrorService } from 'src/app/core/shared/services/error.service';
import { Equipement } from 'src/app/core/shared/models/equipement';
import { WorkOrder } from 'src/app/core/shared/models/WorkOrder';
import { Report } from 'src/app/core/shared/models/report.model';

@Injectable({
  providedIn: 'root',
})
export class OpenWorkorderService {
  defaultCompanyId = AppConfigService.settings.defaultCompanyId;
  bcCoreApiUrl = AppConfigService.settings.bcCoreApiUrl;
  customApiUrlSegment = AppConfigService.settings.customApiUrlSegment;
  bcEnvirName = AppConfigService.settings.bcEnvirName;
  defaultLg = AppConfigService.settings.defaultLanguage;
  schemaVersion = AppConfigService.settings.bcSchemaversion;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService
  ) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': 'fr-CA',
      'If-Match': '*',
    }),
  };

  getLinkedAssets(tenantId: any, companyId: string, $filter): Observable<any> {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/linkedAssets?$schemaversion='+this.schemaVersion+'&$expand=linkedrepairassets,eraLinkedChildAssets&$filter=' +
      $filter;
    return this.http
      .get(url, this.httpOptions)
      .pipe(catchError(this.errorService.handleError));
  }
  getWorkorders(tenantId: any, companyId: string, $filter): Observable<any> {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/workOrders?$schemaversion='+this.schemaVersion+'&$filter='+
      $filter;
      
    return this.http.get(url, this.httpOptions).pipe(
      map((result) => result as WorkOrder[]),
      catchError(this.errorService.handleError)
    );
  }

  getWorkordersWithExpand(
    tenantId: any,
    companyId: string,
    $filter,
    $expand
  ): Observable<any> {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/workOrders?$schemaversion='+this.schemaVersion+'&$filter=' +
      $filter +
      '&' +
      $expand;
    return this.http.get(url, this.httpOptions).pipe(
      map((result) => result as Equipement),
      catchError(this.errorService.handleError)
    );
  }

  getTrackedItemsStatistics(
    tenantId: any,
    companyId: string,
    $filter,
    $expand
  ): Observable<any> {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/getTrackedItemsStatistics?$schemaversion='+this.schemaVersion+'&$filter=' +
      $filter +
      '&' +
      $expand;
    return this.http.get(url, this.httpOptions).pipe(
      map((result) => result as Equipement),
      catchError(this.errorService.handleError)
    );
  }

  editElement(
    tenantId: any,
    companyId: string,
    data,
    concatenatedUrl: string
  ): Observable<any> {
    const schemaversion = "?$schemaversion="+this.schemaVersion
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/' +
      concatenatedUrl + schemaversion;

    return this.http.patch(url, data, this.httpOptions).pipe(
      map((result) => result as any),
      catchError(this.errorService.handleError)
    );
  }

  createElement(
    tenantId: any,
    companyId: string,
    data,
    concatenatedUrl: string
  ): Observable<any> {
    const schemaversion = "?$schemaversion="+this.schemaVersion
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/' +
      concatenatedUrl+schemaversion;
    return this.http.post(url, data, this.httpOptions).pipe(
      map((result) => result as any),
      catchError(this.errorService.handleError)
    );
  }

  printWorkOrderLabel(
    tenantId: any,
    companyId: string,
    workOrder: string
  ): Observable<any> {
    const url =
      this.bcCoreApiUrl +
      tenantId +
      '/' +
      this.bcEnvirName +
      '/' +
      this.customApiUrlSegment +
      '/companies(' +
      companyId +
      ')/workOrderPrintLabels(00000000-0000-0000-0000-000000000000)/Microsoft.NAV.runReportWOPrintLabelPdf?$schemaversion='+this.schemaVersion;
    const body = { workOrderNo: workOrder };
    return this.http.post(url, JSON.stringify(body), this.httpOptions).pipe(
      map((result) => result as Report),
      catchError(this.errorService.handleError)
    );
  }
}
