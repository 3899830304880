<div class="container-scroller">
  <!-- Nav bar component -->
  <app-nav-bar [BIXI_userAuthObject]="BIXI_userAuthObject" [currentProfile]="currentProfile"
    [currentUserCompany]="currentUserCompany" (changeProfileEvent)="changeProfile($event)" [equipementNo]="equipementNo"
    [pageName]="pageName" (logoutEvent)="logout()" [isUserBlocked]="isUserBlocked"></app-nav-bar>

  <div class="container-fluid page-body-wrapper add-padding">
    <!-- Side Bar component -->
    <app-side-bar [currentProfile]="currentProfile" [currentUserCompany]="currentUserCompany" [pageName]="pageName"
      [equipementNo]="equipementNo"></app-side-bar>

    <div class="main-panel" style="margin-left: 70px">
      <div class="content-wrapper p-3">
        <div class="card cardWorkOrdersGrid" #cardWorkOrdersGrid>
          <div class="text-white bg-primary card-header pt-2 pb-2">
            <h6 class="mt-1">
              <i class="fas fa-check mr-2"></i>Bons de travail à valider
            </h6>
          </div>

          <ejs-grid #workOrdersGrid [dataSource]="workOrdersData" gridLines="Horizontal" showColumnChooser="true"
            [allowSelection]="true" [selectionSettings]="selectOptions" [editSettings]="editSettings"
            [allowSorting]="true" [allowMultiSorting]="true" (toolbarClick)="toolbarClick($event)" rowHeight="30">
            <!-- <ejs-toolbar>
                          <e-items>
                            <e-item text='Nouveau'></e-item>
                            <e-item text='Afficher/Masquer' align="right"></e-item>
                          </e-items>
                        </ejs-toolbar> -->
            <e-columns>
              <!-- <e-column type='checkbox' width='50'></e-column> -->
              <e-column field="WorkOrder" [isPrimaryKey]="true" headerText="Bon de travail" width="130"
                textAlign="Left">
                <ng-template #template let-data>
                  <a rel="nofollow" style="cursor: pointer" class="text-info fw-bold" routerLink="/openworkorder">{{
                    data.no
                    }}</a>
                </ng-template>
              </e-column>
              <e-column headerText="Type" width="130" textAlign="Left">
                <ng-template #template let-data>
                  {{ data.correctiveMaintenance ? "Correctif" : "Preventif" }}
                </ng-template>
              </e-column>
              <e-column field="description" headerText="Description" width="150" textAlign="Left"></e-column>
              <e-column field="eraBixiStatus" headerText="Statut" width="150" textAlign="Left"></e-column>
              <e-column field="nextPlannedDate" headerText="Date" format="yMd" width="120"></e-column>
            </e-columns>
          </ejs-grid>
        </div>
      </div>
    </div>
  </div>
</div>

<app-linked-asset [formTitle]="'validate WO'" [isUserBlocked]="isUserBlocked"></app-linked-asset>

<div class="modal fade" id="associatedEquipTemplateModal" tabindex="-1" aria-labelledby="associatedEquipTemplateLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" style="width: 70%">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-customized-title" id="associatedEquipTemplateLabel">
          Bon du travail N° <b> {{ selectedWorEquipment }}</b>
        </h6>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body pt-2 pb-2">
        <div class="row">
          <div class="col-md-6">
            <div class="card mt-2 rounded-0 borderTemplateTable">
              <div class="card-header pb-0 pt-2 bg-light rounded-0">
                <h5 class="">Roue avant</h5>
              </div>
              <div class="card-body py-0 pl-2 pr-2">
                <div class="table-responsive">
                  <table class="table templateTable">
                    <thead>
                      <tr class="text-center">
                        <th style="width: 40%"></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="font-weight-bold">Alignement</td>
                        <td>
                          <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn">
                            <i class="fa fa-wrench fontSizeTemplateBtn"></i>
                            ajuster
                          </button>
                          <button id="eg3"
                            class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn mt-2">
                            <i class="fa fa-retweet fontSizeTemplateBtn"></i>
                            changer
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">Roue changée</td>
                        <td>
                          <button id="eg3"
                            class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn">
                            <i class="fa fa-retweet fontSizeTemplateBtn"></i>
                            changer
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">Réflecteur 1x 2x</td>
                        <td>
                          <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn">
                            <i class="fa fa-wrench fontSizeTemplateBtn"></i>
                            ajuster
                          </button>
                          <button id="eg3"
                            class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn mt-2">
                            <i class="fa fa-retweet fontSizeTemplateBtn"></i>
                            changer
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">Tube</td>
                        <td>
                          <button id="eg3"
                            class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn">
                            <i class="fa fa-retweet fontSizeTemplateBtn"></i>
                            changer
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card mt-2 rounded-0 borderTemplateTable">
              <div class="card-header pb-0 pt-2 bg-light rounded-0">
                <h5 class="">Roue arrière</h5>
              </div>
              <div class="card-body py-0 pl-2 pr-2">
                <div class="table-responsive">
                  <table class="table templateTable">
                    <thead>
                      <tr class="text-center">
                        <th style="width: 40%"></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="font-weight-bold">Alignement</td>
                        <td>
                          <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn">
                            <i class="fa fa-wrench fontSizeTemplateBtn"></i>
                            ajuster
                          </button>
                          <button id="eg3"
                            class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn mt-2">
                            <i class="fa fa-retweet fontSizeTemplateBtn"></i>
                            changer
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">Roue changée</td>
                        <td>
                          <button id="eg3"
                            class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn">
                            <i class="fa fa-retweet fontSizeTemplateBtn"></i>
                            changer
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">Réflecteur 1x 2x</td>
                        <td>
                          <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn">
                            <i class="fa fa-wrench fontSizeTemplateBtn"></i>
                            ajuster
                          </button>
                          <button id="eg3"
                            class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn mt-2">
                            <i class="fa fa-retweet fontSizeTemplateBtn"></i>
                            changer
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">Tube</td>
                        <td>
                          <button id="eg3"
                            class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn">
                            <i class="fa fa-retweet fontSizeTemplateBtn"></i>
                            changer
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-bs-dismiss="modal">
          Confirmer
        </button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          Annuler
        </button>
      </div>
    </div>
  </div>
</div>