import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AppConfig } from './app.config';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  static settings: AppConfig;

  constructor(private http: HttpClient) { }

  loadAppConfig() {
    const jsonFile = 'assets/configs/config.' + environment.name + '.json';
    return new Promise<void>((resolve, reject) => {
      const configSetting = this.http.get(jsonFile);
      lastValueFrom(configSetting).then(response=> {
          AppConfigService.settings = <AppConfig>response;
          resolve();
      }).catch((response: any) => {
        reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
      });
    });
  }
}
