<div class="container-scroller">
  <!-- Nav bar component -->
  <app-nav-bar [BIXI_userAuthObject]="BIXI_userAuthObject" [currentProfile]="currentProfile"
    [currentUserCompany]="currentUserCompany" (changeProfileEvent)="changeProfile($event)" [equipementNo]="equipementNo"
    [pageName]="pageName" (endAllTasksEvent)="endAllTasksClick()" (validateAllTasksEvent)="validateAllTasksClick()"
    (logoutEvent)="logout()" (printDetailBtEvent)="generateDetailBtPdf(false)"
    (reopenAllFinishedTasksEvent)="reopenAllFinishedTasks()" (reopenAllValidatedTasksEvent)="reopenAllValidatedTasks()"
    [events]="closeOpenWorkorderpanelEvent.asObservable()" [isUserBlocked]="isUserBlocked"
    [printLabelEquipement]="equipement" (maganePermssionEvent)="managePermissions()"></app-nav-bar>

  <div class="container-fluid page-body-wrapper add-padding">
    <!-- Side Bar component -->
    <app-side-bar (onpenEquipementAssocieEvent)="onpenEquipementAssocieModal()" [currentProfile]="currentProfile"
      [currentUserCompany]="currentUserCompany" [pageName]="pageName" [equipementNo]="equipementNo"></app-side-bar>

    <div class="main-panel" style="margin-left: 70px">
      <div class="content-wrapper p-3">
        <!-- Header -->
        <app-header *ngIf="equipement" [BIXI_userAuthObject]="BIXI_userAuthObject"
          [currentUserCompany]="currentUserCompany" (refreshEquipementEvent)="refreshEquipementEvent()"
          [equipement]="equipement" [pageName]="pageName" (sendBtReportToSharepointEvent)="generateDetailBtPdf(true)"
          (maganePermssionEvent)="managePermissions()"></app-header>

        <div *ngIf="workorderTemplate && !isBTRoue" class="row">
          <div *ngFor="
              let columnLine of workorderTemplate.columnLines;
              let i = index
            " class="col-md-4">
            <div *ngFor="let sectionLine of columnLine.sectionLines; let j = index"
              class="card mt-2 rounded-0 borderTemplateTable">
              <div class="card-header pb-0 pt-2 bg-light rounded-0">
                <h5 class="sectionLineDisplayName">
                  <!-- <i class="fas fa-table"></i> -->
                  {{ sectionLine.displayName }}
                </h5>
              </div>
              <div class="card-body pt-0 pb-0 pl-2 pr-2" style="padding: 0px !important">
                <div class="table-responsive">
                  <table class="table templateTable mt-1">
                    <thead class="">
                      <tr class="text-center">
                        <th style="width: 40%"></th>
                        <th *ngIf="
                            !sectionLine.leftLabel && !sectionLine.rightLabel
                          "></th>
                        <th *ngIf="sectionLine.leftLabel">
                          {{ sectionLine.leftLabel }}
                        </th>
                        <th *ngIf="sectionLine.rightLabel">
                          {{ sectionLine.rightLabel }}
                        </th>
                      </tr>

                      <!-- <tr class="text-center">
                    <th style="width:30%"></th>
                    <th>{{sectionLine.leftLabel}}</th>
                    <th>{{sectionLine.rightLabel}}</th>
                  </tr> -->
                    </thead>
                    <tbody>
                      <tr *ngFor="
                          let subSectionLine of sectionLine.subSectionLines;
                          let k = index
                        ">
                        <td class="font-weight-bold" style="padding-right: 0px; width: 50px">
                          <div class="ctc-1">
                            {{ subSectionLine.displayName }}
                          </div>
                        </td>
                        <td colspan="2" *ngIf="subSectionLine.position == 'Center'">
                          <div style="width: 100%" *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l1 = index
                            " class="btn-group d-flex">
                            <div style="width: 100%" class="btn-group d-flex mb-1"
                              *ngIf="taskLine.position == 'Center'">
                              <circle-progressbar (click)="processOnProgressBarClick(taskLine, 'f')"
                                [progressionValue]="getRoundedValue(taskLine.taskCategoryByDoc?.finishedRate)"
                                [progressionType]="taskRealisation"
                                [isOverconsumption]="taskLine.taskCategoryByDoc?.overconsumption"
                                [displayProgressBarCircleTitle]="k == 0 && l1 == 0 ? true : false"
                                [disableProgressBar]="disableProgressBarFinish || sameTasks?.[taskLine.systemId]?.workOrdersNo?.length > 1 ? true : false"></circle-progressbar>
                              <circle-progressbar (click)="
                                  processOnProgressBarClick(taskLine, 'v')
                                " [progressionValue]="
                                getRoundedValue(taskLine.taskCategoryByDoc?.approuvedRate)
                                " [progressionType]="taskValidation" [isOverconsumption]="
                                  taskLine.taskCategoryByDoc?.overconsumption
                                " [displayProgressBarCircleTitle]="
                                  k == 0 && l1 == 0 ? true : false
                                "
                                [disableProgressBar]="disableProgressBarApprouve || sameTasks?.[taskLine.systemId]?.workOrdersNo?.length > 1 ? true : false"></circle-progressbar>
                              <button
                                class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style add-large-width"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'}"
                                [disabled]="isTaskBtnDisabled(taskLine.taskCategoryByDoc !== undefined
                                      ? { data: 'taskCategoryByDoc',object: taskLine}: { data: 'taskLine', object: taskLine })" (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                taskLine.taskCategoryByDoc !== undefined
                                ? "(" +
                                taskLine.taskCategoryByDoc.multiple +
                                ")"
                                : ""
                                }}
                                <i class=" fontSizeTemplateBtn fa fa-star {{
                                    colorDefaultTaskIcon_Fr_Fr_A_G
                                  }}"></i>
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                i,
                                j,
                                k,
                                l1
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ i }}_{{ j }}_{{ k }}_{{
                                    l1
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                              <button (click)="onTaskDetailButtonClick(taskLine, false)" [disabled]="
                                  taskLine.taskCategoryByDoc != undefined
                                    ? false
                                    : true
                                " style="border-left: 1px solid gray" class="btn btn-sm p-1 borderTemplateBtn fontSizeTemplateBtn {{
                                  isTaskSelected_Fr_Fr_A_G
                                }} task-right-left-button-style"
                                [ngClass]="sameTasks?.[taskLine.systemId]?.workOrdersNo?.length > 1 ? 'btn-danger' : 'btn-light'"
                                data-bs-whatever="Ajuster freins avant">
                                <i class="fa fa-list-ul fontSizeTemplateBtn"></i>
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l2 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Left'">
                              <circle-progressbar (click)="
                                  processOnProgressBarClick(taskLine, 'f')
                                " [progressionValue]="
                                getRoundedValue(taskLine.taskCategoryByDoc?.finishedRate)
                                " [progressionType]="taskRealisation" [isOverconsumption]="
                                  taskLine.taskCategoryByDoc?.overconsumption
                                " [displayProgressBarCircleTitle]="
                                  k == 0 && l2 == 0 ? true : false
                                "
                                [disableProgressBar]="disableProgressBarFinish || sameTasks?.[taskLine.systemId]?.workOrdersNo?.length > 1 ? true : false"></circle-progressbar>
                              <circle-progressbar (click)="
                                  processOnProgressBarClick(taskLine, 'v')
                                " [progressionValue]="
                                getRoundedValue(taskLine.taskCategoryByDoc?.approuvedRate)
                                " [progressionType]="taskValidation" [isOverconsumption]="
                                  taskLine.taskCategoryByDoc?.overconsumption
                                " [displayProgressBarCircleTitle]="
                                  k == 0 && l2 == 0 ? true : false
                                "
                                [disableProgressBar]="disableProgressBarApprouve || sameTasks?.[taskLine.systemId]?.workOrdersNo?.length > 1 ? true : false"></circle-progressbar>

                              <button
                                class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style add-width-to-button"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }"
                                [disabled]="isTaskBtnDisabled(taskLine.taskCategoryByDoc !== undefined
                                      ? { data: 'taskCategoryByDoc',object: taskLine}: { data: 'taskLine', object: taskLine })"
                                (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                taskLine.taskCategoryByDoc !== undefined
                                ? "(" +
                                taskLine.taskCategoryByDoc.multiple +
                                ")"
                                : ""
                                }}
                                <i class=" fontSizeTemplateBtn fa fa-star {{
                                    colorDefaultTaskIcon_Fr_Fr_A_G
                                  }}"></i>
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                i,
                                j,
                                k,
                                l2
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ i }}_{{ j }}_{{ k }}_{{
                                    l2
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                              <button (click)="onTaskDetailButtonClick(taskLine, false)" [disabled]="
                                  taskLine.taskCategoryByDoc != undefined
                                    ? false
                                    : true
                                " style="border-left: 1px solid gray" class="btn btn-sm p-1 borderTemplateBtn fontSizeTemplateBtn {{
                                  isTaskSelected_Fr_Fr_A_G
                                }} task-right-left-button-style"
                                [ngClass]="sameTasks?.[taskLine.systemId]?.workOrdersNo?.length > 1 ? 'btn-danger' : 'btn-light'"
                                data-bs-whatever="Ajuster freins avant
                              ">
                                <i class="fa fa-list-ul fontSizeTemplateBtn"></i>
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.rightLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.rightLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l3 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Right'">
                              <circle-progressbar (click)="
                                  processOnProgressBarClick(taskLine, 'f')
                                " [progressionValue]="
                                getRoundedValue(taskLine.taskCategoryByDoc?.finishedRate)
                                " [progressionType]="taskRealisation" [isOverconsumption]="
                                  taskLine.taskCategoryByDoc?.overconsumption
                                " [displayProgressBarCircleTitle]="
                                  k == 0 && l3 == 1 ? true : false
                                "
                                [disableProgressBar]="disableProgressBarFinish || sameTasks?.[taskLine.systemId]?.workOrdersNo?.length > 1 ? true : false"></circle-progressbar>
                              <circle-progressbar (click)="
                                  processOnProgressBarClick(taskLine, 'v')
                                " [progressionValue]="
                                getRoundedValue(taskLine.taskCategoryByDoc?.approuvedRate)
                                " [progressionType]="taskValidation" [isOverconsumption]="
                                  taskLine.taskCategoryByDoc?.overconsumption
                                " [displayProgressBarCircleTitle]="
                                  k == 0 && l3 == 1 ? true : false
                                "
                                [disableProgressBar]="disableProgressBarApprouve || sameTasks?.[taskLine.systemId]?.workOrdersNo?.length > 1 ? true : false"></circle-progressbar>
                              <button
                                class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style add-width-to-button"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }"
                                [disabled]="isTaskBtnDisabled(taskLine.taskCategoryByDoc !== undefined
                                      ? { data: 'taskCategoryByDoc',object: taskLine}: { data: 'taskLine', object: taskLine })"
                                (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                taskLine.taskCategoryByDoc !== undefined
                                ? "(" +
                                taskLine.taskCategoryByDoc.multiple +
                                ")"
                                : ""
                                }}
                                <i class=" fontSizeTemplateBtn fa fa-star {{
                                    colorDefaultTaskIcon_Fr_Fr_A_G
                                  }}"></i>
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                i,
                                j,
                                k,
                                l3
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ i }}_{{ j }}_{{ k }}_{{
                                    l3
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                              <button (click)="onTaskDetailButtonClick(taskLine, false)" [disabled]="
                                  taskLine.taskCategoryByDoc != undefined
                                    ? false
                                    : true
                                " style="border-left: 1px solid gray" class="btn btn-sm p-1 borderTemplateBtn fontSizeTemplateBtn {{
                                  isTaskSelected_Fr_Fr_A_G
                                }} task-right-left-button-style"
                                [ngClass]="sameTasks?.[taskLine.systemId]?.workOrdersNo?.length > 1 ? 'btn-danger' : 'btn-light'"
                                data-bs-whatever="Ajuster freins avant">
                                <i class="fa fa-list-ul fontSizeTemplateBtn"></i>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Template de la roue-->
        <div *ngIf="workorderTemplate && isBTRoue" class="row">
          <div class="col-md-4">
            <div *ngFor="
                let sectionLine of workorderTemplate.columnLines[
                  templateSideIndex
                ].sectionLines;
                let j = index
              " class="card mt-2 rounded-0 borderTemplateTable">
              <div class="card-header pb-0 pt-2 bg-light rounded-0">
                <h5 class="sectionLineDisplayName">
                  <!-- <i class="fas fa-table"></i> -->
                  {{ sectionLine.displayName }}
                </h5>
              </div>
              <div class="card-body pt-0 pb-0 pl-2 pr-2">
                <div class="table-responsive">
                  <table class="table templateTable">
                    <thead>
                      <tr class="text-center">
                        <th style="width: 40%"></th>
                        <th *ngIf="
                            !sectionLine.leftLabel && !sectionLine.rightLabel
                          "></th>
                        <th *ngIf="sectionLine.leftLabel">
                          {{ sectionLine.leftLabel }}
                        </th>
                        <th *ngIf="sectionLine.rightLabel">
                          {{ sectionLine.rightLabel }}
                        </th>
                      </tr>

                      <!-- <tr class="text-center">
                  <th style="width:30%"></th>
                  <th>{{sectionLine.leftLabel}}</th>
                  <th>{{sectionLine.rightLabel}}</th>
                </tr> -->
                    </thead>
                    <tbody>
                      <tr *ngFor="
                          let subSectionLine of sectionLine.subSectionLines;
                          let k = index
                        ">
                        <td class="font-weight-bold" style="padding-right: 0px; width: 50px">
                          <div class="ctc-1">
                            {{ subSectionLine.displayName }}
                          </div>
                        </td>
                        <td colspan="2" *ngIf="subSectionLine.position == 'Center'">
                          <div style="width: 100%" *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l1 = index
                            " class="btn-group d-flex">
                            <div style="width: 100%" class="btn-group d-flex mb-1"
                              *ngIf="taskLine.position == 'Center'">
                              <circle-progressbar (click)="
                                  processOnProgressBarClick(taskLine, 'f')
                                " [progressionValue]="
                                getRoundedValue(taskLine.taskCategoryByDoc?.finishedRate)
                                " [progressionType]="taskRealisation" [isOverconsumption]="
                                  taskLine.taskCategoryByDoc?.overconsumption
                                " [displayProgressBarCircleTitle]="
                                  k == 0 && l1 == 0 ? true : false
                                "
                                [disableProgressBar]="disableProgressBarFinish || sameTasks?.[taskLine.systemId]?.workOrdersNo?.length > 1 ? true : false"></circle-progressbar>
                              <circle-progressbar (click)="
                                  processOnProgressBarClick(taskLine, 'v')
                                " [progressionValue]="
                                getRoundedValue(taskLine.taskCategoryByDoc?.approuvedRate)
                                " [progressionType]="taskValidation" [isOverconsumption]="
                                  taskLine.taskCategoryByDoc?.overconsumption
                                " [displayProgressBarCircleTitle]="
                                  k == 0 && l1 == 0 ? true : false
                                "
                                [disableProgressBar]="disableProgressBarApprouve || sameTasks?.[taskLine.systemId]?.workOrdersNo?.length > 1 ? true : false"></circle-progressbar>
                              <button
                                class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style add-large-width"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }"
                                [disabled]="isTaskBtnDisabled(taskLine.taskCategoryByDoc !== undefined
                                      ? { data: 'taskCategoryByDoc',object: taskLine}: { data: 'taskLine', object: taskLine })"
                                (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                taskLine.taskCategoryByDoc !== undefined
                                ? "(" +
                                taskLine.taskCategoryByDoc.multiple +
                                ")"
                                : ""
                                }}
                                <i class=" fontSizeTemplateBtn fa fa-star {{
                                    colorDefaultTaskIcon_Fr_Fr_A_G
                                  }}"></i>
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                0,
                                j,
                                k,
                                l1
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ 0 }}_{{ j }}_{{ k }}_{{
                                    l1
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                              <button (click)="onTaskDetailButtonClick(taskLine, false)" [disabled]="
                                  taskLine.taskCategoryByDoc != undefined
                                    ? false
                                    : true
                                " style="border-left: 1px solid gray" class="btn btn-sm p-1 borderTemplateBtn fontSizeTemplateBtn {{
                                  isTaskSelected_Fr_Fr_A_G
                                }} task-right-left-button-style"
                                [ngClass]="sameTasks?.[taskLine.systemId]?.workOrdersNo?.length > 1 ? 'btn-danger' : 'btn-light'"
                                data-bs-whatever="Ajuster freins avant">
                                <i class="fa fa-list-ul fontSizeTemplateBtn"></i>
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l2 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Left'">
                              <circle-progressbar (click)="
                                  processOnProgressBarClick(taskLine, 'f')
                                " [progressionValue]="
                                getRoundedValue(taskLine.taskCategoryByDoc?.finishedRate)
                                " [progressionType]="taskRealisation" [isOverconsumption]="
                                  taskLine.taskCategoryByDoc?.overconsumption
                                " [displayProgressBarCircleTitle]="
                                  k == 0 && l2 == 0 ? true : false
                                "
                                [disableProgressBar]="disableProgressBarFinish || sameTasks?.[taskLine.systemId]?.workOrdersNo?.length > 1 ? true : false"></circle-progressbar>
                              <circle-progressbar (click)="
                                  processOnProgressBarClick(taskLine, 'v')
                                " [progressionValue]="
                                getRoundedValue(taskLine.taskCategoryByDoc?.approuvedRate)
                                " [progressionType]="taskValidation" [isOverconsumption]="
                                  taskLine.taskCategoryByDoc?.overconsumption
                                " [displayProgressBarCircleTitle]="
                                  k == 0 && l2 == 0 ? true : false
                                "
                                [disableProgressBar]="disableProgressBarApprouve || sameTasks?.[taskLine.systemId]?.workOrdersNo?.length > 1 ? true : false"></circle-progressbar>
                              <button
                                class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style add-width-to-button"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }"
                                [disabled]="isTaskBtnDisabled(taskLine.taskCategoryByDoc !== undefined
                                      ? { data: 'taskCategoryByDoc',object: taskLine}: { data: 'taskLine', object: taskLine })"
                                (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                taskLine.taskCategoryByDoc !== undefined
                                ? "(" +
                                taskLine.taskCategoryByDoc.multiple +
                                ")"
                                : ""
                                }}
                                <i class=" fontSizeTemplateBtn fa fa-star {{
                                    colorDefaultTaskIcon_Fr_Fr_A_G
                                  }}"></i>
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                0,
                                j,
                                k,
                                l2
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ 0 }}_{{ j }}_{{ k }}_{{
                                    l2
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                              <button (click)="onTaskDetailButtonClick(taskLine, false)" [disabled]="
                                  taskLine.taskCategoryByDoc != undefined
                                    ? false
                                    : true
                                " style="border-left: 1px solid gray" class="btn btn-sm p-1 borderTemplateBtn fontSizeTemplateBtn {{
                                  isTaskSelected_Fr_Fr_A_G
                                }} task-right-left-button-style"
                                [ngClass]="sameTasks?.[taskLine.systemId]?.workOrdersNo?.length > 1 ? 'btn-danger' : 'btn-light'"
                                data-bs-whatever="Ajuster freins avant
                            ">
                                <i class="fa fa-list-ul fontSizeTemplateBtn"></i>
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.rightLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.rightLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l3 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Right'">
                              <circle-progressbar (click)="
                                  processOnProgressBarClick(taskLine, 'f')
                                " [progressionValue]="
                                getRoundedValue(taskLine.taskCategoryByDoc?.finishedRate)
                                " [progressionType]="taskRealisation" [isOverconsumption]="
                                  taskLine.taskCategoryByDoc?.overconsumption
                                " [displayProgressBarCircleTitle]="
                                  k == 0 && l3 == 1 ? true : false
                                "
                                [disableProgressBar]="disableProgressBarFinish || sameTasks?.[taskLine.systemId]?.workOrdersNo?.length > 1 ? true : false"></circle-progressbar>
                              <circle-progressbar (click)="
                                  processOnProgressBarClick(taskLine, 'v')
                                " [progressionValue]="
                                getRoundedValue(taskLine.taskCategoryByDoc?.approuvedRate)
                                " [progressionType]="taskValidation" [isOverconsumption]="
                                  taskLine.taskCategoryByDoc?.overconsumption
                                " [displayProgressBarCircleTitle]="
                                  k == 0 && l3 == 1 ? true : false
                                "
                                [disableProgressBar]="disableProgressBarApprouve || sameTasks?.[taskLine.systemId]?.workOrdersNo?.length > 1 ? true : false"></circle-progressbar>
                              <button
                                class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style add-width-to-button"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }"
                                [disabled]="isTaskBtnDisabled(taskLine.taskCategoryByDoc !== undefined
                                      ? { data: 'taskCategoryByDoc',object: taskLine}: { data: 'taskLine', object: taskLine })"
                                (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                taskLine.taskCategoryByDoc !== undefined
                                ? "(" +
                                taskLine.taskCategoryByDoc.multiple +
                                ")"
                                : ""
                                }}
                                <i class=" fontSizeTemplateBtn fa fa-star {{
                                    colorDefaultTaskIcon_Fr_Fr_A_G
                                  }}"></i>
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                0,
                                j,
                                k,
                                l3
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ 0 }}_{{ j }}_{{ k }}_{{
                                    l3
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                              <button (click)="onTaskDetailButtonClick(taskLine, false)" [disabled]="
                                  taskLine.taskCategoryByDoc != undefined
                                    ? false
                                    : true
                                " style="border-left: 1px solid gray" class="btn btn-sm p-1 borderTemplateBtn fontSizeTemplateBtn {{
                                  isTaskSelected_Fr_Fr_A_G
                                }} task-right-left-button-style"
                                [ngClass]="sameTasks?.[taskLine.systemId]?.workOrdersNo?.length > 1 ? 'btn-danger' : 'btn-light'"
                                data-bs-whatever="Ajuster freins avant">
                                <i class="fa fa-list-ul fontSizeTemplateBtn"></i>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-linked-asset *ngIf="equipement" [formTitle]="'detail WO'" (confirmeCreateWoEquipEvent)="createNewWorkOrder($event)"
  [equipement]="equipement" [events]="refreshAssetLinkEvent.asObservable()"
  [isUserBlocked]="isUserBlocked"></app-linked-asset>

<app-consumption-track [consumptionTrackData]="consumptionTrackData"></app-consumption-track>

<div class="modal fade" id="associatedEquipTemplateModal" tabindex="-1" aria-labelledby="associatedEquipTemplateLabel"
  aria-hidden="true">
  <div *ngIf="roueWorkorder" class="modal-dialog modal-lg"
    [ngStyle]="{ width: roueWorkorder.isBtRoue ? '40%' : '70%' }">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 *ngIf="roueWorkorder !== null" class="modal-title text-primary modal-customized-title"
          id="associatedEquipTemplateLabel">
          <i class="fa fa-cog mr-2"></i>
          <span class="fw-bold">Bon de travail N° <span class="text-danger">{{ roueWorkorder.no }}</span></span>
        </h6>
        <button type="button" class="btn btn-danger btn-sm my-1" data-bs-dismiss="modal" aria-label="Close">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body pt-2 pb-2">
        <div *ngIf="templateRoue && !roueWorkorder.isBtRoue" class="row">
          <div *ngFor="let columnLine of templateRoue.columnLines; let i = index" class="col-md-4">
            <div *ngFor="let sectionLine of columnLine.sectionLines; let j = index"
              class="card mt-2 rounded-0 borderTemplateTable">
              <div class="card-header pb-0 pt-2 bg-light rounded-0">
                <h5 class="sectionLineDisplayName">
                  <!-- <i class="fas fa-table"></i> -->
                  {{ sectionLine.displayName }}
                </h5>
              </div>
              <div class="card-body pt-0 pb-0 pl-2 pr-2" style="padding: 0px !important">
                <div class="table-responsive">
                  <table class="table templateTable">
                    <thead>
                      <tr class="text-center">
                        <th style="width: 40%"></th>
                        <th *ngIf="
                            !sectionLine.leftLabel && !sectionLine.rightLabel
                          "></th>
                        <th *ngIf="sectionLine.leftLabel">
                          {{ sectionLine.leftLabel }}
                        </th>
                        <th *ngIf="sectionLine.rightLabel">
                          {{ sectionLine.rightLabel }}
                        </th>
                      </tr>

                      <!-- <tr class="text-center">
                      <th style="width:30%"></th>
                      <th>{{sectionLine.leftLabel}}</th>
                      <th>{{sectionLine.rightLabel}}</th>
                    </tr> -->
                    </thead>
                    <tbody>
                      <tr *ngFor="
                          let subSectionLine of sectionLine.subSectionLines;
                          let k = index
                        ">
                        <td class="font-weight-bold" style="padding-right: 0px; width: 50px">
                          <div class="ctc-1">
                            {{ subSectionLine.displayName }}
                          </div>
                        </td>
                        <td colspan="2" *ngIf="subSectionLine.position == 'Center'">
                          <div style="width: 100%" *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l1 = index
                            " class="btn-group d-flex">
                            <div style="width: 100%" class="btn-group d-flex mb-1"
                              *ngIf="taskLine.position == 'Center'">
                              <button
                                class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style add-large-width"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }"
                                [disabled]="isTaskBtnDisabled(taskLine.taskCategoryByDoc !== undefined
                                      ? { data: 'taskCategoryByDoc',object: taskLine}: { data: 'taskLine', object: taskLine })"
                                (click)="
                                  popup_BtUpdateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                taskLine.taskCategoryByDoc !== undefined
                                ? "(" +
                                taskLine.taskCategoryByDoc.multiple +
                                ")"
                                : ""
                                }}
                                <i class=" fontSizeTemplateBtn fa fa-star {{
                                    colorDefaultTaskIcon_Fr_Fr_A_G
                                  }}"></i>
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                i,
                                j,
                                k,
                                l1
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ i }}_{{ j }}_{{ k }}_{{
                                    l1
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l2 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Left'">
                              <button
                                class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style add-width-to-button"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }"
                                [disabled]="isTaskBtnDisabled(taskLine.taskCategoryByDoc !== undefined
                                      ? { data: 'taskCategoryByDoc',object: taskLine}: { data: 'taskLine', object: taskLine })"
                                (click)="
                                  popup_BtUpdateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                taskLine.taskCategoryByDoc !== undefined
                                ? "(" +
                                taskLine.taskCategoryByDoc.multiple +
                                ")"
                                : ""
                                }}
                                <i class=" fontSizeTemplateBtn fa fa-star {{
                                    colorDefaultTaskIcon_Fr_Fr_A_G
                                  }}"></i>
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                i,
                                j,
                                k,
                                l2
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ i }}_{{ j }}_{{ k }}_{{
                                    l2
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.rightLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.rightLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l3 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Right'">
                              <button
                                class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style add-width-to-button"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }"
                                [disabled]="isTaskBtnDisabled(taskLine.taskCategoryByDoc !== undefined
                                      ? { data: 'taskCategoryByDoc',object: taskLine}: { data: 'taskLine', object: taskLine })"
                                (click)="
                                  popup_BtUpdateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                taskLine.taskCategoryByDoc !== undefined
                                ? "(" +
                                taskLine.taskCategoryByDoc.multiple +
                                ")"
                                : ""
                                }}
                                <i class=" fontSizeTemplateBtn fa fa-star {{
                                    colorDefaultTaskIcon_Fr_Fr_A_G
                                  }}"></i>
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                i,
                                j,
                                k,
                                l3
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ i }}_{{ j }}_{{ k }}_{{
                                    l3
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="templateRoue && roueWorkorder.isBtRoue" class="row">
          <div class="col-md-12">
            <div *ngFor="
                let sectionLine of templateRoue.columnLines[templateSideIndex]
                  .sectionLines;
                let j = index
              " class="card mt-2 rounded-0 borderTemplateTable">
              <div class="card-header pb-0 pt-2 bg-light rounded-0">
                <h5 class="">
                  {{ sectionLine.displayName }}
                </h5>
              </div>
              <div class="card-body py-0 pl-2 pr-2">
                <div class="table-responsive">
                  <table class="table templateTable">
                    <thead>
                      <tr class="text-center">
                        <th style="width: 40%"></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="
                          let subSectionLine of sectionLine.subSectionLines;
                          let k = index
                        ">
                        <td class="font-weight-bold">
                          {{ subSectionLine.displayName }}
                        </td>
                        <td colspan="2" *ngIf="subSectionLine.position == 'Center'">
                          <div style="width: 100%" *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l1 = index
                            " class="btn-group d-flex">
                            <div style="width: 100%" class="btn-group d-flex mb-1"
                              *ngIf="taskLine.position == 'Center'">
                              <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }"
                                [disabled]="isTaskBtnDisabled(taskLine.taskCategoryByDoc !== undefined
                                      ? { data: 'taskCategoryByDoc',object: taskLine}: { data: 'taskLine', object: taskLine })"
                                (click)="
                                  popup_BtUpdateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                0,
                                j,
                                k,
                                l1
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ 0 }}_{{ j }}_{{ k }}_{{
                                    l1
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l2 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Left'">
                              <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }"
                                [disabled]="isTaskBtnDisabled(taskLine.taskCategoryByDoc !== undefined
                                      ? { data: 'taskCategoryByDoc',object: taskLine}: { data: 'taskLine', object: taskLine })"
                                (click)="
                                  popup_BtUpdateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                0,
                                j,
                                k,
                                l2
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ 0 }}_{{ j }}_{{ k }}_{{
                                    l2
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l3 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Right'">
                              <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }"
                                [disabled]="isTaskBtnDisabled(taskLine.taskCategoryByDoc !== undefined
                                      ? { data: 'taskCategoryByDoc',object: taskLine}: { data: 'taskLine', object: taskLine })"
                                (click)="
                                  popup_BtUpdateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                0,
                                j,
                                k,
                                l3
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ 0 }}_{{ j }}_{{ k }}_{{
                                    l3
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer px-0">
          <button type="button" (click)="onBtClose()" class="btn btn-primary btn-sm" data-bs-dismiss="modal">
            <i class="fa fa-check mr-2"></i>Continuer
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-detail-tache-modal (refreshGetEquipementEvent)="refreshGetEquipement()" [BIXI_userAuthObject]="BIXI_userAuthObject"
  [currentProfile]="currentProfile" [currentProfiles]="currentProfiles" [currentUserCompany]="currentUserCompany"
  [BTandLines]="BTandLines" [openDetailTacheModalEvent]="openDetailTacheModalEvent"
  [populateExpensesArrayEvent]="populateExpensesArrayEvent" [populateItemsArrayEvent]="populateItemsArrayEvent"
  [sameTasks]="sameTasks" [currentWorkorder]="currentWorkorder" [itemsList]="itemsList" [expensesList]="expensesList"
  [allItemVariants]="allItemVariants" [editWorkOrderFeatures]="editWorkOrderFeatures"
  [expenseWorkOrderLines]="expenseWorkOrderLines" [itemWorkOrderLines]="itemWorkOrderLines"
  [onSelectedAllFinishedClickEvent]="onSelectedAllFinishedClickEvent"
  [onReopenGeneralClickEvent]="onReopenGeneralClickEvent"
  [onSelectedAllValidateClickEvent]="onSelectedAllValidateClickEvent" [unitsOfMeasureList]="unitsOfMeasureList"
  [locationsList]="locationsList" [bikePartsData]="bikePartsData"
  [isUserBlocked]="isUserBlocked"></app-detail-tache-modal>

<div class="modal fade" id="btListModal" tabindex="-1" aria-labelledby="btListModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="width: 70%">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-customized-title" id="btListModalLabel">
          Associer un bon de travail
        </h6>
        <button type="button" class="btn-close" data-bs-toggle="modal" data-bs-target="#actionDetailModal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="card mt-2">
          <div class="card-header pt-2 bg-light rounded-0">
            <h5 class="">Bons de travail en cours</h5>
            <ejs-grid #validateAlldepGrid [dataSource]="currentWoData" gridLines="Horizontal" showColumnChooser="false"
              [allowSelection]="true" [allowSorting]="true" rowHeight="30">
              <e-columns>
                <e-column field="NumWo" headerText="Bon de travail" width="150" textAlign="Left"></e-column>
                <e-column field="Type" headerText="Type" width="130" textAlign="Left"></e-column>
                <e-column field="Description" headerText="Description" width="120" textAlign="Left"></e-column>
                <e-column field="Status" headerText="Statut" width="120" textAlign="Left"></e-column>
              </e-columns>
            </ejs-grid>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-header pt-2 bg-light rounded-0">
            <h5 class="">Bons de travail ouverts</h5>
            <ejs-grid #validateAllEquipGrid [dataSource]="workOrdersData" gridLines="Horizontal"
              showColumnChooser="false" [allowSelection]="true" [allowSorting]="true" rowHeight="30">
              <e-columns>
                <e-column field="WorkOrder" headerText="Bon de travail" width="130" textAlign="Left">
                  <!-- <ng-template #template let-data>
                  <a rel='nofollow' style="cursor:pointer" class="text-info"
                    (click)="goReadOnlyToTemplate()">{{data.WorkOrder}}</a>
                </ng-template> -->
                </e-column>
                <e-column field="Category" headerText="Type" width="130" textAlign="Left"></e-column>
                <e-column field="Revision" headerText="Description" width="150" textAlign="Left"></e-column>
                <e-column field="State" headerText="Statut" width="150" textAlign="Left"></e-column>
                <e-column field="Date" headerText="Date" format="yMd" width="120"></e-column>
              </e-columns>
            </ejs-grid>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#actionDetailModal"
          (click)="associateBtToArticles()">
          Ajouter
        </button>
        <button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#actionDetailModal">
          Annuler
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="popup_BtMulitipeValueTaskLineModal" tabindex="-1"
  aria-labelledby="popup_BtMulitipeValueTaskLineModal" aria-hidden="true" data-bs-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-customized-title" id="updateItemsModal">
          <i class="fa fa-cog mr-2"></i>
          <span class="fw-bold">Paramètre de tâche</span>
        </h6>
      </div>
      <div class="modal-body pt-2 pb-2">
        <div class="row mt-2">
          <div class="grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="forms-sample">
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Min</label>
                    <div class="col-sm-7">
                      <input *ngIf="EObjectToUpdate !== null" type="number" class="form-control form-control-sm py-1"
                        [value]="EObjectToUpdate.object.fieldMINValue" [disabled]="true" />
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Max</label>
                    <div class="col-sm-7">
                      <input *ngIf="EObjectToUpdate !== null" type="number" class="form-control form-control-sm py-1"
                        [value]="EObjectToUpdate.object.fieldMAXValue" [disabled]="true" />
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Multiplicateur</label>
                    <div class="col-sm-7">
                      <input type="number" name="multiplicateur" #multiplicateur="ngModel"
                        [(ngModel)]="taskMultipleValue" required
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                        class="form-control form-control-sm py-1" autofocus="autofocus" #multiplicateurElement />
                      <div class="alert alert-danger input-error-style" *ngIf="multiplicateur.errors?.['required']">
                        Le multiplicateur est requis
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="">
          <button type="button" class="btn btn-primary btn-sm mr-2" data-bs-dismiss="modal"
            (click)="popup_BtAddTaskByMultipleValue()" [disabled]="multiplicateur.errors?.['required']">
            <i class="fa fa-plus mr-2"></i>Ajouter la tâche
          </button>
          <button class="btn btn-light btn-sm" (click)="onMultipleTaskCancel()" data-bs-dismiss="modal">
            <i class="fa fa-times mr-2"></i>Annuler
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="mulitipeValueTaskLineModal" tabindex="-1" aria-labelledby="mulitipeValueTaskLineModal"
  aria-hidden="true" data-bs-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-customized-title" id="updateItemsModal">
          <i class="fa fa-cog mr-2"></i>
          <span class="fw-bold">Paramètre de tâche</span>
        </h6>
      </div>
      <div class="modal-body pt-2 pb-2">
        <div class="row mt-2">
          <div class="grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="forms-sample">
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Min</label>
                    <div class="col-sm-7">
                      <input *ngIf="EObjectToUpdate !== null" type="number" class="form-control form-control-sm py-1"
                        [value]="EObjectToUpdate.object.fieldMINValue" [disabled]="true" />
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Max</label>
                    <div class="col-sm-7">
                      <input *ngIf="EObjectToUpdate !== null" type="number" class="form-control form-control-sm py-1"
                        [value]="EObjectToUpdate.object.fieldMAXValue" [disabled]="true" />
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Multiplicateur</label>
                    <div class="col-sm-7">
                      <input type="number" name="multiplicateur" #multiplicateur="ngModel"
                        [(ngModel)]="taskMultipleValue" required
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                        class="form-control form-control-sm py-1" autofocus="autofocus" #multiplicateurElement />
                      <div class="alert alert-danger input-error-style" *ngIf="multiplicateur.errors?.['required']">
                        Le multiplicateur est requis
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="">
          <button type="button" class="btn btn-primary btn-sm mr-2" data-bs-dismiss="modal"
            (click)="addTaskByMultipleValue()" [disabled]="multiplicateur.errors?.['required']">
            <i class="fa fa-plus mr-2"></i>Ajouter la tâche
          </button>
          <button class="btn btn-light btn-sm" data-bs-dismiss="modal"><i class="fa fa-times mr-2"></i>Annuler</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="associatedEquipTemplateModal" tabindex="-1" aria-labelledby="associatedEquipTemplateLabel"
  aria-hidden="true">
  <div *ngIf="roueWorkorder" class="modal-dialog modal-lg"
    [ngStyle]="{ width: roueWorkorder.isBtRoue ? '40%' : '70%' }">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 *ngIf="roueWorkorder !== null" class="modal-title text-primary modal-customized-title"
          id="associatedEquipTemplateLabel">
          <i class="fa fa-cog mr-2"></i>
          <span class="fw-bold">Bon de travail N° <span class="text-danger">{{ roueWorkorder.no }}</span></span>
        </h6>
        <button type="button" class="btn btn-danger btn-sm mr-2" data-bs-dismiss="modal" aria-label="Close">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body pt-2 pb-2">
        <div *ngIf="templateRoue && !roueWorkorder.isBtRoue" class="row">
          <div *ngFor="let columnLine of templateRoue.columnLines; let i = index" class="col-md-4">
            <div *ngFor="let sectionLine of columnLine.sectionLines; let j = index"
              class="card mt-2 rounded-0 borderTemplateTable">
              <div class="card-header pb-0 pt-2 bg-light rounded-0">
                <h5 class="sectionLineDisplayName">
                  <!-- <i class="fas fa-table"></i> -->
                  {{ sectionLine.displayName }}
                </h5>
              </div>
              <div class="card-body pt-0 pb-0 pl-2 pr-2" style="padding: 0px !important">
                <div class="table-responsive">
                  <table class="table templateTable">
                    <thead>
                      <tr class="text-center">
                        <th style="width: 40%"></th>
                        <th *ngIf="
                            !sectionLine.leftLabel && !sectionLine.rightLabel
                          "></th>
                        <th *ngIf="sectionLine.leftLabel">
                          {{ sectionLine.leftLabel }}
                        </th>
                        <th *ngIf="sectionLine.rightLabel">
                          {{ sectionLine.rightLabel }}
                        </th>
                      </tr>

                      <!-- <tr class="text-center">
                  <th style="width:30%"></th>
                  <th>{{sectionLine.leftLabel}}</th>
                  <th>{{sectionLine.rightLabel}}</th>
                </tr> -->
                    </thead>
                    <tbody>
                      <tr *ngFor="
                          let subSectionLine of sectionLine.subSectionLines;
                          let k = index
                        ">
                        <td class="font-weight-bold" style="padding-right: 0px; width: 50px">
                          <div class="ctc-1">
                            {{ subSectionLine.displayName }}
                          </div>
                        </td>
                        <td colspan="2" *ngIf="subSectionLine.position == 'Center'">
                          <div style="width: 100%" *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l1 = index
                            " class="btn-group d-flex">
                            <div style="width: 100%" class="btn-group d-flex mb-1"
                              *ngIf="taskLine.position == 'Center'">
                              <button
                                class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style add-large-width"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }" (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                taskLine.taskCategoryByDoc !== undefined
                                ? "(" +
                                taskLine.taskCategoryByDoc.multiple +
                                ")"
                                : ""
                                }}
                                <i class=" fontSizeTemplateBtn fa fa-star {{
                                    colorDefaultTaskIcon_Fr_Fr_A_G
                                  }}"></i>
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                i,
                                j,
                                k,
                                l1
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ i }}_{{ j }}_{{ k }}_{{
                                    l1
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l2 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Left'">
                              <button
                                class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style add-width-to-button"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }" (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                taskLine.taskCategoryByDoc !== undefined
                                ? "(" +
                                taskLine.taskCategoryByDoc.multiple +
                                ")"
                                : ""
                                }}
                                <i class=" fontSizeTemplateBtn fa fa-star {{
                                    colorDefaultTaskIcon_Fr_Fr_A_G
                                  }}"></i>
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                i,
                                j,
                                k,
                                l2
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ i }}_{{ j }}_{{ k }}_{{
                                    l2
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.rightLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.rightLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l3 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Right'">
                              <button
                                class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style add-width-to-button"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }" (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                taskLine.taskCategoryByDoc !== undefined
                                ? "(" +
                                taskLine.taskCategoryByDoc.multiple +
                                ")"
                                : ""
                                }}
                                <i class=" fontSizeTemplateBtn fa fa-star {{
                                    colorDefaultTaskIcon_Fr_Fr_A_G
                                  }}"></i>
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                i,
                                j,
                                k,
                                l3
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ i }}_{{ j }}_{{ k }}_{{
                                    l3
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="templateRoue && roueWorkorder.isBtRoue" class="row">
          <div class="col-md-12">
            <div *ngFor="
                let sectionLine of templateRoue.columnLines[templateSideIndex]
                  .sectionLines;
                let j = index
              " class="card mt-2 rounded-0 borderTemplateTable">
              <div class="card-header pb-0 pt-2 bg-light rounded-0">
                <h5 class="">
                  {{ sectionLine.displayName }}
                </h5>
              </div>
              <div class="card-body py-0 pl-2 pr-2">
                <div class="table-responsive">
                  <table class="table templateTable">
                    <thead>
                      <tr class="text-center">
                        <th style="width: 40%"></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="
                          let subSectionLine of sectionLine.subSectionLines;
                          let k = index
                        ">
                        <td class="font-weight-bold" style="padding-right: 0px; width: 50px">
                          <div class="ctc-1">
                            {{ subSectionLine.displayName }}
                          </div>
                        </td>
                        <td colspan="2" *ngIf="subSectionLine.position == 'Center'">
                          <div style="width: 100%" *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l1 = index
                            " class="btn-group d-flex">
                            <div style="width: 100%" class="btn-group d-flex mb-1"
                              *ngIf="taskLine.position == 'Center'">
                              <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }" (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                0,
                                j,
                                k,
                                l1
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ 0 }}_{{ j }}_{{ k }}_{{
                                    l1
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l2 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Left'">
                              <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }" (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                0,
                                j,
                                k,
                                l2
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ 0 }}_{{ j }}_{{ k }}_{{
                                    l2
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l3 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Right'">
                              <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }" (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                0,
                                j,
                                k,
                                l3
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ 0 }}_{{ j }}_{{ k }}_{{
                                    l3
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer px-0">
          <button type="button" (click)="onBtClose()" class="btn btn-primary btn-sm" data-bs-dismiss="modal">
            <i class="fa fa-check mr-2"></i>Continuer
          </button>
        </div>
      </div>
    </div>
  </div>

</div>

<div id="pdf_barcode" class="float-left mb-2 {{ isPrintDetailBtVisible }}">
  <div class="row">
    <ejs-barcodegenerator style="display: block" #barcode id="barcode" width="200px" height="80px" mode="SVG"
      type="Code39" value=""></ejs-barcodegenerator>
  </div>
</div>

<div class="container {{ isPrintDetailBtVisible }}" style="opacity:0">
  <div #sectiontoprint id="sectiontoprint" class="px-4 py-4" style="color: black">
    <table class="table table-stripped table-border border border-secondary mb-1" style="height:80px">
      <tr class="bg-light fw-bold small">
        <td>N° de série</td>
        <td>Description</td>
        <td>Catégorie équip.</td>
        <td>BT en cours</td>
        <td>Statut BT</td>
        <td>Statut équip. actuel</td>
        <td>Emplacement équip.</td>
      </tr>
      <tr>
        <td>{{ equipement?.no }}</td>
        <td>{{ equipement?.description }}</td>
        <td>{{ equipement?.categoryDescription }}</td>
        <td>{{ equipement?.workOrderNo }}</td>
        <td>{{ equipement?.workOrderBixiStatusDesc }}</td>
        <td>{{ equipement?.assetStatusCode }}</td>
        <td>{{ equipement?.assetLocationCode }}</td>
      </tr>
    </table>
    <div class="row" *ngIf="workorderTemplate">
      <div *ngFor="let columnLine of workorderTemplate.columnLines;let i = index" class="col-md-4 cardColum">
        <div *ngFor="let sectionLine of columnLine.sectionLines; let j = index"
          class="card rounded-0 borderTemplateTable cardSection">
          <div class="card-header pb-0 pt-2 bg-light rounded-0">
            <h5 class="sectionLineDisplayName">
              <!-- <i class="fas fa-table"></i> -->
              {{ sectionLine.displayName }}
            </h5>
          </div>
          <div class="card-body pt-0 pb-0 pl-2 pr-2 bg-white" style="padding: 0px !important">
            <div class="table-responsive">
              <table class="">
                <thead class="">
                  <tr class="text-center">
                    <th style="width: 10px !important"></th>
                    <th *ngIf="
                              !sectionLine.leftLabel && !sectionLine.rightLabel
                            "></th>
                    <th style="width: 40px !important" *ngIf="sectionLine.leftLabel">
                      {{ sectionLine.leftLabel }}
                    </th>
                    <th style="width: 40px !important" *ngIf="sectionLine.rightLabel">
                      {{ sectionLine.rightLabel }}
                    </th>
                  </tr>

                  <!-- <tr class="text-center">
                      <th style="width:30%"></th>
                      <th>{{sectionLine.leftLabel}}</th>
                      <th>{{sectionLine.rightLabel}}</th>
                    </tr> -->
                </thead>
                <tbody>
                  <tr *ngFor="
                            let subSectionLine of sectionLine.subSectionLines;
                            let k = index
                          ">
                    <td class="small-font-size-bx" style="padding-right: 0px; width: 10px">
                      <div class="ctc-1">
                        {{ subSectionLine.displayName }}
                      </div>
                    </td>
                    <td colspan="2" *ngIf="subSectionLine.position == 'Center'">
                      <div style="width: 100%" *ngFor="
                                let taskLine of subSectionLine.taskLines;
                                let l1 = index
                              " class="btn-group d-flex">
                        <div style="width: 100%" class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Center'">
                          <button
                            class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style add-large-width"
                            [ngStyle]="{
                                    backgroundColor:
                                      taskLine.taskCategoryByDoc != undefined
                                        ? taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Evaluator' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                          ? taskLine.definition !== undefined
                                            ? taskLine.definition.evaluatorColor
                                            : 'white'
                                          : taskLine.taskCategoryByDoc
                                              .createdByProfil == 'Mechanic' &&
                                            taskLine.taskCategoryByDoc
                                              .codeTaskCategory ==
                                              taskLine.codeTaskCategory
                                          ? taskLine.definition !== undefined
                                            ? taskLine.definition.mechanicColor
                                            : 'white'
                                          : taskLine.taskCategoryByDoc
                                              .createdByProfil == 'Supervisor' &&
                                            taskLine.taskCategoryByDoc
                                              .codeTaskCategory ==
                                              taskLine.codeTaskCategory
                                          ? taskLine.definition !== undefined
                                            ? taskLine.definition.supervisorColor
                                            : 'white'
                                          : 'white'
                                        : 'white'
                                  }" (click)="
                                    updateTaskOnWorkOrder(
                                      taskLine.taskCategoryByDoc !== undefined
                                        ? {
                                            data: 'taskCategoryByDoc',
                                            object: taskLine
                                          }
                                        : { data: 'taskLine', object: taskLine }
                                    )
                                  ">
                            {{
                            taskLine.taskCategoryByDoc !== undefined
                            ? "(" +
                            taskLine.taskCategoryByDoc.multiple +
                            ")"
                            : ""
                            }}
                            {{ taskLine.displayName }}
                          </button>
                        </div>
                      </div>
                    </td>

                    <td *ngIf="
                              (subSectionLine.position == 'L/R' &&
                                sectionLine.leftLabel) ||
                              (subSectionLine.position == 'L/R' &&
                                sectionLine.leftLabel == '')
                            ">
                      <div *ngFor="
                                let taskLine of subSectionLine.taskLines;
                                let l2 = index
                              " class="btn-group d-flex">
                        <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Left'">
                          <button
                            class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style"
                            [ngStyle]="{
                                    backgroundColor:
                                      taskLine.taskCategoryByDoc != undefined
                                        ? taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Evaluator' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                          ? taskLine.definition !== undefined
                                            ? taskLine.definition.evaluatorColor
                                            : 'white'
                                          : taskLine.taskCategoryByDoc
                                              .createdByProfil == 'Mechanic' &&
                                            taskLine.taskCategoryByDoc
                                              .codeTaskCategory ==
                                              taskLine.codeTaskCategory
                                          ? taskLine.definition !== undefined
                                            ? taskLine.definition.mechanicColor
                                            : 'white'
                                          : taskLine.taskCategoryByDoc
                                              .createdByProfil == 'Supervisor' &&
                                            taskLine.taskCategoryByDoc
                                              .codeTaskCategory ==
                                              taskLine.codeTaskCategory
                                          ? taskLine.definition !== undefined
                                            ? taskLine.definition.supervisorColor
                                            : 'white'
                                          : 'white'
                                        : 'white'
                                  }" (click)="
                                    updateTaskOnWorkOrder(
                                      taskLine.taskCategoryByDoc !== undefined
                                        ? {
                                            data: 'taskCategoryByDoc',
                                            object: taskLine
                                          }
                                        : { data: 'taskLine', object: taskLine }
                                    )
                                  ">
                            {{
                            taskLine.taskCategoryByDoc !== undefined
                            ? "(" +
                            taskLine.taskCategoryByDoc.multiple +
                            ")"
                            : ""
                            }}
                            {{ taskLine.displayName }}
                          </button>
                        </div>
                      </div>
                    </td>

                    <td *ngIf="
                              (subSectionLine.position == 'L/R' &&
                                sectionLine.rightLabel) ||
                              (subSectionLine.position == 'L/R' &&
                                sectionLine.rightLabel == '')
                            ">
                      <div *ngFor="
                                let taskLine of subSectionLine.taskLines;
                                let l3 = index
                              " class="btn-group d-flex">
                        <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Right'">
                          <button
                            class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style"
                            [ngStyle]="{
                                    backgroundColor:
                                      taskLine.taskCategoryByDoc != undefined
                                        ? taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Evaluator' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                          ? taskLine.definition !== undefined
                                            ? taskLine.definition.evaluatorColor
                                            : 'white'
                                          : taskLine.taskCategoryByDoc
                                              .createdByProfil == 'Mechanic' &&
                                            taskLine.taskCategoryByDoc
                                              .codeTaskCategory ==
                                              taskLine.codeTaskCategory
                                          ? taskLine.definition !== undefined
                                            ? taskLine.definition.mechanicColor
                                            : 'white'
                                          : taskLine.taskCategoryByDoc
                                              .createdByProfil == 'Supervisor' &&
                                            taskLine.taskCategoryByDoc
                                              .codeTaskCategory ==
                                              taskLine.codeTaskCategory
                                          ? taskLine.definition !== undefined
                                            ? taskLine.definition.supervisorColor
                                            : 'white'
                                          : 'white'
                                        : 'white'
                                  }" (click)="
                                    updateTaskOnWorkOrder(
                                      taskLine.taskCategoryByDoc !== undefined
                                        ? {
                                            data: 'taskCategoryByDoc',
                                            object: taskLine
                                          }
                                        : { data: 'taskLine', object: taskLine }
                                    )
                                  ">
                            {{
                            taskLine.taskCategoryByDoc !== undefined
                            ? "(" +
                            taskLine.taskCategoryByDoc.multiple +
                            ")"
                            : ""
                            }}
                            {{ taskLine.displayName }}
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="bikePartsData && bikePartsData.length > 0" class="cardBikeParts">
      <label class="h5 fw-bold mt-5">Pièces</label>
      <table class="table table-stripped table-border border border-secondary small">
        <tr class="bg-light fw-bold small">
          <td class="py-2">Opérations</td>
          <td class="py-2 text-center">Quantité</td>
          <td class="py-2">Code article</td>
          <td class="py-2">Code variant</td>
          <td class="py-2">Description</td>
        </tr>
        <tr *ngFor="let part of bikePartsData">
          <td class="py-2">{{ part?.descriptionCategory }}</td>
          <td class="py-2 text-center">{{ part?.quantity }}</td>
          <td class="py-2">{{ part?.no }}</td>
          <td class="py-2">{{ part?.variantCode }}</td>
          <td class="py-2">{{ part?.description }}</td>
        </tr>
      </table>
    </div>
  </div>
</div>