import { Component, Input, OnInit, ViewChild } from '@angular/core';
import Notiflix from 'notiflix';
import { Subject } from 'rxjs';
import { Company } from 'src/app/core/shared/models/company.model';
import { ConfirmationDetails } from 'src/app/core/shared/models/confirmationDetails.model';
import { StorageService } from 'src/app/core/shared/services/storage.service';
import { DetailWorkorderService } from 'src/app/pages/detail-workorder/services/detail-workorder.service';
import { BikePartsListService } from 'src/app/pages/item-list/bike-parts-list.service';
import { CommunService } from 'src/app/services/commun.service';
declare var bootstrap: any;
import { Profile } from 'src/app/core/shared/models/profile';
import { HeaderService } from '../header/services/header.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-validation-status',
  templateUrl: './confirm-validation-status.component.html',
  styleUrls: ['./confirm-validation-status.component.scss']
})
export class ConfirmValidationStatusComponent implements OnInit {
  @Input('confirmValidationStatusModalEvent') confirmValidationStatusModalEvent:Subject<any>;
  generateReturnOT: boolean = false;
  checkValidationOfLines: boolean = false; 
  BIXI_userAuthObject: any = null;
  currentUserCompany: Company | undefined;
  confirmationDetailsData: ConfirmationDetails[] = [];
  confirmationDetailsItemsData: ConfirmationDetails[] = [];
  confirmationDetailsExpensesData: ConfirmationDetails[] = [];
  reviewBeforeConfirmModal = null
  workOrderBixiStatusModal = null
  workorderNo : string = "";
  workorderSysId : string = "";
  confirmValidationStatusStart: boolean = false; 
  isTransferOrderReturnCreated: boolean = true; 
  isValidateBtnDisabled: boolean = true; 
  isItemChecked: boolean = false; 
  isExpenseChecked: boolean = false; 
  @ViewChild('barcode') public barcode: any;
  codeBarImgBase64: string = "";
  createdOTReturnNo: string = "";
  isPrintReturnOTNoClicked: boolean = false;
  defaultFinishedStatus: string = "";
  nextWoStatus: string = "";
  constructor(
    private detailWorkorderService: DetailWorkorderService,
    private storageService: StorageService,
    private bikePartsListService: BikePartsListService,
    private communService: CommunService,
    private headerService: HeaderService,    
    private router: Router,
  ) { } 


  initConfirmValidationStatusModalValues(){
    this.confirmValidationStatusStart = true;
    this.isValidateBtnDisabled = true;
    this.isPrintReturnOTNoClicked = false;
    this.isTransferOrderReturnCreated = false;      
    this.confirmationDetailsData = [];
    this.confirmationDetailsExpensesData = [];
    this.confirmationDetailsItemsData =  [];
    this.isItemChecked = false;
    this.isExpenseChecked = false;
    this.codeBarImgBase64 = "";
    this.createdOTReturnNo = "";
    this.defaultFinishedStatus = "";
    this.nextWoStatus = "";
  }
  
  ngOnInit() {
      this.BIXI_userAuthObject = JSON.parse(
        this.storageService.getItem('bixi-user-auth')
      );
      this.currentUserCompany = JSON.parse(
        this.storageService.getItem('bixi-currentCompany')
      );

    this.confirmValidationStatusModalEvent.subscribe((data: any) => {
      this.initConfirmValidationStatusModalValues();
      this.generateReturnOT = data.generateReturnOT;
      this.checkValidationOfLines = data.checkValidationOfLines;
      this.defaultFinishedStatus =  data.defaultFinalStatus;
      this.nextWoStatus =  data.nextWoStatus;    
      this.workorderNo = data.workorderNo;
      this.workorderSysId = data.workorderSysId
      this.getConfirmationDetails(this.workorderNo);   
    });
  }
  
  getConfirmationDetails(WorkorderNumber) {
      // Notiflix.Loading.standard('Chargement...', {
      //   svgColor: '#ffffff',
      // });    
      this.detailWorkorderService
      .getConfirmationDetails(
        this.BIXI_userAuthObject.tenantId,
        this.currentUserCompany.id,
        "$filter=documentNo eq '" + WorkorderNumber +"'"  
      )
      .subscribe({
        next: (confirmationDetails) => {
          Notiflix.Loading.remove();
          this.confirmationDetailsData = confirmationDetails;      
          this.confirmationDetailsExpensesData = this.confirmationDetailsData.filter((elem) => elem?.type?.toLowerCase() === 'expense' || elem?.type?.toLowerCase() === 'dépenses');
          this.confirmationDetailsItemsData = this.confirmationDetailsData.filter((elem) => elem?.type?.toLowerCase() === 'item' || elem?.type?.toLowerCase() === 'article');
          if(this.confirmationDetailsExpensesData.length == 0 && this.confirmationDetailsItemsData.length == 0){
            this.isValidateBtnDisabled = false;
            this.generateReturnOT = false;
          }
          else{
            this.generateReturnOT = this.confirmationDetailsData?.filter(x=>x?.returnTransToBePlanned == true)?.length > 0 ? true : false;
          }
          this.reviewBeforeConfirmModal = new bootstrap.Modal(document.getElementById('reviewBeforeConfirmModal'), {})
          this.reviewBeforeConfirmModal.show();
        },
        error: (error) => {
          Notiflix.Loading.remove();
        },
    });
  }


  checkExpensesToValidate(event){
    this.isValidateBtnDisabled = (event && this.isItemChecked) || (event && this.confirmationDetailsItemsData.length == 0) ? false : true;
  }

  checkItemsToValidate(event){
    this.isValidateBtnDisabled = (event && this.isExpenseChecked) || (event && this.confirmationDetailsExpensesData.length == 0) ? false : true;
  } 
  
  cancelReviewBeforeConfirmModal(){
    this.reviewBeforeConfirmModal.hide();
    // this.workOrderBixiStatusModal = new bootstrap.Modal(document.getElementById('workOrderBixiStatusModal'), {})
    // this.workOrderBixiStatusModal.show();
  }

  async generateCodeBar(valueBarCode: string) {
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    this.barcode.value = valueBarCode;
    this.createdOTReturnNo = valueBarCode;
    try{      
      await new Promise(resolve => setTimeout(resolve)).then(() => this.exportAsBase64Image())
    }
    catch{
        this.errorHandle('Une erreur est survenue lors du génération code barre de N° Transfet retour'+ this.barcode.value); 
    }
  }

  async exportAsBase64Image(){
    await this.barcode.exportAsBase64Image('PNG').then(data => {
      if(data){
        this.codeBarImgBase64 = data;
      }
      else{
        this.errorHandle('Une erreur est survenue lors du génération code barre de N° Transfet retour'); 
      }
      Notiflix.Loading.remove();
    })    
  }

  printReturnOrderLabel() {   
    if(this.barcode.value){
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff',
      });
      this.bikePartsListService
        .printReturnOrderLabel(
          this.BIXI_userAuthObject.tenantId,
          this.currentUserCompany.id,
          this.barcode?.value
        )
        .subscribe({
          next: (report) => {
            Notiflix.Loading.remove();
            if (report?.value)
              this.communService.openPdfFileInNewTab(report?.value);
            else
              Notiflix.Notify.failure(
                `Aucune étiquette trouvée pour l'ordre de transfert retour ${this.barcode?.value} `
              );
            this.isPrintReturnOTNoClicked = true;
          },
          error: (error) => {
            this.errorHandle(error);
            this.isPrintReturnOTNoClicked = true;
          },
        });
    }
    else{
      Notiflix.Notify.failure("Impossible d'imprimer l'étiquette. N° Transfert RP invalide ou inexistant", {
        timeout: 3000,
        showOnlyTheLastOne: true, 
        width: "400px",
      }); 
      this.isPrintReturnOTNoClicked = true;      
    }
  }  

  applyNewStatus(){
    if(!this.isValidateBtnDisabled){
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff'
      });
      let currentProfile: Profile  = JSON.parse(
        this.storageService.getItem('bixi-currentProfile')
      );
      const userProfile = currentProfile?.profil       
      const data = { "newStatus": this.nextWoStatus, "userProfil": userProfile, "isMultiBt": false }
      this.headerService.editStatus(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.workorderSysId, JSON.stringify(data)).subscribe({
        next: (data) => {
           this.getreturnOrderNo()                 
        },
        error: (error) => {
          this.errorHandle(error);
      }
      }) 
    }
  }

  applyFinishedStatus(){
    if(!this.isValidateBtnDisabled){
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff'
      });
      let currentProfile: Profile  = JSON.parse(
        this.storageService.getItem('bixi-currentProfile')
      );
      const userProfile = currentProfile?.profil
          
      const data = { "newStatus": this.defaultFinishedStatus, "userProfil": userProfile, "isMultiBt": false }
      this.headerService.editStatus(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.workorderSysId, JSON.stringify(data)).subscribe({
        next: (data) => {
          this.reviewBeforeConfirmModal.hide();
          let currentProfile = this.storageService.getItem('bixi-currentProfile') ? JSON.parse(this.storageService.getItem('bixi-currentProfile'))?.id : "";
          this.router.navigateByUrl(`home/${this.currentUserCompany.id}/${currentProfile}`);  
          Notiflix.Loading.remove();                       
        },
        error: (error) => {
          this.errorHandle(error);
        }
      }) 
    }
  }

  getreturnOrderNo(){       
    this.headerService.getreturnOrderNo(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id).subscribe({
      next: (data) => {
        Notiflix.Loading.remove();
        this.confirmValidationStatusStart = false;
        if(data?.value && data?.value.length>0){
           let returnOrder = data?.value?.find (x=> x.no == this.workorderNo);
           if(returnOrder && returnOrder?.returnOrderNo){
            this.isTransferOrderReturnCreated = true;
            this.createdOTReturnNo = data?.value[0]?.returnOrderNo;
            this.generateCodeBar(this.createdOTReturnNo);
           }
           else{
            this.isPrintReturnOTNoClicked = true;
            this.isTransferOrderReturnCreated = false;
           }
        }
        else{
          this.isPrintReturnOTNoClicked = true;
          this.isTransferOrderReturnCreated = false;
        }              
      },
      error: (error) => {
       Notiflix.Loading.remove();
       this.isTransferOrderReturnCreated = false;
    }
    }) 
  }

  private errorHandle(message){
    Notiflix.Loading.remove();
    Notiflix.Notify.failure(message, {
      timeout: 3000,
      showOnlyTheLastOne: true, 
      width: "400px",
    })    
  }

}
