import { Injectable } from '@angular/core';
import { StatusProfileBlockAndReturnScan } from '../models/statusProfileBlockAndReturnScan.model';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  public setItem(key: string, data: string): void {
    localStorage.setItem(key, data);
  }

  public getItem(key: string): string {
    return localStorage.getItem(key) || '';
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }

  setBlockAndReturnScanObject(profile: string, status: string, woId: string, blockAction: boolean, returnScan: boolean){
    let blockAndReturnScanObject = {} as StatusProfileBlockAndReturnScan;
    blockAndReturnScanObject.currentProfile = profile //== "Evaluator" ? "Evaluateur" : profile;
    blockAndReturnScanObject.currentStatus = status;
    blockAndReturnScanObject.workOrderLeadId = woId;
    blockAndReturnScanObject.isBlockedAction = blockAction;
    blockAndReturnScanObject.isRetrunScan = returnScan;
    this.setItem("Bixi_BlockAndReturnScan", JSON.stringify(blockAndReturnScanObject));
  }

  removeBlockAndReturnScanObject(){
    this.removeItem("Bixi_BlockAndReturnScan");
  }
}
