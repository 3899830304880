import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as Notiflix from 'notiflix';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { OpenWorkorderService } from 'src/app/pages/open-workorder/services/open-workorder.service';
import { CommunService } from 'src/app/services/commun.service';
import { StorageService } from 'src/app/core/shared/services/storage.service';
import { Company } from 'src/app/core/shared/models/company.model';
import { Profile } from 'src/app/core/shared/models/profile';
import { Equipement } from 'src/app/core/shared/models/equipement';
import { SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { GenericComponent } from '../generic-component';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Features } from 'src/app/models/accessGrantObject.model';
import { AccessService } from 'src/app/guard/access.service';
import { StatusProfileBlockAndReturnScan } from 'src/app/core/shared/models/statusProfileBlockAndReturnScan.model';

@Component({
  selector: 'app-linked-asset',
  templateUrl: './linked-asset.component.html',
  styleUrls: ['./linked-asset.component.scss'],
})
export class LinkedAssetComponent extends GenericComponent implements OnInit {
  @ViewChild('listEquipGrid') listEquipGrid: GridComponent;
  @Input() formTitle: string = 'Default Title';
  @Input() equipement: Equipement;
  @Output() confirmeCreateWoEquipEvent = new EventEmitter<object>();
  @Input() isUserBlocked: boolean = true;

  selectedWorEquipment = '';
  linkedAssets: Object[];
  bixiUserAuthObject: any = null;
  currentUserCompany: Company | undefined;
  currentProfile: Profile = null;
  public selectionOptions: SelectionSettingsModel = {
    type: 'Single', checkboxMode: 'ResetOnRowClick'
  };

  selectedRowIndex = 0;
  private refreshAssetLinkEvent: Subscription;
  @Input() events: Observable<void>;
  linkedAssetFeatures: Features = null;
  
  constructor(
    private router: Router,
    private communService: CommunService,
    private storageService: StorageService,
    private openWorkOrderService: OpenWorkorderService,
    private accessService: AccessService
  ) {
    super();
  }

  ngOnInit() {

      if(this.events){
        this.refreshAssetLinkEvent = this.events.subscribe(() => {
          this.getLinkedAssets()
        });
      }
    
    if (this.storageService.getItem('bixi-user-auth'))
      this.bixiUserAuthObject = JSON.parse(
        this.storageService.getItem('bixi-user-auth')
      );
    if (this.storageService.getItem('bixi-currentCompany'))
      this.currentUserCompany = JSON.parse(
        this.storageService.getItem('bixi-currentCompany')
      );
    if (this.storageService.getItem('bixi-currentProfile'))
      this.currentProfile = JSON.parse(
        this.storageService.getItem('bixi-currentProfile')
      );
    this.getLinkedAssets();

    this.linkedAssetFeatures = this.accessService.getFeatureByProfile(this.currentProfile.profil)

  }

  confirmeCreateWoEquip() {
    if(!this.isUserBlocked && this.linkedAssetFeatures?.buttonCreateWO){
      var selectedRecords: any[] = this.listEquipGrid.getSelectedRecords();
      if(selectedRecords.length > 0 && (!selectedRecords[0].currentWo || selectedRecords[0].currentWo == '')){
        this.confirmeCreateWoEquipEvent.emit(selectedRecords[0])
      }else{
        Notiflix.Notify.failure('Veuillez sélectionner un équipement sans Bon de travail.', {
          timeout: 3000,
          showOnlyTheLastOne: true, width: "400px",
        });
      }
    }
    else  this.displayPermissionErrorMessage();
  }

  private displayPermissionErrorMessage() {

    if(this.storageService.getItem('Bixi_BlockAndReturnScan')){
      const statusApiPermissions:StatusProfileBlockAndReturnScan = JSON.parse(this.storageService.getItem("Bixi_BlockAndReturnScan")) || null;
      if(statusApiPermissions && statusApiPermissions.isBlockedAction){
        Notiflix.Notify.warning(
            "Le statut en cours "+ statusApiPermissions.currentStatus+" du bon de travail bloque cette opération pour le profil " +
              this.communService.roleEnglishToFrench[statusApiPermissions.currentProfile],{
              timeout: 3000,
              showOnlyTheLastOne: true, width: "400px",
            }
          );
      }
      else {
        Notiflix.Notify.warning(
            "Vous ne pouvez pas effectuer cette action car vous êtes un " +
              this.communService.roleEnglishToFrench[statusApiPermissions.currentProfile],{
              timeout: 3000,
              showOnlyTheLastOne: true, width: "400px",
            }
          );
      }
    }

  } 

  goToTemplate() {
    localStorage.setItem('bixiApp_isTemplateReadOnly', '0');
    this.router.navigateByUrl('detailworkorder');
  }

  goToTemplate2(data) {
    localStorage.setItem('bixiApp_isTemplateReadOnly', '0');
    this.storageService.setItem('equipementRoueSerialNumber', data.num)

    const roueAssetNomber = this.storageService.getItem(
      'equipementRoueSerialNumber'
    );

      this.storageService.setItem(
        'currentSerialNumber-window-popup-browser',
        roueAssetNomber
      );
      this.router.navigateByUrl(
        `detailworkorder/${this.currentUserCompany.id}/${this.currentProfile.id}/workOrder/${data.currentWo}?mode=topup-bt`
      );

  }

  printWorkOrderLabel() {
    if(this.listEquipGrid.getSelectedRecords().length > 0) {
      var workOrderNo = this.listEquipGrid
      .getSelectedRecords()[0]
      ['currentWo']?.trim();
      var isVirtualAsset = this.listEquipGrid.getSelectedRecords()[0]
      ['isVirtualAsset']
      if (workOrderNo) {
        if(isVirtualAsset){
          Notiflix.Loading.standard('Chargement...', {
            svgColor: '#ffffff',
          });
          this.openWorkOrderService
            .printWorkOrderLabel(
              this.bixiUserAuthObject.tenantId,
              this.currentUserCompany.id,
              workOrderNo
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (report) => {
                Notiflix.Loading.remove();
                if (report?.value)
                  this.communService.openPdfFileInNewTab(report?.value);
                else
                  Notiflix.Notify.failure(
                      `Aucune étiquette trouvée pour le bon de travail ${workOrderNo} `,{
                      timeout: 3000,
                      showOnlyTheLastOne: true, 
                      width: "400px",
                  });
              },
              error: (error) => {
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(error, {
                  timeout: 3000,
                  showOnlyTheLastOne: true, 
                  width: "400px",
                });
              },
            });
        }
        else{
          Notiflix.Notify.warning("Impossible d'imprimer le bon de travail d'un équipement non virtuel", {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
          }); 
        }
      } else {
        Notiflix.Notify.failure('Aucun Bon de travail trouvé sur cet équipement. Veuillez créer un Bon de travail.', {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
        });
      }
    }else{
      Notiflix.Notify.warning('Aucun équipement sélectionné', {
        timeout: 3000,
        showOnlyTheLastOne: true, 
        width: "400px",
      });      
    } 
  }

  getLinkedAssets() {
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff',
      });    
      this.openWorkOrderService
      .getLinkedAssets(
        this.bixiUserAuthObject.tenantId,
        this.currentUserCompany.id,
        "no eq '" + this.equipement?.no + "'"
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (result) => {
          if (result.value && result.value.length) {
            let eraLinkedChildAssets =
              result.value[0].eraLinkedChildAssets.map((x) => ({
                num: x.no,
                desciption: x.description,
                category: x.categoryCode,
                currentWo: x.leadWorkOrderNo,
                isRoueAvant: typeof x.isRoueAvant,
                isVirtualAsset: x.isVirtualAsset
              })) ?? [];
            
            const linkedrepairassets =
              result.value[0].linkedrepairassets.map((x) => ({
                num: x.assetN,
                desciption: x.assetdescription,
                category: x.codeassetcategory,
                currentWo: x.workOrderSourceNo,
                isRoueAvant: x.isRoueAvant,
                isVirtualAsset: x.isVirtualAsset
              })) ?? [];

            let linkedAssetsList = eraLinkedChildAssets.concat(linkedrepairassets);
            this.linkedAssets = linkedAssetsList.filter((v,i,a)=>a.findIndex(t=>(t?.num === v?.num && t?.category == v?.category))===i)
          }
          Notiflix.Loading.remove();
        },
        error: (error) => {
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
           });        
           Notiflix.Loading.remove();
        },
      });
  }

  rowselecting(args: any): void {  
    if (args.target && args.target.classList.contains('e-icons')){ 
      if(this.listEquipGrid){
        this.listEquipGrid.clearSelection();
      }
    }   
  }
  
}
