import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/config/app.config.service';
import { Observable, catchError, map } from 'rxjs';
import { ErrorService } from 'src/app/core/shared/services/error.service';


@Injectable({
  providedIn: 'root'
})
export class ArchiveWorkorderService {

  defaultCompanyId = AppConfigService.settings.defaultCompanyId;
  bcCoreApiUrl = AppConfigService.settings.bcCoreApiUrl;
  customApiUrlSegment = AppConfigService.settings.customApiUrlSegment;
  bcEnvirName = AppConfigService.settings.bcEnvirName;
  defaultLg = AppConfigService.settings.defaultLanguage;
  schemaVersion = AppConfigService.settings.bcSchemaversion;

    constructor(private http: HttpClient,
      private errorService: ErrorService  ) { }
  
     // Http Options
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'If-Match': '*'
      }),
    }; 

    getArchives(tenantId: any, companyId: string, $filter: string): Observable<any>{
      const url =  this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+ this.customApiUrlSegment + "/companies("+companyId+")/postedWorkOrders?$schemaversion="+this.schemaVersion+"&$filter="+$filter 
      return this.http.get(url, this.httpOptions).pipe(
        map(result  => result as any),
        catchError(this.errorService.handleError));
    }

    getArchivesOrderLines(tenantId: any, companyId: string, systemId: string, $filter: string): Observable<any>{
      const url =  this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+ this.customApiUrlSegment + "/companies("+companyId+")/postedWorkOrders("+ systemId +")/postedWorkOrderLines?$schemaversion="+this.schemaVersion+"&$filter="+$filter 
      return this.http.get(url, this.httpOptions).pipe(
        map(result  => result as any),
        catchError(this.errorService.handleError));
    }

}
