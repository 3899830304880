import { Component, ViewChild } from '@angular/core';
import * as Notiflix from 'notiflix';
import { ClickEventArgs } from '@syncfusion/ej2-navigations'
import { ColumnChooser, GridComponent, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/core/shared/services/storage.service';
import { Profile } from 'src/app/core/shared/models/profile';
import { Company } from 'src/app/core/shared/models/company.model';
import { HomeService } from '../home/services/home.service';
import { Equipement } from 'src/app/core/shared/models/equipement';
import { ValidationWorkorderService } from './services/validation-workorder.service';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { StatusProfileBlockAndReturnScan } from 'src/app/core/shared/models/statusProfileBlockAndReturnScan.model';
declare var bootstrap: any;

@Component({
  selector: 'app-validation-workorder',
  templateUrl: './validation-workorder.component.html',
  styleUrls: ['./validation-workorder.component.scss']
})
export class ValidationWorkorderComponent {

  @ViewChild('workOrdersGrid') workOrdersGrid: GridComponent;
  @ViewChild('cardWorkOrdersGrid') cardWorkOrdersGrid: any;   
  userProfil = "";

  pageName = 'validationWorkorder'
  public workOrdersData: Object[];
  public listEquipData: Object[];
  public currentWoData: object[];
  public consumptionTrackData: object[];

  public pageSettings: Object; 
  public toolbarOptions: ToolbarItems[] | object;
  public selectOptions: Object;
  public editSettings: Object;
  public columnChooserOption: ColumnChooser;
  currentUserName ="";

  userProfilTitle = "";
  BIXI_userAuthObject: any = null
  currentProfile: Profile = null
  currentProfiles: Profile[] = []
  workOrderNo = ''
  currentUserCompany: Company | undefined;
  selectedWorEquipment= "";
  equipement: Equipement = null
  equipementNo: string = ''
  isUserBlocked: boolean = true;  
  

  constructor(private router: Router,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private homeService: HomeService,
    private validationWorkorderService: ValidationWorkorderService,
    private AuthService: AuthService) { }


  changeProfile(profil){
    if(profil){
  
      const profileFound = this.currentProfiles.find(currentProfile => currentProfile.profil == profil)
  
      localStorage.setItem("bixi-currentProfile", JSON.stringify(profileFound));
      this.reloadCurrentRoute()

    }else{
      this.AuthService.logout()
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    currentUrl = currentUrl.replace('%20', ' ');
    location.reload()
  }

  logout(){
    Notiflix.Confirm.show(
      'Confirmation',
      "Êtes-vous sûr de vouloir vous déconnecter?",
      'Confirmer',
      'Annuler',
      () => {
        this.router.navigateByUrl('/')
      },
      () => {
      },
      {
        width: "450px",
        okButtonBackground: "#04B76B",
        cancelButtonBackground: "#FF5E6D",
        titleColor: "#392C70",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
      });
  }

  ngOnInit() {

    Notiflix.Notify.init({
      position: 'right-bottom'
    });
    // check if user has data in browser storage, else redirect himn to login page
    if(!this.storageService.getItem("bixi-user-auth") || !this.storageService.getItem("bixi-currentCompany") || !this.storageService.getItem("bixi-currentProfile")){
      this.router.navigateByUrl('/auth/login')
    }else{
      this.BIXI_userAuthObject = JSON.parse(this.storageService.getItem("bixi-user-auth"));
      this.currentUserCompany = JSON.parse(this.storageService.getItem("bixi-currentCompany"));
      this.currentProfile = JSON.parse(this.storageService.getItem("bixi-currentProfile"));
      this.currentProfiles = JSON.parse(this.storageService.getItem("bixi-currentProfiles"));
      this.equipementNo =  this.storageService.getItem('currentSerialNumber')
      if(this.storageService.getItem('Bixi_BlockAndReturnScan')){
        let BlockAndReturnScan: StatusProfileBlockAndReturnScan  = JSON.parse(this.storageService.getItem('Bixi_BlockAndReturnScan'));
        this.isUserBlocked = BlockAndReturnScan ? JSON.parse(this.storageService.getItem('Bixi_BlockAndReturnScan'))?.isBlockedAction : true
      }
     
    }
      this.getEquipement() 
      localStorage.setItem("bixiApp_isTemplateReadOnly", "0")   

    this.consumptionTrackData = [
      {NumItem: "ART01 (STICKER)", LastVariantCons: "VIRGIN", ConsumDate : "13/10/2022"},
      {NumItem: "ART02", LastVariantCons: "BTC-0001", ConsumDate : "13/10/2022"},
      {NumItem: "ART03", LastVariantCons: "BTC-0002", ConsumDate : "17/11/2022"},
      {NumItem: "ART04", LastVariantCons: "BTP-0001", ConsumDate : "28/01/2023"},
    ];
    this.listEquipData  = [
      {num: "ROAv-001", desciption: "Roue avant vélo électrique", category: "Roue", currentWo: "BT001"},
      {num: "ROAr-001", desciption: "Roue arrière vélo électrique", category: "Roue", currentWo: "BT002"},
    ];
    this.toolbarOptions = [
      { text: 'Afficher/masquer', prefixIcon: 'e-eye', align: 'Right'}, 
      { text: 'Nouveau', prefixIcon: 'e-plus', align: 'Left' }
    ];
    this.editSettings = { allowAdding: true };
    // Bootstrap tooltip initialization
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
    })     
  }

  validateWorkOrder(){
    var selectedRecords = this.workOrdersGrid.getSelectedRecords();
    for(let k=0; k<selectedRecords.length; k++){
    const index = this.workOrdersData.indexOf(selectedRecords[k]);
      if (index > -1) {
        this.workOrdersData[index]["State"] = "Validé"; 
      }
    }
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff'
    });
    setTimeout(() => {
      Notiflix.Loading.remove();
      this.workOrdersGrid.refresh();
      Notiflix.Notify.success("Bons de tavail validés avec succès!", {timeout: 1500, width: "400px"})
    }, 2000);
  }


  
  getRandomNumber(min: number,max: number) {
    min = Math.ceil(min);max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  getEquipement(){
    this.activatedRoute.params.subscribe(params => {
      this.equipementNo = params['equipementNo'];
      // checjk if equipementNo is in url
      if(this.equipementNo){
        this.equipementNo = this.equipementNo.replace('%20', ' ')
      Notiflix.Loading.standard('Chargement...', {
        svgColor: '#ffffff'        
      });
      this.homeService.getEquipement(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.equipementNo).subscribe({
        next: (equipement) => {
          this.equipement = equipement.value[0]
          this.validationWorkorderService.getWorkorders(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "status eq 'A valider'").subscribe({
            next: (workOrdersData) => {
                this.workOrdersData = workOrdersData.value
            },
            error: (error) => {
            }
          })
          Notiflix.Loading.remove();
        },
        error: (error) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(error, {
            timeout: 3000,
            showOnlyTheLastOne: true, 
            width: "400px",
           })
        }
      })  
      }else{
      }
    })
  }

  refreshEquipementEvent(){
    this.getEquipement()
  }
  
  toolbarClick(args: ClickEventArgs): void {
    switch (args.item.text) {
        case 'Valider':
            this.confirmWorkorder();
            break;
        case 'Afficher/masquer':
            var left =  window.innerWidth; 
            this.workOrdersGrid.openColumnChooser(left-373, 40);
            break;
        default:
          alert(args.item.text)
    }
  }

  columnsShowHide(){
    var left =  window.innerWidth; 
    this.workOrdersGrid.openColumnChooser(left-373, 0);
  }
  confirmWorkorder(){
      var selectedRecords = this.workOrdersGrid.getSelectedRecords();
      if(selectedRecords.length <= 0){
        Notiflix.Notify.warning("Aucun bon de tavail sélectionné!", {timeout: 1500, width: "400px"})
      }
      else{
     Notiflix.Confirm.show(
        'Confirmation',
        "Voulez-vous vraiment valider ces bons de travail?",
        'Confirmer',
        'Annuler',
        () => {
          this.validateWorkOrder()
        },
        () => {
        },
        {
          width: "450px",
          okButtonBackground: "#04B76B",
          cancelButtonBackground: "#FF5E6D",
          titleColor: "#392C70",
          borderRadius: "5px",
          backgroundColor: "#ffffff",
        },
        );
      }

  }

  
}
