import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'circle-progressbar',
  templateUrl: './circle-progressbar.component.html',
  styleUrls: ['./circle-progressbar.component.scss']
})
export class CircleProgressbarComponent implements OnInit {
  @Input() progressionValue;
  @Input() progressionType;
  @Input() isOverconsumption;
  @Input() displayProgressBarCircleTitle = false;
  @Input() disableProgressBar = false;


  constructor() { }

  ngOnInit() {
  }

  getBorderColor(): string{
    let color= ""
    if(this.progressionValue==100){
      if(this.isOverconsumption && this.progressionType == "R") color= "#d13c2b"   
      else color= "#38c18b" 
    }
    else if(this.progressionValue<100 && this.progressionValue>0){
       color = "#eaa92f"
    }
    else{
      color = "#d0d0d0"
    }
    return color
  }

}
