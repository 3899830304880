import { Component } from '@angular/core';
import { Loading } from 'notiflix/build/notiflix-loading-aio';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth/services/auth.service';
import { StorageService } from 'src/app/core/shared/services/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  authDisabled = false;
  authSuccess = false;
  authFailed = false;
  loginDisplay = false;
  successAuthMessage = '';
  errorAuthMessage = '';
  disableConnectionButton = false;

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private router: Router
  ) {}

  login(): void {
    this.authDisabled = true;
    this.disableConnectionButton = true;
    this.clearLoginAlerts();
    Loading.standard('Chargement...', {
      svgColor: '#ffffff',
    });
    this.authService.login().subscribe({
      next: (auth) => {
        //saving authentication data in user's browser storage
        this.storageService.setItem('bixi-user-auth', JSON.stringify(auth));

        //redirect user to profile page
        this.loginSuccess('auth/profile');
      },
      error: (_) => {
        this.disableConnectionButton = false;
        this.loginFailed('Échec! Veuillez vous connecter à nouveau');
      },
    });
  }

  private clearLoginAlerts() {
    this.successAuthMessage = '';
    this.errorAuthMessage = '';
    this.authSuccess = false;
    this.authFailed = false;
  }

  private loginFailed(error: string) {
    this.errorAuthMessage = error;
    this.authFailed = true;
    this.authDisabled = true;
    Loading.remove();
  }

  private loginSuccess(route: string) {
    this.setLoginDisplay();
    this.authSuccess = true;
    this.successAuthMessage = 'Félicitations! Connexion établie avec succès';
    Loading.remove();
    setTimeout(() => {
      this.router.navigateByUrl(route);
    }, 2000);
  }

  logout(): void {
    this.authService.logout();
  }

  private setLoginDisplay() {
    this.loginDisplay = this.authService.getAllAccountsCount() > 0;
  }
}
