import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

constructor() { }

  public handleError(error: HttpErrorResponse) {
    let errMsg = "";
    let stopWordError = "CorrelationId";
    if(error?.status != 409){
      errMsg = error?.error?.error?.message;
      errMsg = errMsg?.slice(0, errMsg?.indexOf(stopWordError));
    }
    errMsg = errMsg ? errMsg : "Une erreur inconnue s'est produite lors du traitement";
    return throwError(() => errMsg);
  }
}
