import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/config/app.config.service';
import { StartingInfos } from '../../../models/starting-infos.model';
import { Observable, Subject, catchError, map } from 'rxjs';
import { StorageService } from 'src/app/core/shared/services/storage.service';
import { ErrorService } from 'src/app/core/shared/services/error.service';
import { GenericApi } from 'src/app/core/shared/models/generic-api.model';
import { Equipement } from 'src/app/core/shared/models/equipement';


@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  defaultCompanyId = AppConfigService.settings.defaultCompanyId;
  bcCoreApiUrl = AppConfigService.settings.bcCoreApiUrl;
  customApiUrlSegment = AppConfigService.settings.customApiUrlSegment;
  bcEnvirName = AppConfigService.settings.bcEnvirName;
  defaultLg = AppConfigService.settings.defaultLanguage;
  schemaVersion = AppConfigService.settings.bcSchemaversion;
  
  public newMultipleCorrectifWOrkOrderEquipement: Subject<any> = new Subject<any>();
  public maganePermssionEvent: Subject<any> = new Subject<any>();
  
    constructor(private http: HttpClient,
      private storageService: StorageService,
      private errorService: ErrorService  ) { }
  
     // Http Options
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'If-Match': '*',
        'Accept-Language': 'fr-CA'
      }),
    }; 
  
    // getAuthorizedStatus(tenantId: any, companyId: string): Observable<any>{      
    //   const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+ this.customApiUrlSegment + "/companies("+companyId+")/eraAutorisedStatus";     
    //   return this.http.get(url, this.httpOptions).pipe(
    //     map(result  => result as Equipement),
    //     catchError(this.errorService.handleError));
    // }

    editStatus(tenantId: any, companyId: string, workOrderId, data: any): Observable<any>{  
      const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+ this.customApiUrlSegment + "/companies("+companyId+")/workOrders("+workOrderId+")/Microsoft.NAV.changeStatus?$schemaversion="+this.schemaVersion ;  
      return this.http.post(url, data, this.httpOptions).pipe(
        map(result  => result as Equipement),
        catchError(this.errorService.handleError));
    }

    checkchangeStatus(tenantId: any, companyId: string, workOrderId, data: any): Observable<any>{  
      const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+ this.customApiUrlSegment + "/companies("+companyId+")/workOrders("+workOrderId+")/Microsoft.NAV.checkchangeStatus?$schemaversion="+this.schemaVersion ;  
      return this.http.post(url, data, this.httpOptions).pipe(
        map(result  => result as Equipement),
        catchError(this.errorService.handleError));
    }

    getreturnOrderNo(tenantId: any, companyId: string): Observable<any>{  
      const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+ this.customApiUrlSegment + "/companies("+companyId+")/eraWOReturnOrder?$schemaversion="+this.schemaVersion ;     
      return this.http.get(url, this.httpOptions).pipe(
        catchError(this.errorService.handleError));
    }
  
  }
