import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-consumption-track',
  templateUrl: './consumption-track.component.html',
  styleUrls: ['./consumption-track.component.scss'],
})
export class ConsumptionTrackComponent {
  @Input() consumptionTrackData: object[];
}
