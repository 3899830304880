import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/config/app.config.service';
import { StartingInfos } from '../../../models/starting-infos.model';
import { Observable, catchError, map } from 'rxjs';
import { StorageService } from 'src/app/core/shared/services/storage.service';
import { ErrorService } from 'src/app/core/shared/services/error.service';
import { GenericApi } from 'src/app/core/shared/models/generic-api.model';
import { Equipement } from 'src/app/core/shared/models/equipement';

@Injectable({
  providedIn: 'root'
})
export class ValidationWorkorderService {

  defaultCompanyId = AppConfigService.settings.defaultCompanyId;
  bcCoreApiUrl = AppConfigService.settings.bcCoreApiUrl;
  customApiUrlSegment = AppConfigService.settings.customApiUrlSegment;
  bcEnvirName = AppConfigService.settings.bcEnvirName;
  defaultLg = AppConfigService.settings.defaultLanguage;
  schemaVersion = AppConfigService.settings.bcSchemaversion;

    constructor(private http: HttpClient,
      private storageService: StorageService,
      private errorService: ErrorService  ) { }
  
     // Http Options
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    }; 
  
    getWorkorders(tenantId: any, companyId: string, $filter): Observable<any>{
      const url = this.bcCoreApiUrl+tenantId+"/"+this.bcEnvirName+"/"+ this.customApiUrlSegment + "/companies("+companyId+")/workOrders?$schemaversion="+this.schemaVersion+"&$filter="+$filter;     
      return this.http.get(url, this.httpOptions).pipe(
        map(result  => result as Equipement),
        catchError(this.errorService.handleError));
    }

}
