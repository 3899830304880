<div class="container-scroller">
  <!-- Nav bar component -->
  <app-nav-bar [BIXI_userAuthObject]="BIXI_userAuthObject" [currentProfile]="currentProfile"
    [currentUserCompany]="currentUserCompany" (changeProfileEvent)="changeProfile($event)" [equipementNo]="equipementNo"
    [pageName]="pageName" (logoutEvent)="logout()" [isUserBlocked]="isUserBlocked"></app-nav-bar>

  <div class="container-fluid page-body-wrapper add-padding">
    <!-- Side Bar component -->
    <app-side-bar [currentProfile]="currentProfile" [currentUserCompany]="currentUserCompany" [pageName]="pageName"
      (onpenEquipementAssocieEvent)="onpenEquipementAssocieModal()" [equipementNo]="equipementNo"></app-side-bar>

    <div class="main-panel" style="margin-left: 70px">
      <div class="content-wrapper p-3">
        <!-- Header -->
        <app-header *ngIf="equipement" [BIXI_userAuthObject]="BIXI_userAuthObject"
          [currentUserCompany]="currentUserCompany" [equipement]="equipement" [pageName]="pageName"
          (refreshEquipementEvent)="refreshEquipementEvent()"></app-header>

        <div class="accordion mt-2 accordion-solid-header" id="accordionExample" role="tablist">
          <div class="card border">
            <div class="accordion-item card-header">
              <h2 class="accordion-header my-0" id="headingOne">
                <a class="accordion-button py-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                  aria-expanded="true" aria-controls="collapseOne">
                  <i class="fas fa-folder mr-2"></i>Bons de travail en
                  cours
                  <!-- <button class="btn btn-sm btn-outline-info float-right pt-1 pb-1" style="margin-top:-5px"
                      (click)="goToDetailworkorder()"><i class="fa fa-list-alt mr-2 small"></i><span
                        style="font-size:12px">Editer les bons
                        de travail</span></button> -->
                </a>
              </h2>
            </div>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
              data-bs-parent="#accordionExample">
              <div class="accordion-body py-2">
                <div class="card cardWorkOrdersGrid">
                  <div>
                    <button class="btn btn-light m-2 mr-0 float-left p-1 border" (click)="addCurrentWoToOpenWoList()"
                      *ngIf="openWorkOrderFeatures?.workorderExclude">
                      <small class="fw-bold mr-2 ml-1"><i class="fas fa-random mr-1"></i> Exclure de BT en
                        cours</small>
                    </button>
                    <button class="btn btn-light m-2 ml-1 float-left p-1 border" [disabled]="disableEditBTButton"
                      (click)="goToMultipleBTDetailworkorder()">
                      <small class="fw-bold mr-2 ml-1"><i class="fas fa-list-alt mr-1"></i> Editer les bons de
                        travail
                      </small>
                    </button>
                    <!-- <button class="btn btn-light float-right border m-2 p-1" (click)="columnsShowHideOpenWo()">
                        <small class="fw-bold mr-2 ml-2"><i class="fas fa-eye mr-2"></i> Afficher/masquer</small></button> -->
                  </div>
                  <ejs-grid #currentWoGrid [dataSource]="inProgressWorkOrdersData" gridLines="Horizontal"
                    showColumnChooser="false" [allowSelection]="true" (checkBoxChange)="checkBoxChange($event)"
                    [allowMultiSorting]="true" rowHeight="30">
                    <e-columns>
                      <e-column type="checkbox" width="50" *ngIf="currentProfile.profil == 'Evaluator'"></e-column>
                      <e-column headerText="Bon de travail" width="150" textAlign="Left">
                        <ng-template #template let-data>
                          <a rel="nofollow" style="cursor: pointer" class="text-info fw-bold"
                            (click)="goToDetailworkorder(data.no)">{{ data.no }}</a>
                        </ng-template>
                      </e-column>
                      <e-column headerText="Type" width="130" textAlign="Left">
                        <ng-template #template let-data>
                          {{
                          data.correctiveMaintenance
                          ? "Correctif"
                          : "Preventif"
                          }}
                        </ng-template>
                      </e-column>
                      <e-column field="description" headerText="Description" width="120" textAlign="Left"></e-column>
                      <e-column field="eraBixiStatus" headerText="Statut" width="120" textAlign="Left"></e-column>
                    </e-columns>
                  </ejs-grid>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion mt-2 accordion-solid-header" id="accordionExample2" role="tablist">
          <div class="card border">
            <div class="accordion-item card-header">
              <h2 class="accordion-header my-0" id="headingThree">
                <a *ngIf="woOpenedState" class="accordion-button py-2" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                  <i class="fas fa-folder-open mr-2"></i>Bons de travail
                  ouverts
                </a>

                <a *ngIf="!woOpenedState" class="accordion-button py-2" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <i class="fas fa-folder-open mr-2"></i>Bons de travail
                  ouverts
                </a>
              </h2>
            </div>
            <div id="collapseTwo" [class]="
                woOpenedState
                  ? 'accordion-collapse collapse show'
                  : 'accordion-collapse collapse hide'
              " aria-labelledby="headingThree" data-bs-parent="#accordionExample2">
              <div class="accordion-body py-2">
                <div class="card cardWorkOrdersGrid" #cardWorkOrdersGrid>
                  <div>
                    <button class="btn btn-light m-2 float-left p-1 border d-none" (click)="createWorkorder()">
                      <small class="fw-bold mr-1 ml-2"><i class="fas fa-plus mr-1"></i> Nouveau</small>
                    </button>
                    <button class="btn btn-light m-2 float-left p-1 border" (click)="addOpenWoToCurrentWoList()"
                      *ngIf="openWorkOrderFeatures.workorderInclude">
                      <small class="fw-bold mr-2 ml-1"><i class="fas fa-random mr-1"></i> Inclure dans BT en
                        cours</small>
                    </button>
                    <!-- <button class="btn btn-light float-right border m-2 p-1" (click)="columnsShowHideCurrentWo()">
                        <small class="fw-bold mr-2 ml-2"><i class="fas fa-eye mr-2"></i> Afficher/masquer</small></button> -->
                  </div>
                  <ejs-grid #workOrdersGrid [dataSource]="openWorkOrdersData" gridLines="Horizontal"
                    showColumnChooser="true" [allowSelection]="true" [allowSorting]="true" [allowMultiSorting]="true"
                    rowHeight="30">
                    <e-columns>
                      <e-column type="checkbox" width="50" *ngIf="currentProfile.profil == 'Evaluator'"></e-column>
                      <e-column headerText="Bon de travail" width="130" textAlign="Left">
                        <ng-template #template let-data>
                          <a rel="nofollow" style="cursor: pointer" class="text-info fw-bold"
                            (click)="goReadOnlyToTemplate(data.no)">{{ data.no }}</a>
                        </ng-template>
                      </e-column>
                      <e-column headerText="Type" width="130" textAlign="Left">
                        <ng-template #template let-data>
                          {{
                          data.correctiveMaintenance
                          ? "Correctif"
                          : "Preventif"
                          }}
                        </ng-template>
                      </e-column>
                      <e-column field="description" headerText="Description" width="150" textAlign="Left"></e-column>
                      <e-column field="eraBixiStatus" headerText="Statut" width="150" textAlign="Left"></e-column>
                      <e-column field="nextPlannedDate" headerText="Date" format="yMd" width="120"></e-column>

                    </e-columns>


                  </ejs-grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-linked-asset *ngIf="equipement" [formTitle]="'open WO'" [equipement]="equipement"
  (confirmeCreateWoEquipEvent)="createNewWorkOrder($event)" [events]="refreshAssetLinkEvent.asObservable()"
  [isUserBlocked]="isUserBlocked"></app-linked-asset>

<app-consumption-track [consumptionTrackData]="consumptionTrackData"></app-consumption-track>

<div class="modal fade" id="associatedEquipTemplateModal" tabindex="-1" aria-labelledby="associatedEquipTemplateLabel"
  aria-hidden="true">
  <div *ngIf="roueWorkorder" class="modal-dialog modal-lg"
    [ngStyle]="{ width: roueWorkorder.isBtRoue ? '40%' : '70%' }">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 *ngIf="roueWorkorder !== null" class="modal-title text-primary modal-customized-title"
          id="associatedEquipTemplateLabel">
          <i class="fa fa-cog mr-2"></i>
          <span class="fw-bold">Bon de travail N° <span class="text-danger">{{ roueWorkorder.no }}</span></span>
        </h6>
        <button type="button" class="btn btn-danger btn-sm my-1" data-bs-dismiss="modal" aria-label="Close">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body pt-2 pb-2">
        <div *ngIf="templateRoue && !roueWorkorder.isBtRoue" class="row">
          <div *ngFor="let columnLine of templateRoue.columnLines; let i = index" class="col-md-4">
            <div *ngFor="let sectionLine of columnLine.sectionLines; let j = index"
              class="card mt-2 rounded-0 borderTemplateTable">
              <div class="card-header pb-0 pt-2 bg-light rounded-0">
                <h5 class="sectionLineDisplayName">
                  <!-- <i class="fas fa-table"></i> -->
                  {{ sectionLine.displayName }}
                </h5>
              </div>
              <div class="card-body pt-0 pb-0 pl-2 pr-2" style="padding: 0px !important">
                <div class="table-responsive">
                  <table class="table templateTable">
                    <thead>
                      <tr class="text-center">
                        <th style="width: 40%"></th>
                        <th *ngIf="
                            !sectionLine.leftLabel && !sectionLine.rightLabel
                          "></th>
                        <th *ngIf="sectionLine.leftLabel">
                          {{ sectionLine.leftLabel }}
                        </th>
                        <th *ngIf="sectionLine.rightLabel">
                          {{ sectionLine.rightLabel }}
                        </th>
                      </tr>

                      <!-- <tr class="text-center">
                <th style="width:30%"></th>
                <th>{{sectionLine.leftLabel}}</th>
                <th>{{sectionLine.rightLabel}}</th>
              </tr> -->
                    </thead>
                    <tbody>
                      <tr *ngFor="
                          let subSectionLine of sectionLine.subSectionLines;
                          let k = index
                        ">
                        <td class="font-weight-bold" style="padding-right: 0px; width: 50px">
                          <div class="ctc-1">
                            {{ subSectionLine.displayName }}
                          </div>
                        </td>
                        <td colspan="2" *ngIf="subSectionLine.position == 'Center'">
                          <div style="width: 100%" *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l1 = index
                            " class="btn-group d-flex">
                            <div style="width: 100%" class="btn-group d-flex mb-1"
                              *ngIf="taskLine.position == 'Center'">
                              <button
                                class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style add-large-width"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }" (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                taskLine.taskCategoryByDoc !== undefined
                                ? "(" +
                                taskLine.taskCategoryByDoc.multiple +
                                ")"
                                : ""
                                }}
                                <i class=" fontSizeTemplateBtn fa fa-star {{
                                    colorDefaultTaskIcon_Fr_Fr_A_G
                                  }}"></i>
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                i,
                                j,
                                k,
                                l1
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ i }}_{{ j }}_{{ k }}_{{
                                    l1
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l2 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Left'">
                              <button
                                class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style add-width-to-button"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }" (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                taskLine.taskCategoryByDoc !== undefined
                                ? "(" +
                                taskLine.taskCategoryByDoc.multiple +
                                ")"
                                : ""
                                }}
                                <i class=" fontSizeTemplateBtn fa fa-star {{
                                    colorDefaultTaskIcon_Fr_Fr_A_G
                                  }}"></i>
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                i,
                                j,
                                k,
                                l2
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ i }}_{{ j }}_{{ k }}_{{
                                    l2
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.rightLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.rightLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l3 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Right'">
                              <button
                                class="btn btn-sm p-1 btn-block borderTemplateBtn fontSizeTemplateBtn isDefaultTask task-button-style add-width-to-button"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }" (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                taskLine.taskCategoryByDoc !== undefined
                                ? "(" +
                                taskLine.taskCategoryByDoc.multiple +
                                ")"
                                : ""
                                }}
                                <i class=" fontSizeTemplateBtn fa fa-star {{
                                    colorDefaultTaskIcon_Fr_Fr_A_G
                                  }}"></i>
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                i,
                                j,
                                k,
                                l3
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ i }}_{{ j }}_{{ k }}_{{
                                    l3
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="templateRoue && roueWorkorder.isBtRoue" class="row">
          <div class="col-md-12">
            <div *ngFor="
                let sectionLine of templateRoue.columnLines[templateSideIndex]
                  .sectionLines;
                let j = index
              " class="card mt-2 rounded-0 borderTemplateTable">
              <div class="card-header pb-0 pt-2 bg-light rounded-0">
                <h5 class="">
                  {{ sectionLine.displayName }}
                </h5>
              </div>
              <div class="card-body py-0 pl-2 pr-2">
                <div class="table-responsive">
                  <table class="table templateTable">
                    <thead>
                      <tr class="text-center">
                        <th style="width: 40%"></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="
                          let subSectionLine of sectionLine.subSectionLines;
                          let k = index
                        ">
                        <td class="font-weight-bold" style="padding-right: 0px; width: 50px">
                          <div class="ctc-1">
                            {{ subSectionLine.displayName }}
                          </div>
                        </td>
                        <td colspan="2" *ngIf="subSectionLine.position == 'Center'">
                          <div style="width: 100%" *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l1 = index
                            " class="btn-group d-flex">
                            <div style="width: 100%" class="btn-group d-flex mb-1"
                              *ngIf="taskLine.position == 'Center'">
                              <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }" (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                0,
                                j,
                                k,
                                l1
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ 0 }}_{{ j }}_{{ k }}_{{
                                    l1
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l2 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Left'">
                              <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }" (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                0,
                                j,
                                k,
                                l2
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ 0 }}_{{ j }}_{{ k }}_{{
                                    l2
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                                {{ taskLine.displayName }}
                              </button>
                            </div>
                          </div>
                        </td>

                        <td *ngIf="
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel) ||
                            (subSectionLine.position == 'L/R' &&
                              sectionLine.leftLabel == '')
                          ">
                          <div *ngFor="
                              let taskLine of subSectionLine.taskLines;
                              let l3 = index
                            " class="btn-group d-flex">
                            <div class="btn-group d-flex mb-1" *ngIf="taskLine.position == 'Right'">
                              <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"
                                [ngStyle]="{
                                  backgroundColor:
                                    taskLine.taskCategoryByDoc != undefined
                                      ? taskLine.taskCategoryByDoc
                                          .createdByProfil == 'Evaluator' &&
                                        taskLine.taskCategoryByDoc
                                          .codeTaskCategory ==
                                          taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.evaluatorColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Mechanic' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.mechanicColor
                                          : 'white'
                                        : taskLine.taskCategoryByDoc
                                            .createdByProfil == 'Supervisor' &&
                                          taskLine.taskCategoryByDoc
                                            .codeTaskCategory ==
                                            taskLine.codeTaskCategory
                                        ? taskLine.definition !== undefined
                                          ? taskLine.definition.supervisorColor
                                          : 'white'
                                        : 'white'
                                      : 'white'
                                }" (click)="
                                  updateTaskOnWorkOrder(
                                    taskLine.taskCategoryByDoc !== undefined
                                      ? {
                                          data: 'taskCategoryByDoc',
                                          object: taskLine
                                        }
                                      : { data: 'taskLine', object: taskLine }
                                  )
                                ">
                                {{
                                getTaskLineIcon(
                                taskLine.displayName.trim(),
                                0,
                                j,
                                k,
                                l3
                                )
                                }}
                                <img src="" id="taskLine_icon_{{ 0 }}_{{ j }}_{{ k }}_{{
                                    l3
                                  }}" class="contentbase64-style fontSizeTemplateBtn task-icon-style" />
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer px-0">
          <button type="button" (click)="onBtClose()" class="btn btn-primary btn-sm" data-bs-dismiss="modal">
            <i class="fa fa-check mr-2"></i>Continuer
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="mulitipeValueTaskLineModal" tabindex="-1" aria-labelledby="mulitipeValueTaskLineModal"
  aria-hidden="true" data-bs-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header pt-2 pb-2 bg-light">
        <h6 class="modal-title text-primary modal-customized-title" id="updateItemsModal">
          <b>Paramètre de tâche</b>
        </h6>
      </div>
      <div class="modal-body pt-2 pb-2">
        <div class="row mt-2">
          <div class="grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="forms-sample">
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Min</label>
                    <div class="col-sm-7">
                      <input *ngIf="EObjectToUpdate !== null" type="number" class="form-control form-control-sm py-1"
                        [value]="EObjectToUpdate.object.fieldMINValue" [disabled]="true" />
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Max</label>
                    <div class="col-sm-7">
                      <input *ngIf="EObjectToUpdate !== null" type="number" class="form-control form-control-sm py-1"
                        [value]="EObjectToUpdate.object.fieldMAXValue" [disabled]="true" />
                    </div>
                  </div>
                  <div class="form-group row mb-0 py-1">
                    <label class="col-sm-5 col-form-label py-1">Multiplicateur</label>
                    <div class="col-sm-7">
                      <input type="number" name="multiplicateur" #multiplicateur="ngModel"
                        [(ngModel)]="taskMultipleValue" required
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                        class="form-control form-control-sm py-1" autofocus="autofocus" #multiplicateurElement />
                      <div class="alert alert-danger input-error-style" *ngIf="multiplicateur.errors?.['required']">
                        Le multiplicateur est requis
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="">
          <button type="button" class="btn btn-primary mr-2" data-bs-dismiss="modal" (click)="addTaskByMultipleValue()"
            [disabled]="multiplicateur.errors?.['required']">
            Ajouter la tâche
          </button>
          <button class="btn btn-light" (click)="onMultipleTaskCancel()" data-bs-dismiss="modal">
            Annuler
          </button>
        </div>
      </div>
    </div>
  </div>
</div>