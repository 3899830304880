import { ErrorHandler, Injectable, Injector} from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin, ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { ApplicationInsights, ICustomProperties, IDependencyTelemetry, IEventTelemetry, IExceptionTelemetry, IMetricTelemetry, IPageViewTelemetry, ITraceTelemetry } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { StorageService } from './core/shared/services/storage.service';

@Injectable()
export class ApplicationInsightsService {

     myinjector = Injector.create({
        providers: [
            { provide: ApplicationinsightsAngularpluginErrorService, useClass: ApplicationinsightsAngularpluginErrorService },
        ]
      });

    private angularPlugin = new AngularPlugin(this.myinjector);

    private clickPluginInstance = new ClickAnalyticsPlugin();
    private clickPluginConfig = {
      autoCapture: true
    };
    
    private appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: environment.appInsights.instrumentationKey,
            connectionString: environment.connectionString,
            extensions: [this.angularPlugin],
            // auto router tracking, default pageview duration will be set to 0
            extensionConfig: {
                [this.angularPlugin.identifier]: {
                    router: this.router, useInjector: true
                },
                [this.clickPluginInstance.identifier]: this.clickPluginConfig
            },
        },
    });

    constructor(private router: Router, private storageService: StorageService) {
        this.appInsights.loadAppInsights();

        const auth = this.storageService.getItem('bixi-user-auth')

        if(auth !== ''){
            const userEmail = JSON.parse(auth).account.username
            this.appInsights.setAuthenticatedUserContext(userEmail);
        }
        
        this.appInsights.addTelemetryInitializer(envelope => {
            envelope.tags = envelope.tags || [];
            envelope.tags["ai.cloud.role"] = environment.applicationFullName;
        });
    }

    // expose tracking methods 
    trackEvent(event: IEventTelemetry, customProperties?:ICustomProperties) {
        this.appInsights.trackEvent(event, customProperties);
    }
    
    startTrackEvent(name?: string) {
        this.appInsights.startTrackEvent(name);
    }
    
    stopTrackEvent(name: string, properties?: { [p: string]: string }, measurements?: { [p: string]: number }): any {
        this.appInsights.stopTrackEvent(name, properties, measurements);
    }
    
    trackPageView(pageView?:IPageViewTelemetry) {
        this.appInsights.trackPageView(pageView);
    }
    
    startTrackPage(name?: string) {
        this.appInsights.startTrackPage(name);
    }
    
    stopTrackPage(name?: string, url?: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }) {
        this.appInsights.stopTrackPage(name, url, properties, measurements);
    }
    
    trackMetric(metric:IMetricTelemetry, properties?: ICustomProperties) {
        this.appInsights.trackMetric(metric, properties);
    }
    
    trackException(exception: IExceptionTelemetry,  properties?: ICustomProperties) {
        this.appInsights.trackException(exception, properties);
    }
    
    trackTrace(message: ITraceTelemetry, properties?: ICustomProperties) {
        this.appInsights.trackTrace(message, properties);
    }
    
    trackDependencyData(dependency: IDependencyTelemetry) {
        this.appInsights.trackDependencyData(dependency);
    }
    
    flush() {
        this.appInsights.flush();
    } 
}