import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigService } from './config/app.config.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptor,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import {
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './config/auth/msal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './pages/login/login.component';
import { ProfilComponent } from './pages/profil/profil.component';
import { HomeComponent } from './pages/home/home.component';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { OpenWorkorderComponent } from './pages/open-workorder/open-workorder.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { HeaderComponent } from './components/header/header.component';
import { DetailWorkorderComponent } from './pages/detail-workorder/detail-workorder.component';
import { NgxEditorModule } from 'ngx-editor';
import { RouterModule } from '@angular/router';
import {
  GridModule,
  ToolbarService,
  ColumnChooserService,
  SortService,
  PageService,
  EditService,
} from '@syncfusion/ej2-angular-grids';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import {
  FilterService,
  TreeGridModule,
} from '@syncfusion/ej2-angular-treegrid';
import {
  HtmlEditorService,
  ImageService,
  LinkService,
  QuickToolbarService,
  RichTextEditorModule,
} from '@syncfusion/ej2-angular-richtexteditor';
import {
  DialogModule,
  TooltipComponent,
  TooltipModule,
} from '@syncfusion/ej2-angular-popups';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { ArchiveWorkorderComponent } from './pages/archive-workorder/archive-workorder.component';
import { TemplateMakerComponent } from './pages/template-maker/template-maker.component';
import { LoadingComponent } from './components/loading/loading.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TemplatesComponent } from './pages/templates/templates.component';
import { ValidationWorkorderComponent } from './pages/validation-workorder/validation-workorder.component';
import { DropDownListModule, ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { ApplicationInsightsService } from './telemetry.service';
import { LinkedAssetComponent } from './components/linked-asset/linked-asset.component';
import { BikePartsListComponent } from './pages/item-list/bike-parts-list.component';
import { CircleProgressbarComponent } from './components/circle-progressbar/circle-progressbar.component';
import { BarcodeGeneratorAllModule } from '@syncfusion/ej2-angular-barcode-generator';
import { DetailTacheModalComponent } from './components/detail-tache-modal/detail-tache-modal.component';
import { ConsumptionTrackComponent } from './components/consumption-track/consumption-track.component';
import { ConfirmValidationStatusComponent } from './components/confirm-validation-status/confirm-validation-status.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ProfilComponent,
    HomeComponent,
    OpenWorkorderComponent,
    NavBarComponent,
    HeaderComponent,
    DetailWorkorderComponent,
    SideBarComponent,
    ArchiveWorkorderComponent,
    TemplateMakerComponent,
    LoadingComponent,
    TemplatesComponent,
    ValidationWorkorderComponent,
    LinkedAssetComponent,
    BikePartsListComponent,
    CircleProgressbarComponent,
    DetailTacheModalComponent,
    ConsumptionTrackComponent,
    ConfirmValidationStatusComponent
  ],
  exports: [LoginComponent, ProfilComponent, HomeComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    MsalModule,
    FormsModule,
    GridAllModule,
    NgxEditorModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    GridModule,
    ToolbarModule,
    ButtonModule,
    RichTextEditorModule,
    DialogModule,
    NgxEditorModule,
    ReactiveFormsModule,
    TooltipModule,
    AngularEditorModule,
    TreeGridModule,
    BrowserAnimationsModule,
    DropDownListModule,
    ComboBoxModule,
    BarcodeGeneratorAllModule
  ],
  providers: [
    ApplicationInsightsService,
    FilterService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function initializeApp(appConfig: AppConfigService) {
  return () => appConfig.loadAppConfig();
}
