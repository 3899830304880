<!-- Section: Design Block -->
<div class="content-wrapper h-100 remove-padding">
  <div class="row g-0 d-flex align-items-center  h-100">
    <div class="col-6 d-lg-flex h-100 infos-half-bg">
    </div>
    <div class="col-6 d-flex flex-column align-items-center justify-content-center">
      <div class="alert login-alert alert-fill-danger alert-dismissible col-lg-7 col-md-11" *ngIf="profilFailed">
        <i class="fa fa-exclamation-triangle"></i>
        {{errorProfilMessage}}
        <!-- Échec! Veuillez vous connecter à nouveau -->
      </div>
      <div class="card shadow-5-strong col-lg-7 col-md-11">
        <div class="card-body py-4 px-5">
          <div class="row d-flex justify-content-center text-center">
            <img src="../../../assets/imgs/bixi.png" class="logo-auth mb-4">
            <h4 class="fw-bold">Utilisateur</h4>
            <h4 class="text-secondary fw-bold mb-4">{{currentUser}}</h4>
            <select class="form-select rounded-0 w-100 border mt-3" (change)="selectCompany($event)">
              <option value="" selected disabled>Sélectionner une compagnie</option>
              <option *ngFor="let company of companies" [value]="company.id">{{company.name}}</option>
            </select>
            <select id="profileId" class="form-select rounded-0 w-100 border mt-3" (change)="onProfileChange($event)"
              [disabled]="disableProfile">
              <option value="">Sélectionner un profil</option>
              <option *ngFor="let profil of profiles" [value]="profil.id"
                [attr.selected]="profil.default == true ? true : null">{{profil.profil == 'Supervisor' ? 'Superviseur'
                : profil.profil == 'Mechanic' ? 'Mécanicien' : profil.profil == 'Evaluator' ? 'Evaluateur' :
                profil.profil == 'Administrator' ? 'Administrateur' : ''}}</option>
            </select>
            <button type="button" class="btn btn-block btn-primary bg-gradient btn-sm mt-4"
              [disabled]="disableConfirmationButton" (click)="confirmProfilSelection()"><i class="fa fa-check me-2">
              </i>Confirmer</button>
            <a class="btn btn-link small text-auth text-primary text-decoration-none"
              routerLink="/auth/login"><small>Retour à la page de connexion?</small></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Section: Design Block -->