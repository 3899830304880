<div class="row" style="height:200px">
  <div class="col-md-4">
    <img src="../../../assets/imgs/loading-block.gif" class="col-12 mt-2" />
    <img src="../../../assets/imgs/loading-block.gif" class="col-12 mt-2" />
    <img src="../../../assets/imgs/loading-block.gif" class="col-12 mt-2" />
    <img src="../../../assets/imgs/loading-block.gif" class="col-12 mt-2" />
    <img src="../../../assets/imgs/loading-block.gif" class="col-12 mt-2" />

  </div>
  <div class="col-md-4">
    <img src="../../../assets/imgs/loading-block.gif" class="col-12 mt-2" />
    <img src="../../../assets/imgs/loading-block.gif" class="col-12 mt-2" />
    <img src="../../../assets/imgs/loading-block.gif" class="col-12 mt-2" />
    <img src="../../../assets/imgs/loading-block.gif" class="col-12 mt-2" />
    <img src="../../../assets/imgs/loading-block.gif" class="col-12 mt-2" />

  </div>
  <div class="col-md-4">
    <img src="../../../assets/imgs/loading-block.gif" class="col-12 mt-2" />
    <img src="../../../assets/imgs/loading-block.gif" class="col-12 mt-2" />
    <img src="../../../assets/imgs/loading-block.gif" class="col-12 mt-2" />
    <img src="../../../assets/imgs/loading-block.gif" class="col-12 mt-2" />
    <img src="../../../assets/imgs/loading-block.gif" class="col-12 mt-2" />
  </div>
</div>