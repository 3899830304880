<div class="container-scroller">
  <!-- Nav bar component -->
  <app-nav-bar [BIXI_userAuthObject]="BIXI_userAuthObject" [currentProfile]="currentProfile"
    [currentUserCompany]="currentUserCompany" (changeProfileEvent)="changeProfile($event)" [equipementNo]="equipementNo"
    [pageName]="pageName" (logoutEvent)="logout()" [isUserBlocked]="isUserBlocked"></app-nav-bar>

  <div class="container-fluid page-body-wrapper add-padding">
    <!-- Side Bar component -->
    <app-side-bar [currentProfile]="currentProfile" [currentUserCompany]="currentUserCompany" [pageName]="pageName"
      [equipementNo]="equipementNo"></app-side-bar>

    <div class="main-panel" style="margin-left: 70px">
      <div class="content-wrapper p-3">
        <!-- Header -->
        <app-header *ngIf="equipement" [BIXI_userAuthObject]="BIXI_userAuthObject"
          [currentUserCompany]="currentUserCompany" (refreshEquipementEvent)="refreshEquipementEvent()"
          [pageName]="pageName" [equipement]="equipement"></app-header>

        <div class="card cardWorkOrdersGrid mt-2" #cardWorkOrdersGrid>
          <div class="text-white bg-primary card-header d-inline">
            <h4 class="float-left my-0 fw-bold">
              <i class="fas fa-folder mr-2"></i>Bons de travail archivés
            </h4>
          </div>
          <ejs-grid #workOrdersGrid [dataSource]="archices" gridLines="Horizontal" showColumnChooser="true"
            [allowSelection]="true" [selectionSettings]="selectOptions" [editSettings]="editSettings"
            [allowSorting]="true" [allowMultiSorting]="true" (toolbarClick)="toolbarClick($event)" rowHeight="30">
            <e-columns>
              <e-column field="WorkOrder" [isPrimaryKey]="true" headerText="Bon de travail" width="130"
                textAlign="Left">
                <ng-template #template let-data>
                  <!-- TODO passer donné vers le Modal au click -->
                  <a rel="nofollow" style="cursor: pointer" class="text-info fw-bold" data-bs-toggle="modal"
                    data-bs-target="#workorderDetailModal" (click)="workorderDetailModalData(data)">{{ data.no }}</a>
                </ng-template>
              </e-column>
              <e-column headerText="Type" width="130" textAlign="Left">
                <ng-template #template let-data>
                  {{ data.correctiveMaintenance ? "Correctif" : "Preventif" }}
                </ng-template>
              </e-column>
              <e-column field="description" headerText="Description" width="150" textAlign="Left"></e-column>
              <e-column field="State" headerText="Statut" width="150" textAlign="Left">
                <ng-template #template let-data>
                  <!-- TODO passer donné vers le Modal au click -->
                  <span>Archivé</span>
                </ng-template>
              </e-column>
              <e-column field="nextPlannedDate" headerText="Date" format="yMd" width="120"></e-column>
            </e-columns>
          </ejs-grid>
        </div>
      </div>
    </div>
  </div>
</div>
<app-linked-asset *ngIf="equipement" [formTitle]="'archive WO'" [equipement]="equipement"
  [isUserBlocked]="isUserBlocked"></app-linked-asset>

<!-- <div class="modal fade" id="associatedEquipModal" tabindex="-1" aria-labelledby="associatedEquipModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" style="width:70%">
      <div class="modal-content">
        <div class="modal-header pt-2 pb-2 bg-light">
          <h6 class="modal-title text-primary" id="associatedEquipModalLabel">Liste des équipements</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ejs-grid #listEquipGrid [dataSource]='listEquipData' gridLines="Horizontal" showColumnChooser='false'
            [allowSelection]="true" [allowMultiSorting]='true' rowHeight="30">
            <e-columns>
              <e-column field='num' headerText='N° équipement' width='130' textAlign='Left'></e-column>
              <e-column field='desciption' headerText='Description' width='150' textAlign='Left'></e-column>
              <e-column field='category' headerText='Catégorie' width='120' textAlign='Left'></e-column>
              <e-column headerText='Bon de travail' width='150' textAlign='Left'>
                <ng-template #template let-data>
                  <a rel='nofollow' class="link text-info">{{data.currentWo}}</a>
                </ng-template>
              </e-column>
            </e-columns>
          </ejs-grid>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary"><i class="fa fa-print mr-2"></i>Imprimer étiquette</button>
          <button type="button" class="btn btn-success" data-bs-toggle="modal"
            data-bs-target="#associatedEquipTemplateModal" (click)="confirmeCreateWoEquip()">Créer un bon de
            travail</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Annuler</button>
        </div>
      </div>
    </div>
  </div> -->

<!-- <div class="modal fade" id="associatedEquipTemplateModal" tabindex="-1" aria-labelledby="associatedEquipTemplateLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" style="width:70%">
      <div class="modal-content">
        <div class="modal-header pt-2 pb-2 bg-light">
          <h6 class="modal-title text-primary" id="associatedEquipTemplateLabel">Bon du travail N° <b>
              {{selectedWorEquipment}}</b></h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body pt-2 pb-2">
          <div class="row">
            <div class="col-md-6">
              <div class="card mt-2 rounded-0 borderTemplateTable">
                <div class="card-header pb-0 pt-2 bg-light rounded-0">
                  <h5 class="">
                    Roue avant
                  </h5>
                </div>
                <div class="card-body py-0 pl-2 pr-2">
                  <div class="table-responsive">
                    <table class="table templateTable">
                      <thead>
                        <tr class="text-center">
                          <th style="width:40%"></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="font-weight-bold">
                            Alignement
                          </td>
                          <td>
                            <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"><i
                                class="fa fa-wrench fontSizeTemplateBtn"></i>
                              ajuster</button>
                            <button id="eg3"
                              class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn mt-2 "><i
                                class="fa fa-retweet fontSizeTemplateBtn"></i> changer</button>
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">
                            Roue changée
                          </td>
                          <td>
                            <button id="eg3"
                              class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"><i
                                class="fa fa-retweet fontSizeTemplateBtn"></i> changer</button>
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">
                            Réflecteur 1x 2x
                          </td>
                          <td>
                            <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"><i
                                class="fa fa-wrench fontSizeTemplateBtn"></i>
                              ajuster</button>
                            <button id="eg3"
                              class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn mt-2 "><i
                                class="fa fa-retweet fontSizeTemplateBtn"></i> changer</button>
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">
                            Tube
                          </td>
                          <td>
                            <button id="eg3"
                              class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"><i
                                class="fa fa-retweet fontSizeTemplateBtn"></i> changer</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card mt-2 rounded-0 borderTemplateTable">
                <div class="card-header pb-0 pt-2 bg-light rounded-0">
                  <h5 class="">
                    Roue arrière
                  </h5>
                </div>
                <div class="card-body py-0 pl-2 pr-2">
                  <div class="table-responsive">
                    <table class="table templateTable">
                      <thead>
                        <tr class="text-center">
                          <th style="width:40%"></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="font-weight-bold">
                            Alignement
                          </td>
                          <td>
                            <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"><i
                                class="fa fa-wrench fontSizeTemplateBtn"></i>
                              ajuster</button>
                            <button id="eg3"
                              class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn mt-2 "><i
                                class="fa fa-retweet fontSizeTemplateBtn"></i> changer</button>
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">
                            Roue changée
                          </td>
                          <td>
                            <button id="eg3"
                              class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"><i
                                class="fa fa-retweet fontSizeTemplateBtn"></i> changer</button>
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">
                            Réflecteur 1x 2x
                          </td>
                          <td>
                            <button class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"><i
                                class="fa fa-wrench fontSizeTemplateBtn"></i>
                              ajuster</button>
                            <button id="eg3"
                              class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn mt-2 "><i
                                class="fa fa-retweet fontSizeTemplateBtn"></i> changer</button>
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">
                            Tube
                          </td>
                          <td>
                            <button id="eg3"
                              class="btn btn-sm btn-light p-1 btn-block borderTemplateBtn fontSizeTemplateBtn"><i
                                class="fa fa-retweet fontSizeTemplateBtn"></i> changer</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" data-bs-dismiss="modal">Confirmer</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Annuler</button>
        </div>
      </div>
    </div>
  </div> -->

<!-- <div class="modal fade" id="consumptionTrackModal" tabindex="-1" aria-labelledby="consumptionTrackModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" style="width:70%">
      <div class="modal-content">
        <div class="modal-header pt-2 pb-2 bg-light">
          <h6 class="modal-title text-primary" id="consumptionTrackModalLabel">Suivi de consommation</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ejs-grid #consumptionTrackGrid [dataSource]='consumptionTrackData' gridLines="Horizontal"
            showColumnChooser='false' [allowSelection]="true" [allowMultiSorting]='true' rowHeight="30">
            <e-columns>
              <e-column field='NumItem' headerText="N° d'article" width='130' textAlign='Left'></e-column>
              <e-column field='LastVariantCons' headerText='Dernière variante consommée' width='150'
                textAlign='Left'></e-column>
              <e-column field='ConsumDate' headerText='Date de consommation' width='120' textAlign='Left'></e-column>
            </e-columns>
          </ejs-grid>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Fermer</button>
        </div>
      </div>
    </div>
  </div> -->

<!-- workorderDetail Modal -->
<div class="modal fade" id="workorderDetailModal" tabindex="-1" aria-labelledby="workorderDetailModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" style="width: 85%">
    <div class="modal-content">
      <div class="modal-header py-2 bg-light">
        <h5 class="modal-title text-primary modal-customized-title" id="workorderDetailModalLabel">
          <i class="fas fa-stream mr-2"></i><span class="fw-bold">Détail du bon de travail archivé</span>
        </h5>
        <button type="button" class="btn btn-danger btn-sm my-1" data-bs-dismiss="modal" aria-label="Close">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body py-3 px-3">
        <div class="card mb-2">
          <div class="card border">
            <div class="card-header">
              <h5 *ngIf="currentWorkorder !== null" class="mb-0">
                {{ currentWorkorder.no }} /
                {{
                currentWorkorder.correctiveMaintenance
                ? "Correctif"
                : "Preventif"
                }}
                / Réparation de vélo
              </h5>
            </div>
          </div>
        </div>

        <ejs-treegrid #treegridObj *ngIf="drawTable" [dataSource]="workOrderDetailData" childMapping="subtasks"
          [treeColumnIndex]="0" [allowSorting]="true" enableCollapseAll="true" rowHeight="45">
          <e-columns>
            <!-- <e-column field='taskID' headerText='Task ID' width='70' textAlign='Left'></e-column> -->
            <e-column #section field="codeTaskCategory" headerText="Section" width="150" textAlign="Left"></e-column>
            <e-column field="no" headerText="No" width="100" textAlign="Left"></e-column>
            <!-- <e-column field='action' headerText='Action' width='100' format="yMd" textAlign='Left'></e-column> -->
            <e-column field="description" headerText="Description" width="150" textAlign="Left"></e-column>
            <e-column field="unitOfMeasureCode" headerText="Uom" width="80" textAlign="Left"></e-column>
            <e-column field="locationCode" headerText="Emplacement" width="90" textAlign="Left"></e-column>
            <e-column field="quantity" headerText="Quantité" width="100" textAlign="Right"></e-column>
            <e-column field="eraFinished" headerText="Terminé" width="100" textAlign="Center" [allowEditing]="false">
              <ng-template #template let-data><i *ngIf="data.eraFinished == undefined"
                  class="fa fa-check"></i></ng-template>
            </e-column>
            <e-column field="eraApproved" headerText="Validé" width="100" textAlign="Center" [allowEditing]="false">
              <ng-template #template let-data><i *ngIf="data.eraApproved == undefined"
                  class="fa fa-thumbs-up"></i></ng-template>
            </e-column>
            <e-column field="insertedby" headerText="Créé par" width="90" textAlign="Left" [allowEditing]="false">
              <ng-template #template let-data>
                <span *ngIf="data.insertedby" class="p-1 rounded-pill" [ngClass]="{
                    bgColorMec: data.createdByProfil === 'Mechanic',
                    'bgColorEval ': data.createdByProfil === 'Evaluator',
                    'bgColorSup ': data.createdByProfil === 'Supervisor'
                  }">
                  {{ data.insertedby }}
                </span>
              </ng-template>
            </e-column>
            <e-column field="createdByProfil" headerText="Rôle" width="90" textAlign="Left">
              <ng-template #template let-data>
                <span>
                  {{ data.createdByProfil == 'Mechanic' ? 'Mécanicien' : data.createdByProfil == 'Evaluator' ?
                  'Évaluateur' : data.createdByProfil == 'Supervisor' ? 'Superviseur' : '' }}
                </span>
              </ng-template>
            </e-column>
          </e-columns>
        </ejs-treegrid>
      </div>
    </div>
  </div>
</div>