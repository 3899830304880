import { Injectable } from '@angular/core';
import {MsalService} from "@azure/msal-angular";
import {StorageService} from "../../shared/services/storage.service";
import {Observable} from "rxjs";
import {AuthenticationResult} from "@azure/msal-browser";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private msalService: MsalService, private storageService: StorageService) { }

  logout(): void {
    this.msalService.logoutPopup({
      mainWindowRedirectUri: "/auth/login",
    }).subscribe(_ => {
      this.storageService.clear();
    })
  }

  getAllAccountsCount(): number {
    return this.msalService.instance.getAllAccounts().length;
  }

  login(): Observable<AuthenticationResult> {
    return this.msalService.loginPopup();
  }
}
